import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AddressService, AlertService, CommonService, Eorders, EordersService, EventService, SettingsService } from 'src/app/@tji/_dbShare';
import { AddressListComponent } from '../address-list/address-list.component';

@UntilDestroy()
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  dialogTitle: string = 'Order View';
  Orders: any;
  OrderList: any;
  selectedOrderName: number;
  action: string;
  statusInfoData: any;
  profileImages: any;
  profileDataView: any;
  addressParams: any;
  lang: any = 'en';
  orderData: any = null;

  constructor(public eordersService: EordersService, public eventService: EventService,
    private commonService: CommonService,
    private alertService: AlertService,
    private addressService: AddressService,
    private settingsService: SettingsService,
    public matDialog: MatDialog,
    private cd: ChangeDetectorRef,
    public matDialogRef: MatDialogRef<OrderViewComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.eordersService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.orderData = data;
      var productContentId = [];
      if (!this.orderData) {
        if (data && data.productDetails && data.productDetails.length > 0) {
          this.Orders = data;
          this.Orders.productDetails.forEach(element => {
            if (element.id) {
              productContentId.push(element.id)
            }
          });
          this.getProductName(productContentId.join(","))
          // var params = { client_site_id: this.Orders.client_site_id ? this.Orders.client_site_id : null }
          // this.settingsService.getProfile(params);
          // this.settingsService.getAllItems(params);
          // this.cd.detectChanges();
        this.getPrint();
        }
      } else {
        if (this.orderData && data && data.productDetails && data.productDetails.length > 0 && data._id == this.orderData._id) {
          this.Orders = data;
          this.Orders.productDetails.forEach(element => {
            if (element.id) {
              productContentId.push(element.id)
            }
          });
          this.getProductName(productContentId.join(","))
          // var params = { client_site_id: this.Orders.client_site_id ? this.Orders.client_site_id : null }
          // this.settingsService.getProfile(params);
          // this.settingsService.getAllItems(params);
          // this.cd.detectChanges();
          this.getPrint();
        }
      }

    });

    this.settingsService.clientSiteProfile.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.base64) {
        this.profileImages = (data.base64);
        this.getPrint();
      }
    });


    this.addressService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.addressParams = data;
      if (this.Orders.people_id) {
        this.addressParams.people_id = this.Orders.people_id;
        this.addressParams["brand_id"] = this.Orders.address.brand_id?this.Orders.address.brand_id:'';
        this.addressService.getAllItems();
      }
    });

  }

  ngOnInit() {
    this.cd.detectChanges();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.orderData = null;
    this.eordersService.itemPrintSource.next([]);
  }

  /** get product name */
  getProductName(id) {
    var data = {
      "product_ids": id,
      "client_site_id": this.Orders.client_site_id ? this.Orders.client_site_id : null,
    }
    let tempSub = this.commonService.storeItem('eproduct/specific', data, true, "optionOne").subscribe((res: any) => {
      if (res.success) {
        this.Orders.productDetails.forEach((product, index) => {
          res.data.forEach(element => {
            if (product.id === element.content_id) {
              this.Orders.productDetails[index]['name'] = element.title;
              this.Orders.productDetails[index]['currency'] = element.currency;
              this.Orders.productDetails[index]['image_url'] = element.image_url;
            }
            if (product.id === element.retailer_id) {
              this.Orders.productDetails[index]['name'] = element.name;
              this.Orders.productDetails[index]['currency'] = element.currency;
              this.Orders.productDetails[index]['image_url'] = element.image_url;
            }
          });
        });
      } else {
        this.alertService.webErrorShow(res);
      }
      tempSub.unsubscribe();
    }, error => {
      this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
      console.error('There was an error!', error);
      tempSub.unsubscribe();
    })
  }

  getPrint() {
    if (this.Orders && this.Orders._id) {
      var data = this.Orders;
      data["profileImages"] = this.profileImages
      data["clientSiteDate"] = this.profileDataView
      setTimeout(() => {
        this.eordersService.itemPrintSource.next(data);
      }, 500);
    }

  }
  onPrint(order) {
    // window.print();
    this.lang = localStorage.getItem('language');
    var url = this.commonService.baseUrlPrint + 'apime/orderPrint/?id=' + order._id + '&lang=' + this.lang;
    window.open(url, "_blank");
  }

  addressRequest() {
    var data = {
      "orderId": this.Orders._id ? this.Orders._id : ''
    }
    this.eordersService.addressRequest("eorders/requestAddress", data);
  }

  changeAddress() {

    let confirmDialogRef = this.matDialog.open(AddressListComponent, {
      width: "400px",
      data: { type: this.Orders }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        var statusData =
        {
          "id": this.Orders._id,
          "address_id": result[1]
        }
        this.eordersService.addressupdate('eorders/updateaddress', statusData);
      }
    });
  }

}

