import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Alert, AlertModel } from 'src/app/@tji/_dbShare/alert/alert';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { NotifyService } from 'src/app/@tji/_dbShare/notify/notify.service';
import { ToastService } from 'src/app/@tji/_dbShare/alert/toast/toast.service';
import { ToastData } from 'src/app/@tji/_dbShare/alert/toast/toastData.interface';
import { ToastModel } from 'src/app/@tji/_dbShare/alert/toast/toast_model.model';

import { FeedService } from 'src/app/@tji/_dbShare/feed';
import { WhatsappTicketService } from 'src/app/@tji/_dbShare/whatsapp';
import { WebchatTicketService } from 'src/app/@tji/_dbShare/webchat';
import { TweetTicketService } from 'src/app/@tji/_dbShare/twitter';
import { TweetdmTicketService } from 'src/app/@tji/_dbShare/twitterdm';
import { MessengerTicketService } from 'src/app/@tji/_dbShare/messenger';
import { InstagramTicketService } from 'src/app/@tji/_dbShare/instagram';
import { InstadmTicketService } from 'src/app/@tji/_dbShare/instadm';
import { YoutubeTicketService } from 'src/app/@tji/_dbShare/youtube';
import { AudioService } from '../audio.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

declare function notifyMe(title, message): any;

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()

export class EventService {

    authUser: User = this.userService.authUser();
    authUserId: number;
    alertData: Alert;
    toastData: ToastData;
    notificationData = [];
    isNotification: boolean = false;
    isPrepremiumDashboard: boolean = false;
    private eventDataSource = new BehaviorSubject<any>(null);
    eventData = this.eventDataSource.asObservable();
    isOffline: any = false;

    private _unsubscribeAll: Subject<any>;

    constructor(private userService: UserService,
        private commonService: CommonService,
        private notifyService: NotifyService,
        private toastService: ToastService,
        private whatsappTicketService: WhatsappTicketService,
        private webchatTicketService: WebchatTicketService,
        private tweetTicketService: TweetTicketService,
        private tweetdmTicketService: TweetdmTicketService,
        private messengerTicketService: MessengerTicketService,
        private instagramTicketService: InstagramTicketService,
        private instadmTicketService: InstadmTicketService,
        private audioService: AudioService,
        private toastr: ToastrService,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
        private youtubeTicketService: YoutubeTicketService) {
        this.authUserId = (this.authUser && this.authUser.id) ? this.authUser.id : null;
        var user: any = JSON.parse(localStorage.getItem('tji_user'));
        if (user && user.isPrepremiumDashboard) {
            this.isPrepremiumDashboard = user.isPrepremiumDashboard ? user.isPrepremiumDashboard : false;
        }
        updateBrowserTap();
        this.longLive();
    }

    longLive() {
        this.isOffline = globalVar;
        if (!globalVar) {
            this.titleService.setTitle('Social Media Management CRM Software, CRM Tool – aioctopus.com');
        }
        setTimeout(() => {
            this.longLive();
        }, 5);
    }

    changeEventData(eventData: any = null) {
        this.eventDataSource.next(eventData);
        // this.changeCount(eventData);
        this.showToast(eventData);

        let eventName = (eventData && eventData.event) ? eventData.event : null;
        eventName = (eventData && eventData.event) ? eventData.event : eventName;
        // console.log("eventName : ", eventName);
        // console.log('socket eventData : ', eventData);

        if (this.isOffline && eventData && eventData.event != 'logout' && eventData.event != 'login') {
            this.showBrowserNotification(eventData);
        }
    }

    unSubscribe() {
        // console.log('UnSubscribed');
    }

    unSubscribeFilter() {
        // console.log('UnSubscribed Filters');
    }

    clearSubscribe() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    showAlert(title: string, message: string, type: string = null, icon: string = null) {
        this.alertData = new AlertModel({});
        this.alertData.type = (type) ? type : 'info';
        this.alertData.title = title;
        this.alertData.message = message;
        this.alertData.faIcon = 'fa ' + (icon) ? icon : 'fa-exclamation-circle';
        this.alertData.icon = 'fa ' + (icon) ? icon : 'fa-exclamation-circle';
        this.commonService.alert(this.alertData);
    }

    showToast(eventData: any) {
        let eventName = (eventData && eventData.event) ? eventData.event : null;
        eventName = (eventData && eventData.eventName) ? eventData.eventName : eventName;
        if (eventName && (eventName === 'newmessage' || eventName === 'aNewmessage' || eventName === 'delivery_failed' || eventName === 'ticketReply' || eventName === 'newfeed' || eventName === 'aNewfeed' || eventName === 'removefeed' ||
            eventName === 'ticketassigned' || eventName === 'ticketreassigned' || eventName === 'aticketassigned' || eventName === 'aticketreassigned' || eventName === 'ticketabandon' ||
            eventName === 'aticketremoved' || eventName === 'ticketremoved' || eventName === 'ticketclosed' || eventName === 'aclosedticketremoved')) {

            if (eventName === 'ticketReply') {
                if (eventData.notification) {
                    this.toastData = new ToastModel({});
                    this.toastData.type = eventData.event;
                    this.toastData.title = eventData.notification.heading;
                    this.toastData.message = eventData.notification.text;
                    this.toastData.icon = eventData.notification.icon;
                    this.toastData.faIcon = 'fa ' + (eventData.notification.icon) ? eventData.notification.icon : 'fa-exclamation-circle' //eventData.faIcon;
                    this.toastData.site = eventData.notification.site;
                    this.toastData.category = this.getCategory(eventData);
                    // this.toastData.image = (eventData.notification.image) ? eventData.notification.image : eventData.notification.data.peopleAvatar;
                }
            } else {

                this.toastData = new ToastModel({});
                this.toastData.type = eventData.event;
                this.toastData.title = eventData.heading;
                this.toastData.message = eventData.text;
                this.toastData.icon = eventData.icon;
                this.toastData.faIcon = 'fa ' + (eventData.icon) ? eventData.icon : 'fa-exclamation-circle' //eventData.faIcon;
                this.toastData.site = eventData.site;
                this.toastData.category = this.getCategory(eventData);
                this.toastData.image = (eventData.image) ? eventData.image : eventData.data.peopleAvatar;

            }
            let isClient: boolean = this.userService.isAuthUserIsClient();
            let isAgent: boolean = this.userService.isAuthUserIsAgent();

            if (eventName && eventName === 'newmessage' || eventName === 'aNewmessage' || eventName === 'ticketReply') {
                this.toastData.type = 'newmessage';
                this.toastService.showMessage(this.toastData);
                // // if (this.notificationData && this.notificationData.length <= 6) {
                // this.notificationData.push(this.toastData);
                // // }
                // if (this.notificationData.length > 0 && this.notificationData.length <= 3) {
                //     this.toastService.showMessage(this.toastData);
                //     // this.notificationData.shift();
                //     this.isNotification = false;
                // } else if (this.notificationData.length > 4 && !this.isNotification) {
                //     var notification = {
                //         category: "received",
                //         faIcon: "",
                //         icon: "",
                //         id: null,
                //         image: "",
                //         message: "New Message Received",
                //         site: "",
                //         title: "Multiple",
                //         type: "newmessage"
                //     }
                //     this.toastService.showMessage(notification);
                //     this.isNotification = true;
                // } else if (this.notificationData.length > 6) {
                //     this.toastr.clear();
                //     this.notificationData = []
                //     this.isNotification = false;
                // }
            }
            switch (eventData.event) {
                case "newmessage":
                    // this.toastService.showMessage(this.toastData); 
                    break;
                case "newfeed":
                    if (isClient) {
                        this.toastService.showMessage(this.toastData);
                        notifyMe(this.toastData.title, this.toastData.message);
                    }
                    if (this.isPrepremiumDashboard || !this.isPrepremiumDashboard) {
                        this.toastData.title = eventData.site;
                        this.toastData['message'] = "Congratulations! you earned a new Lead on" + eventData.site
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "aNewfeed":
                    if (isClient) {
                        this.toastData.type = 'newfeed'
                        this.toastService.showMessage(this.toastData);
                        notifyMe(this.toastData.title, this.toastData.message);
                    }
                    if (this.isPrepremiumDashboard || !this.isPrepremiumDashboard) {
                        this.toastData.title = eventData.site;
                        this.toastData['message'] = "Congratulations! you earned a new Lead on" + eventData.site
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }

                    break;
                case "removefeed":
                    if (isClient && this.toastData && this.toastData.title) {
                        this.toastService.showMessage(this.toastData);
                        notifyMe(this.toastData.title, this.toastData.message);
                    }
                    break;
                case "ticketassigned":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "ticketreassigned":
                    this.toastService.showMessage(this.toastData);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "aticketassigned":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "aticketreassigned":
                    this.toastService.showMessage(this.toastData);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "ticketabandon":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    break;
                case "ticketremoved":
                    if (isAgent) {
                        this.toastService.showMessage(this.toastData);
                        notifyMe(this.toastData.title, this.toastData.message);
                    }
                    break;
                case "aticketremoved":
                    if (isAgent) {
                        this.toastService.showMessage(this.toastData);
                        notifyMe(this.toastData.title, this.toastData.message);
                    }
                    break;
                case "delivery_failed":
                    this.toastService.showMessage(this.toastData);
                    // notifyMe(this.toastData.title, this.toastData.message);
                    break;
                case "ticketclosed":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "aclosedticketremoved":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "newticket":
                    this.toastService.showMessage(this.toastData);
                    notifyMe(this.toastData.title, this.toastData.message);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                default:
                    break;
            }
        }
        if (eventName && (eventName === 'orderCreate' || eventName === 'ordereassign' || eventName === 'orderemoved' || eventName === 'orderUpdate' || eventName === 'paymentCreate' || eventName === 'paymentUpdate'
            || eventName === 'addcomment' || eventName === 'removecomment' || eventName === "replycomment" || eventName === "templatedelete" || eventName === "templatecreate"
            || eventName === "templateupdate" || eventName === "templatevent" || eventName === "labelupdate" || eventName === "ticketUpdate")) {
            this.toastData = new ToastModel({});
            this.toastData.type = 'success';
            this.toastData.title = eventData.title;
            this.toastData.message = eventData.text;
            this.toastData.category = this.getCategory(eventData);
            let isClient: boolean = this.userService.isAuthUserIsClient();
            let isAgent: boolean = this.userService.isAuthUserIsAgent();

            switch (eventData.event) {
                case "orderCreate":
                    this.toastService.showMessage(this.toastData);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "ordereassign":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "orderemoved":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "orderUpdate":
                    if (eventData.data && eventData.data.status === "Confirmed") {
                        this.audioService.playOrderAudio();
                    }
                    this.toastService.showMessage(this.toastData);
                    break;
                case "paymentCreate":
                    this.toastService.showMessage(this.toastData);
                    if (this.isPrepremiumDashboard) {
                        this.toastService.showPrepremiumDashboard(this.toastData);
                    }
                    break;
                case "paymentUpdate":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "addcomment":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "removecomment":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "replycomment":
                    this.toastService.showMessage(this.toastData);
                    break;
                // case "templatdelete":
                //     this.toastService.showMessage(this.toastData);
                //     break;
                // case "templatcreate":
                //     this.toastService.showMessage(this.toastData);
                //     break;
                // case "templatupdate":
                //     this.toastService.showMessage(this.toastData);
                //     break;
                case "templatevent":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "labelupdate":
                    this.toastService.showMessage(this.toastData);
                    break;
                case "ticketUpdate":
                    this.toastService.showMessage(this.toastData);
                    break;
                default:
                    break;
            }
        }

    }

    showBrowserNotification(eventData: any) {
        let eventName = (eventData && eventData.event) ? eventData.event : null;
        eventName = (eventData && eventData.eventName) ? eventData.eventName : eventName;
        if (eventName) {

            switch (eventData.event) {
                case "newmessage":
                    this.titleService.setTitle('New Message Received');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aNewmessage":
                    this.titleService.setTitle('New Message Received');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "delivery_failed":
                    this.titleService.setTitle('Message Delivery Failed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "newfeed":
                    this.titleService.setTitle('Congratulations! you earned a new Lead');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aNewfeed":
                    this.titleService.setTitle('Congratulations! you earned a new Lead');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketassigned":
                    this.titleService.setTitle('New Ticket Assigned');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketreassigned":
                    this.titleService.setTitle('Ticket Reassigned');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aticketassigned":
                    this.titleService.setTitle('Ticket Reassigned');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aticketreassigned":
                    this.titleService.setTitle('Ticket Reassigned');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketabandon":
                    this.titleService.setTitle('Ticket Abandon');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketremoved":
                    this.titleService.setTitle('Ticket Removed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aticketremoved":
                    this.titleService.setTitle('Ticket Removed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketclosed":
                    this.titleService.setTitle('Ticket Closed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "aclosedticketremoved":
                    this.titleService.setTitle('Ticket Closed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "newticket":
                    this.titleService.setTitle('New Ticket Received');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "orderCreate":
                    this.titleService.setTitle('New Order Received');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ordereassign":
                    this.titleService.setTitle('Order Reassign');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "orderemoved":
                    this.titleService.setTitle('Order Removed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "orderUpdate":
                    if (eventData.data && eventData.data.status === "Confirmed") {
                        this.titleService.setTitle('Order Confirmed');
                        updateFavicon('assets/img/scrm/notification.png');
                    } else {
                        this.titleService.setTitle('Order Status Updated');
                        updateFavicon('assets/img/scrm/notification.png');
                    }
                    break;
                case "paymentCreate":
                    this.titleService.setTitle('New Payment Created');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "paymentUpdate":
                    this.titleService.setTitle('Payment Status Updated');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "addcomment":
                    this.titleService.setTitle('Comment Added');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "removecomment":
                    this.titleService.setTitle('Comment Removed');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "replycomment":
                    this.titleService.setTitle('Reply Comment');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "templatevent":
                    this.titleService.setTitle('Template Updated');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "labelupdate":
                    this.titleService.setTitle('Label Updated');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                case "ticketUpdate":
                    this.titleService.setTitle('Ticket Updated');
                    updateFavicon('assets/img/scrm/notification.png');
                    break;
                default:
                    break;
            }
        }

    }


    showAlertByData(eventData: any) {
        let eventName = (eventData && eventData.event) ? eventData.event : null;
        eventName = (eventData && eventData.eventName) ? eventData.eventName : eventName;
        if (eventName && (eventName === 'newmessage' || eventName === 'aNewmessage' || eventName === 'ticketReply' || eventName === 'newfeed' || eventName === 'aNewfeed' || eventName === 'removefeed' ||
            eventName === 'aticketassigned' || eventName === 'aticketreassigned' || eventName === 'ticketassigned' || eventName === 'ticketreassigned' || eventName === 'ticketabandon' ||
            eventName === 'aticketremoved' || eventName === 'ticketremoved' || eventName === 'ticketclosed' || eventName === 'aclosedticketremoved')) {
            this.alertData = new AlertModel({});
            this.alertData.type = eventData.event;
            this.alertData.title = eventData.heading;
            this.alertData.message = eventData.text;
            this.alertData.faIcon = eventData.icon;
            this.alertData.image = (eventData.image) ? eventData.image : eventData.data.peopleAvatar;
            let isClient: boolean = this.userService.isAuthUserIsClient();
            let isAgent: boolean = this.userService.isAuthUserIsAgent();
            switch (eventData.event) {
                case "newmessage":
                    this.commonService.alert(this.alertData);
                    break;
                case "aNewmessage":
                    this.commonService.alert(this.alertData);
                    break;
                case "ticketReply":
                    this.commonService.alert(this.alertData);
                    break;
                case "newfeed":
                    if (isClient) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                case "aNewfeed":
                    if (isClient) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                case "removefeed":
                    if (isClient && this.toastData && this.toastData.title) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                case "ticketassigned":
                    this.commonService.alert(this.alertData);
                    notifyMe(this.alertData.title, this.alertData.message);
                    break;
                case "ticketreassigned":
                    this.commonService.alert(this.alertData);
                    break;
                case "aticketassigned":
                    this.commonService.alert(this.alertData);
                    notifyMe(this.alertData.title, this.alertData.message);
                    break;
                case "aticketreassigned":
                    this.commonService.alert(this.alertData);
                    break;
                case "ticketabandon":
                    this.commonService.alert(this.alertData);
                    break;
                case "ticketremoved":
                    if (isAgent) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                case "aticketremoved":
                    if (isAgent) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                case "ticketclosed":
                    this.commonService.alert(this.alertData);
                    notifyMe(this.alertData.title, this.alertData.message);
                    break;
                case "aclosedticketremoved":
                    this.commonService.alert(this.alertData);
                    notifyMe(this.alertData.title, this.alertData.message);
                    break;
                case "newticket":
                    if (isClient) {
                        this.commonService.alert(this.alertData);
                        notifyMe(this.alertData.title, this.alertData.message);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    getCategory(eventData: any) {
        let data = eventData.data;
        if (eventData && eventData.event === 'newmessage' || eventData.event === 'aNewmessage' || eventData.event === 'ticketReply') {
            if (data && data.status) {
                return data.status;
            }
        }
        if (eventData && (eventData.event === 'newticket' ||
            eventData.event === 'ticketassigned' ||
            eventData.event === 'ticketreassigned' ||
            eventData.event === 'aticketassigned' ||
            eventData.event === 'aticketreassigned' ||
            eventData.event === 'ticketremoved' ||
            eventData.event === 'aticketremoved' ||
            eventData.event === 'ticketabandon' ||
            eventData.event === 'ticketReply' ||
            eventData.event === 'ticketclosed' ||
            eventData.event === 'removefeed' ||
            eventData.event === 'aclosedticketremoved' ||
            eventData.event === 'delivery_failed' ||
            eventData.event === 'newfeed' || eventData.event === 'aNewfeed')) {
            return 'ticket';
        }
        return null;
    }

    changeCount(eventData: any) {
        if (eventData && eventData.event) {
            let isClient: boolean = this.userService.isAuthUserIsClient();
            let isAgent: boolean = this.userService.isAuthUserIsAgent();
            let site = (eventData.site) ? eventData.site : null;
            if (site && eventData.event) {
                let data = eventData.data;
                switch (eventData.event) {
                    case "newmessage":
                        if (data && data.parent_id && data.parent && data.parent.table && data.parent.ticketId) {
                            this.updateTicket(data.parent);
                        }
                        break;
                    case "ticketassigned":
                        this.notifyService.increment(site, 'Assigned Tickets');
                        break;
                    case "ticketreassigned":
                        if (isAgent) {
                            this.notifyService.increment(site, 'Assigned Tickets');
                            // if (data && data.parent_id && data.parent && data.parent.table && data.parent.ticketId) {
                            //     this.updateTicketReassigned(data.parent);
                            // }
                        }
                        break;
                    case "aticketassigned":
                        this.notifyService.increment(site, 'Assigned Tickets');
                        break;
                    case "aticketreassigned":
                        if (isAgent) {
                            this.notifyService.increment(site, 'Assigned Tickets');
                            // if (data && data.parent_id && data.parent && data.parent.table && data.parent.ticketId) {
                            //     this.updateTicketReassigned(data.parent);
                            // }
                        }
                        break;
                    case "ticketabandon":
                        this.notifyService.increment(site, 'Assigned Abandon');
                        break;
                    case "ticketremoved":
                        if (isAgent) { this.notifyService.decrement(site, 'Assigned Tickets'); }
                        break;
                    case "aticketremoved":
                        if (isAgent) { this.notifyService.decrement(site, 'Assigned Tickets'); }
                        break;
                    case "ticketclosed":
                        this.notifyService.decrement(site, 'Assigned Tickets');
                        this.notifyService.increment(site, 'Assigned Closed Tickets');
                        break;
                    case "aclosedticketremoved":
                        this.notifyService.decrement(site, 'Assigned Tickets');
                        this.notifyService.increment(site, 'Assigned Closed Tickets');
                        break;
                    case "newfeed":
                        this.notifyService.increment('Feed', 'Feed Count');
                        break;
                    case "removefeed":
                        if (data && (data.is_ticketable > 0 || data.is_ticketable > true)) {
                            this.notifyService.decrement('Feed', 'Feed Ticket Count');
                            this.notifyService.decrement('Feed', 'Feed Count');
                        } else {
                            this.notifyService.decrement('Feed', 'Feed Count');
                        }
                        break;
                    case "newticket":
                        if (data && (data.is_ticketable > 0 || data.is_ticketable > true)) {
                            this.notifyService.increment('Feed', 'Feed Ticket Count');
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    updateTicket(parent) {
        if (parent && parent.id && parent.table && parent.ticketId) {
            switch (parent.table) {
                case 'whatsapp':
                    this.whatsappTicketService.changeAllItemsByItem(parent.whatsapp_ticket);
                    this.whatsappTicketService.replacelibrary(parent.whatsapp_ticket);
                    break;
                case 'webchat':
                    this.webchatTicketService.changeAllItemsByItem(parent.webchat_ticket);
                    this.webchatTicketService.replacelibrary(parent.webchat_ticket);
                    break;
                case 'messenger':
                    this.messengerTicketService.changeAllItemsByItem(parent.messenger_ticket);
                    this.messengerTicketService.replacelibrary(parent.messenger_ticket);
                    break;
                case 'twitter':
                    this.tweetTicketService.changeAllItemsByItem(parent.tweet_ticket);
                    this.tweetTicketService.replacelibrary(parent.tweet_ticket);
                    break;
                case 'twitterdm':
                    this.tweetdmTicketService.changeAllItemsByItem(parent.tweetdm_ticket);
                    this.tweetdmTicketService.replacelibrary(parent.tweetdm_ticket);
                    break;
                case 'instagram':
                    this.instagramTicketService.changeAllItemsByItem(parent.instagram_ticket);
                    this.instagramTicketService.replacelibrary(parent.instagram_ticket);
                    break;
                case 'instadm':
                    this.instadmTicketService.changeAllItemsByItem(parent.instadm_ticket);
                    this.instadmTicketService.replacelibrary(parent.instadm_ticket);
                    break;
                case 'youtube':
                    this.youtubeTicketService.changeAllItemsByItem(parent.youtube_ticket);
                    this.youtubeTicketService.replacelibrary(parent.youtube_ticket);
                    break;
                default:
                    break;
            }
        }
    }
    // updateTicketReassigned(parent) {
    //     if (parent && parent.id && parent.table && parent.ticketId) {
    //         switch (parent.table) {
    //             case 'whatsapp':
    //                 this.whatsappTicketService.updateAllItemsByItem(parent.whatsapp_ticket);
    //                 this.whatsappTicketService.updateReplacelibrary(parent.whatsapp_ticket);
    //                 break;
    //             case 'webchat':
    //                 this.webchatTicketService.changeAllItemsByItem(parent.webchat_ticket);
    //                 this.webchatTicketService.replacelibrary(parent.webchat_ticket);
    //                 break;
    //             case 'messenger':
    //                 this.messengerTicketService.changeAllItemsByItem(parent.messenger_ticket);
    //                 this.messengerTicketService.replacelibrary(parent.messenger_ticket);
    //                 break;
    //             case 'twitter':
    //                 this.tweetTicketService.changeAllItemsByItem(parent.tweet_ticket);
    //                 this.tweetTicketService.replacelibrary(parent.tweet_ticket);
    //                 break;
    //             case 'twitterdm':
    //                 this.tweetdmTicketService.changeAllItemsByItem(parent.tweetdm_ticket);
    //                 this.tweetdmTicketService.replacelibrary(parent.tweetdm_ticket);
    //                 break;
    //             case 'instagram':
    //                 this.instagramTicketService.changeAllItemsByItem(parent.instagram_ticket);
    //                 this.instagramTicketService.replacelibrary(parent.instagram_ticket);
    //                 break;
    //             case 'instadm':
    //                 this.instadmTicketService.changeAllItemsByItem(parent.instadm_ticket);
    //                 this.instadmTicketService.replacelibrary(parent.instadm_ticket);
    //                 break;
    //             case 'youtube':
    //                 this.youtubeTicketService.changeAllItemsByItem(parent.youtube_ticket);
    //                 this.youtubeTicketService.replacelibrary(parent.youtube_ticket);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    // }



}


function updateFavicon(src) {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', src);
}

var globalVar = false;
function updateBrowserTap() {
    document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
            //do whatever you want
            globalVar = true;
            // updateFavicon('assets/img/scrm/notification.png');
        }
        else {
            //do whatever you want
            globalVar = false;
            updateFavicon('assets/img/scrm/favicon.ico');
        }
    },);
}