import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TeamService, Team, TeamModel,
	AreaDeliverySettingService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'app-area-settings',
	templateUrl: './area-settings.component.html',
	styleUrls: ['./area-settings.component.scss']
})
export class AreaSettingsComponent {
	dialogTitle: string = 'Area/City Delivery Settings';
	region: any;
	lang: any = 'en';
	team: Team;
	regionList: any = [];
	loader: boolean = true;
	subLoader: boolean = false;
	updatePrice: any;
	isNote: boolean = false;

	constructor(public userService: UserService,
		public areaDeliverySettingService: AreaDeliverySettingService,
		public matDialogRef: MatDialogRef<AreaSettingsComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {

		this.getInit(_data);
		this.areaDeliverySettingService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.regionList) { this.subLoader = false; }
			var isEnabed = data.filter(x => x.is_delivarable == true);
			if (isEnabed && isEnabed[0] && isEnabed[0].id) {
				this.isNote = false;
			}else{
				this.isNote = true;
			}
			this.regionList = data;
		});
		this.lang = localStorage.getItem('language');
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.region = data.item;

	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}


	priceUpdate(action, data = null) {
		if (action == 'edit') {
			this.regionList.forEach(price => {
				if (price._id == data._id) {
					price.isEditable = true;
				} else {
					price.isEditable = false;
				}
			});
		}
		if (action == 'remove') {
			this.regionList.forEach(price => {
				price.isEditable = false;
			});
		}
		if (action == 'update') {
			var settings = {
				"brand_id": this.region.brand_id,
				"region_id": this.region.id,
				"cities": [
					{
						"city_id": data.id,
						"is_delivarable": data.is_delivarable ? "yes" : "no",
						"price": this.updatePrice
					}
				]
			}
			this.areaDeliverySettingService.deliverySettings(settings);
		}
	}

	saveSetting(data, price) {
		this.updatePrice = price;
	}

	deliveryStatus(data, event) {
		if (event.checked) {
			var settings = {
				"brand_id": this.region.brand_id,
				"region_id": this.region.id,
				"cities": [
					{
						"city_id": data.id,
						"is_delivarable": "yes",
						"price": data.price ? data.price : 0
					}
				]
			}
			this.areaDeliverySettingService.deliverySettings(settings);
		} else {
			var settings = {
				"brand_id": this.region.brand_id,
				"region_id": this.region.id,
				"cities": [
					{
						"city_id": data.id,
						"is_delivarable": "no",
						"price": data.price ? data.price : 0
					}
				]
			}
			this.areaDeliverySettingService.deliverySettings(settings);
		}
	}

}
