import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ToastrComponent } from 'src/app/library/dialog/toastr/toastr.component';
import { StickyNavModule } from 'ng2-sticky-nav';

import { AppShareModule } from 'src/app/app-share.module';
import { PushNotificationService } from 'ngx-push-notifications';
import { CookieService } from 'ngx-cookie-service';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CountdownGlobalConfig } from 'ngx-countdown';

import { AppRoutingModule } from './app-routing.module';
import { Router } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { RequestCache, RequestCacheWithMap } from 'src/app/auth/request-cache.service';
import { HttpErrorHandler } from 'src/app/auth/http-error-handler.service';
import { MessageService } from 'src/app/auth/message.service';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { AuthInterceptor } from 'src/app/auth/auth.interceptor';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { LoaderService } from 'src/app/library/loader/loader.service';
import { LoaderInterceptor } from 'src/app/library/loader/loader.interceptor';
import { ErrorInterceptor } from 'src/app/auth/error.interceptor';
import { GlobalService, CommonService, PusherService, FirebaseService } from 'src/app/@tji/_dbShare';

import { AppComponent } from './app.component';

import { ComponentModule } from './component/component.module';
import { NewPageModule } from './newPage/new-page.module';
import { PageModule } from './page/page.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// import { AngularFirestoreModule } from '@angular/fire/firestore';

import { ColorPickerModule } from 'ngx-color-picker';

import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { IconPickerModule } from 'ngx-icon-picker';
// import { NgxElectronModule } from 'ngx-electron';
import { CsvModule } from '@ctrl/ngx-csv';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
let LocalToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
let socketConfig = {
	url: environment.socketUrl,
	options: {
		transports: environment.socketTransports,
		reconnection: true,
		autoConnect: true,
		query: { 'token': 'almulla-media' }
	}
};

import {NgxPrintModule} from 'ngx-print';


@NgModule({
    declarations: [
        AppComponent,
        ToastrComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            tapToDismiss: true,
            newestOnTop: true,
            timeOut: 5000,
            extendedTimeOut: 1000,
            disableTimeOut: false,
            maxOpened: 4,
            autoDismiss: true,
            easeTime: 300,
            positionClass: 'toast-bottom-right',
            progressBar: true,
            progressAnimation: 'decreasing',
            toastComponent: ToastrComponent,
            preventDuplicates: false,
        }),
        AppShareModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
            // cookieName: 'Tji-Xsrf-Cookie',
            // headerName: 'Tji-Xsrf-Header',
        }),
        // HttpClientInMemoryWebApiModule.forRoot(
        //   InMemoryDataService, {
        //     dataEncapsulation: false,
        //     passThruUnknownUrl: true,
        //     put204: false // return entity after PUT/update
        //   }
        // ),
        StickyNavModule,
        AppRoutingModule,
        ComponentModule,
        PageModule,
        NewPageModule,
        MatIconModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SocketIoModule.forRoot(socketConfig),
        AngularFireModule.initializeApp(environment.firebase),
        // AngularFireAnalyticsModule,
        // AngularFirestoreModule,
        ColorPickerModule,
        NgxScrollTopModule,
        ReactiveFormsModule,
        FormsModule,
        NgxCaptchaModule,
        // IconPickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CsvModule,
        NgxPrintModule,
        // NgxElectronModule
    ],
    providers: [
        MatIconRegistry,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            useFactory: function (router: Router) {
                return new AuthInterceptor(router);
            },
            multi: true,
            deps: [Router],
        },
        { provide: RequestCache, useClass: RequestCacheWithMap },
        HttpErrorHandler,
        MessageService,
        LoaderService,
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['alertGrey']
            }
        },
        { provide: CountdownGlobalConfig, useValue: undefined },
        GlobalService,
        CommonService,
        PusherService,
        PushNotificationService,
        FirebaseService,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

	constructor(matIconRegistry: MatIconRegistry,
		domSanitizer: DomSanitizer) {
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mdi.svg'))
			.addSvgIcon('fa-en', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/language/en.svg'))
			.addSvgIcon('fa-ar', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/language/ar.svg'))
			.addSvgIcon('fa-support', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/support.svg'))
			.addSvgIcon('fa-twitter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/twitter.svg'))
			.addSvgIcon('fa-twitterdm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/twitterdm.svg'))
			.addSvgIcon('fa-instagram', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagram.svg'))
			.addSvgIcon('fa-instagramdm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagramdm.svg'))
			.addSvgIcon('fa-instamdm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagramdm.svg'))
			.addSvgIcon('fa-messenger', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/messenger.svg'))
			.addSvgIcon('fa-facebook', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/facebook.svg'))
			.addSvgIcon('fa-whatsapp', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/whatsapp.svg'))
			.addSvgIcon('fa-youtube', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/youtube.svg'))
			.addSvgIcon('fa-googlechat', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/googlechat.svg'))
			.addSvgIcon('fa-webchat', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/webchat.svg'))
			.addSvgIcon('support', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/support.svg'))
			.addSvgIcon('twitter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/twitter.svg'))
			.addSvgIcon('twitterdm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/twitterdm.svg'))
			.addSvgIcon('instagram', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagram.svg'))
			.addSvgIcon('instagramdm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagramdm.svg'))
			.addSvgIcon('instadm', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/instagramdm.svg'))
			.addSvgIcon('messenger', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/messenger.svg'))
			.addSvgIcon('whatsapp', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/whatsapp.svg'))
			.addSvgIcon('youtube', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/youtube.svg'))
			.addSvgIcon('googlechat', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/googlechat.svg'))
			.addSvgIcon('webchat', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/webchat.svg'))
			.addSvgIcon('fa-support_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/support.svg'))
			.addSvgIcon('fa-twitter_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/twitter.svg'))
			.addSvgIcon('fa-twitterdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/twitterdm.svg'))
			.addSvgIcon('fa-instagram_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagram.svg'))
			.addSvgIcon('fa-instagramdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagramdm.svg'))
			.addSvgIcon('fa-instamdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagramdm.svg'))
			.addSvgIcon('fa-messenger_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/messenger.svg'))
			.addSvgIcon('fa-whatsapp_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/whatsapp.svg'))
			.addSvgIcon('fa-youtube_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/youtube.svg'))
			.addSvgIcon('fa-googlechat_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/googlechat.svg'))
			.addSvgIcon('fa-webchat_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/webchat.svg'))
			.addSvgIcon('support_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/support.svg'))
			.addSvgIcon('twitter_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/twitter.svg'))
			.addSvgIcon('twitterdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/twitterdm.svg'))
			.addSvgIcon('instagram_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagram.svg'))
			.addSvgIcon('instagramdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagramdm.svg'))
			.addSvgIcon('instamdm_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/instagramdm.svg'))
			.addSvgIcon('messenger_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/messenger.svg'))
			.addSvgIcon('whatsapp_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/whatsapp.svg'))
			.addSvgIcon('youtube_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/youtube.svg'))
			.addSvgIcon('googlechat_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/googlechat.svg'))
			.addSvgIcon('webchat_green', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/webchat.svg'))
			.addSvgIcon('fa-support_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/support.svg'))
			.addSvgIcon('fa-twitter_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/twitter.svg'))
			.addSvgIcon('fa-twitterdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/twitterdm.svg'))
			.addSvgIcon('fa-instagram_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagram.svg'))
			.addSvgIcon('fa-instagramdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagramdm.svg'))
			.addSvgIcon('fa-instamdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagramdm.svg'))
			.addSvgIcon('fa-messenger_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/messenger.svg'))
			.addSvgIcon('fa-whatsapp_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/whatsapp.svg'))
			.addSvgIcon('fa-youtube_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/youtube.svg'))
			.addSvgIcon('fa-googlechat_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/googlechat.svg'))
			.addSvgIcon('fa-webchat_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/webchat.svg'))
			.addSvgIcon('support_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/support.svg'))
			.addSvgIcon('twitter_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/twitter.svg'))
			.addSvgIcon('twitterdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/twitterdm.svg'))
			.addSvgIcon('instagram_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagram.svg'))
			.addSvgIcon('instagramdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagramdm.svg'))
			.addSvgIcon('instamdm_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/instagramdm.svg'))
			.addSvgIcon('messenger_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/messenger.svg'))
			.addSvgIcon('whatsapp_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/whatsapp.svg'))
			.addSvgIcon('googlechat_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/googlechat.svg'))
			.addSvgIcon('webchat_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/webchat.svg'))
			.addSvgIcon('youtube_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white/youtube.svg'))
            .addSvgIcon('ticket_closed', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/ticket.svg'))
            .addSvgIcon('contact_user', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green/user.svg'))
			.addSvgIcon('whatsappIcon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsappIcon.svg'))
			.addSvgIcon('dollar_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/default/dollar.svg')) // Or whatever path you placed;
	}
}
