import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveService, Intractive,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-simpleform',
	templateUrl: './simpleForm.component.html',
	styleUrls: ['./simpleForm.component.scss']
})

export class SimpleFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	intractive: Intractive;
	intractiveGroup: IntractiveGroup;
	action: string;
	typeName: string = 'body';
	simpleForm: UntypedFormGroup;

	types: Array<any> = [
		{ title: 'Answerable', value: 'answerable' },
		{ title: 'Yes/No', value: 'yes-no' },
		{ title: 'Rating', value: 'rating' },
		{ title: 'Objective', value: 'objective' },
	];
	public validationRules = {
		Number: { maxLength: 5 }
	};
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '15rem',
		minHeight: '3rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		customClasses: [
			{
				name: "quote",
				class: "quote",
			},
			{
				name: 'redText',
				class: 'redText'
			},
			{
				name: "titleText",
				class: "titleText",
				tag: "h1",
			},
		]
	};
	intractiveGroupData: any;
	liveMessage: any;
	constructor(public userService: UserService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<SimpleFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => this.intractive = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.typeName = (data && data.typeName) ? data.typeName : 'body';
			this.changeType(this.slugify(this.typeName));
			this.getInit(_data);
			this.simpleForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.intractiveGroupData = data ? data.item : '';
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Message';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Message';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		let json: any;
		if (this.intractiveGroup.json && this.intractiveGroup.json['type']) {
			json = null;
		}else{
			json = (this.intractiveGroup && this.intractiveGroup.json) ? JSON.parse(this.intractiveGroup.json) : null;
			this.liveMessage = this.intractiveGroup ? this.intractiveGroup.text : '';
		}
		return this._formBuilder.group({
			text: [this.intractiveGroup.text || null, [Validators.required, Validators.minLength(1)]],
			typeName: [this.intractiveGroup.typeName || null],
			intractive_id: [this.intractiveGroup.intractive_id || this.intractive.id || null, [Validators.required]],
			is_active: [this.intractiveGroup.is_active || true, [Validators.required]],
			order: [this.intractiveGroup.order || this.intractive.intractive_groups.length || null],
			optionA: [(json) ? json.optionA : null],
			optionB: [(json) ? json.optionB : null],
			optionC: [(json) ? json.optionC : null],
			optionD: [(json) ? json.optionD : null],
		});
	}

	changeType(event) {
		this.typeName = event;
		this.createForm();
	}

	slugify(string) {
		var slug = string.toLowerCase().trim();
		slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
		slug = slug.replace(/[\s-]+/g, '-');
		return slug;
	}

	checkMessageType() {
		if (this._data.isChoiceMsg && this.typeName === 'body') {
			return true;
		} else {
			if (this.typeName && (this.typeName === 'header' || this.typeName === 'footer' || this.typeName === 'body')) {
				return false;
			} else {
				return true;
			}
		}
	}
	isNotValid: boolean = true;
	onLoginValidation() {
		if (this.isNotValid) {
			this.validation();
		}
	}
	wordCount: any;
	formEnabled: boolean = false;
	onKey(event: KeyboardEvent) { // with type info
		this.wordCount = event.target['textContent'].length;
		this.onLoginValidation();
	}

	validation() {
		if (this.liveMessage) {
			this.resetError();
			if (this.wordCount > 1024) {
				this.liveMessageError = '1024 characters only allowed';
				this.simpleForm.setErrors({ 'invalid': true });
			} else if (!this.liveMessage) {
				this.liveMessageError = 'Live Message Required';
				this.simpleForm.setErrors({ 'invalid': true });
			} else {
				this.liveMessageError = '';
			}
			// this.liveMessageError = (this.liveMessage == "") ? "Live Message Required" : (this.liveMessage.length < 1025) ? "1024 characters only allowed" : "test";
			// return this.liveMessage != "" && this.liveMessage.length > 1025;
		}
	}
	liveMessageError: string = "";
	resetError() {
		this.liveMessageError = "";
	}
}