import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	FeedService, Feed,
	AgentService, Agent,
	BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'open-conversation-assign-form',
	templateUrl: './assignForm.component.html',
	styleUrls: ['./assignForm.component.scss']
})

export class AssignFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	feed: Feed;
	assignForm: UntypedFormGroup;
	ticketClassName: string = null;

	agents: Agent[] = [];
	agentParam: any;

	action: string;
	priorities: any[] = ['Low', 'Normal', 'Important', 'Very Important'];

	constructor(public userService: UserService,
		public feedService: FeedService,
		public agentService: AgentService,
		public matDialogRef: MatDialogRef<AssignFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.feedService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.feed = data;
			this.assignForm = this.createForm();
		});
		this.agentService.allActiveAgents.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getAgents() {
		this.agentService.getActiveAgent(this.feed.client_site_id);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Assign To';
				break;
			case 'edit':
				this.dialogTitle = 'Re-assign To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			conversation_id: [this.feed.id || null, [Validators.required]],
			agent_id: [null, [Validators.required]],
			priority: ['Low', [Validators.required]],
			client_site_id: [this.feed.client_site_id || null, [Validators.required]],
		});
	}

	changeBoard(boardId) { }
}
