export class SendLaterTemplateModel {
   _id: number;
	id:number;
	waba_id: number;
	client_site_id: number;
	client_id: number;
	name: string;
	category: string;
	allow_category_change: boolean;
	language: string;
	components: any;
	created_at: Date;
	updated_at: Date;
	status: string;
	required_values: any;
	siteName?:string;
	clientSiteName?:string;
	clientSiteColor?:string;
	clientSiteTextColor?:string;
    waba_name: string;
	templateComponent: any;
    business_id: number;
	business_name: string;
    rejected_reason: string;
    postData: any;
    isCarousel: boolean;
    description: any;

    /**
     * Constructor
     *
     * @param SendLaterTemplate
     */
    constructor(SendLaterTemplate) {
            this._id = SendLaterTemplate.id || '';
            this.id = SendLaterTemplate.id || '';
            this.waba_id = SendLaterTemplate.waba_id || '';
            this.client_site_id = SendLaterTemplate.client_site_id || '';
            this.client_id = SendLaterTemplate.client_id || '';
            this.name = SendLaterTemplate.name || '';
            this.category = SendLaterTemplate.category || '';
            this.allow_category_change = SendLaterTemplate.allow_category_change || '';
            this.language = SendLaterTemplate.language || '';
            this.components = SendLaterTemplate.components || '';
            this.created_at = SendLaterTemplate.created_at || '';
            this.updated_at = SendLaterTemplate.updated_at || '';
            this.status = SendLaterTemplate.status || '';
            this.required_values = SendLaterTemplate.required_values || '';
            this.siteName = SendLaterTemplate.siteName || '';
            this.clientSiteName = SendLaterTemplate.clientSiteName || '';
            this.clientSiteColor = SendLaterTemplate.clientSiteColor || '';
            this.clientSiteTextColor = SendLaterTemplate.clientSiteTextColor || '';
            this.waba_name = SendLaterTemplate.waba_name || '';
            this.templateComponent = SendLaterTemplate.templateComponent || '';
            this.business_id = SendLaterTemplate.business_id || '';
            this.business_name = SendLaterTemplate.business_name || '';
            this.rejected_reason = SendLaterTemplate.rejected_reason || '';
            this.postData = SendLaterTemplate.postData || '';
            this.isCarousel = SendLaterTemplate.isCarousel || false;
    }
}
