import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import {
	PremiumDashboard, CountWidget, ClientAgent, PremiumAlert,
	PremiumLiveTicket, PremiumLeadBoard, PremiumLiveMessage,
	PremiumPerformance,
} from './premiumDashboard.interface';
import {
	PremiumDashboardModel, CountWidgetModel, ClientAgentModel,
	PremiumAlertModel, PremiumLiveTicketModel, PremiumLeadBoardModel,
	PremiumLiveMessageModel, PremiumPerformanceModel
} from './premiumDashboard_model.model';
import { Webclip, WebclipService, WebclipModel } from 'src/app/@tji/_dbShare/webclip/webclip';

import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

import { AnyMxRecord } from 'dns';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class PremiumDashboardService implements Resolve<any> {
	url: string = 'premium-dashboard';
	routeParams: any;
	defaultParams: any = {};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private webclipSource = new BehaviorSubject<Webclip>(new WebclipModel({}));
	webclip = this.webclipSource.asObservable();

	private channelSource = new BehaviorSubject<any>(null);
	channel = this.channelSource.asObservable();

	private allItemsSource = new BehaviorSubject<PremiumDashboard>(new PremiumDashboardModel({}));
	allItems = this.allItemsSource.asObservable();

	private countWidgetsSource = new BehaviorSubject<CountWidget[]>([]);
	countWidgets = this.countWidgetsSource.asObservable();

	private agentsCountSource = new BehaviorSubject<Array<any>>([]);
	agentsCount = this.agentsCountSource.asObservable();

	private clientAgentsSource = new BehaviorSubject<ClientAgent[]>([]);
	clientAgents = this.clientAgentsSource.asObservable();

	private clientAgentSource = new BehaviorSubject<ClientAgent>(new ClientAgentModel({}));
	clientAgent = this.clientAgentSource.asObservable();

	private liveTicketsSource = new BehaviorSubject<PremiumLiveTicket[]>([]);
	liveTickets = this.liveTicketsSource.asObservable();

	private liveMessagesSource = new BehaviorSubject<PremiumLiveMessage[]>([]);
	liveMessages = this.liveMessagesSource.asObservable();

	private performancesSource = new BehaviorSubject<PremiumPerformance[]>([]);
	performances = this.performancesSource.asObservable();

	private leadBoardsSource = new BehaviorSubject<PremiumLeadBoard[]>([]);
	leadBoards = this.leadBoardsSource.asObservable();

	private alertsSource = new BehaviorSubject<PremiumAlert[]>([]);
	alerts = this.alertsSource.asObservable();

	private chartFourDataSource = new BehaviorSubject<PremiumDashboard>(new PremiumDashboardModel({}));
	chartFourData = this.chartFourDataSource.asObservable();

	private itemSource = new BehaviorSubject<PremiumDashboard>(new PremiumDashboardModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<PremiumDashboard>(new PremiumDashboardModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private ordersCountSource = new BehaviorSubject<Array<any>>([]);
	orderCounts = this.ordersCountSource.asObservable();

	private paymentsCountSource = new BehaviorSubject<Array<any>>([]);
	paymentsCounts = this.paymentsCountSource.asObservable();

	private performancesBestSellingSource = new BehaviorSubject<PremiumPerformance[]>([]);
	performancesBestSelling = this.performancesBestSellingSource.asObservable();

	private aiPaymentAgentsSource = new BehaviorSubject<any>([]);
	aiPaymentAgents = this.aiPaymentAgentsSource.asObservable();

	private flagsCountSource = new BehaviorSubject<any>([]);
	flagsCount = this.flagsCountSource.asObservable();

	private labelCountSource = new BehaviorSubject<any>([]);
	labelCount = this.labelCountSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		// this.overallPremiumDashboard();
		// return new Promise((resolve, reject) => {
		// 	Promise.all([
		// 		this.resetParams(),
		// 		// this.getWebclipByToken(),
		// 		// this.getAllItems(),
		// 		// this.getAllClientAgents(),
		// 		// this.getItem(),
		// 		// this.getChartFourData()
		// 	])
		// 		.then(() => {
		// 			resolve(null);
		// 		}, reject
		// 		);
		// });
	}

	unSubscribe() {
		// console.log('UnSubscribed PremiumDashboardService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on PremiumDashboardService');
	}

	newParamData: any;
	refressData: any;
	//Webclip
	changeWebclip(webclip: any) {
		this.webclipSource.next(webclip);
		if (webclip && webclip.id) {
			let newParam: any = {
				'token': webclip.token
			};
			var locRefressData = webclip.premium_refresh_intervel ? webclip.premium_refresh_intervel : 600
			this.refressData = locRefressData * 1000;
			this.newParamData = newParam;
			this.paramsInit(newParam);
			this.overallPremiumDashboard(newParam);
			setTimeout(() => {
				this.longLive()
			}, this.refressData);
		}
	}

	longLive() {
		this.overallPremiumDashboard(this.newParamData);
		setTimeout(() => {
			this.longLive();
		}, this.refressData);
	}

	//Channel
	changeChannel(channel: any) {
		this.channelSource.next(channel);
	}

	//CountWidgets
	changeAllCountWidgets(countWidgets: CountWidget[]) {
		this.countWidgetsSource.next(countWidgets);
	}

	changeCountWidget(countWidget: CountWidget, evenName: string) {
		if (countWidget && countWidget.client_site_id) {
			var oldCountWidgets: CountWidget[] = [];
			this.countWidgets.subscribe(data => oldCountWidgets = data);
			if (oldCountWidgets && oldCountWidgets.length > 0) {
				oldCountWidgets.forEach(oldWidgets => {
					if (oldWidgets.client_site_id === countWidget.client_site_id) {
						oldWidgets['count'].forEach(countData => {

							// if (evenName == 'newfeed' && countData.slug == 'total-tickets') {
							// 	countData.count = countData.count + 1;
							// }

							if (evenName == 'newfeed' && countData.slug == 'all_open-tickets') {
								countData.count = countData.count + 1;
							}
							if (evenName == 'removefeed' && countData.slug == 'all_open-tickets' && countWidget['is_ticketable'] == 0) {
								if (countData.count > 0) {
									countData.count = countData.count - 1;
								} else {
									countData.count = 0;
								}
							}
							if (evenName == 'removefeed' && countData.slug == 'conversation' && countWidget['is_ticketable'] == 1) {
								if (countData.count > 0) {
									countData.count = countData.count - 1;
								} else {
									countData.count = 0;
								}
							}
							if (evenName == 'queueticket' && countData.slug == 'conversation') {
								countData.count = countData.count + 1;
							}
							if (evenName == 'ticketclosed' && countData.slug == 'active-tickets') {
								if (countData.count > 0) {
									countData.count = countData.count - 1;
								} else {
									countData.count = 0;
								}
							}
							if (evenName == 'aticketassigned' && countData.slug == 'active-tickets') {
								countData.count = countData.count + 1;
							}

							if (evenName == 'ticketclosed' && countData.slug == 'closed-tickets') {
								countData.count = countData.count + 1;
							}
							if (evenName == 'aticketreopened' && countData.slug == 'active-tickets') {
								countData.count = countData.count + 1;
							}

							if (evenName == 'aticketreopened' && countData.slug == 'closed-tickets') {
								if (countData.count > 0) {
									countData.count = countData.count - 1;
								} else {
									countData.count = 0;
								}
							}


							// if (evenName == 'aticketreassigned' && countData.slug == 'closed-tickets') {
							// 	countData.count = countData.count - 1;
							// }
							// if (evenName == 'aticketreassigned' && countData.slug == 'active-tickets') {
							// 	countData.count = countData.count + 1;
							// }

						});
						this.countWidgetsSource.next(oldCountWidgets);
					}
				});

			}
		}
	}

	updateCountWidget(countWidget: any, evenName: string) {
		if (countWidget && countWidget.client_site_id) {
			var oldCountWidgets: CountWidget[] = [];
			this.countWidgets.subscribe(data => oldCountWidgets = data);
			if (oldCountWidgets && oldCountWidgets.length > 0) {
				oldCountWidgets.forEach(oldWidgets => {
					countWidget.forEach(newCountWidget => {
						if (oldWidgets.client_site_id === newCountWidget.id) {
							oldWidgets['count'].forEach(countData => {
								if (countData.slug == 'closed-tickets') {
									countData.count = newCountWidget.ticket_closed_today;
								}
								if (countData.slug == 'active-tickets') {
									countData.count = newCountWidget.ticket_current;
								}
								if (countData.slug == 'total-tickets') {
									countData.count = newCountWidget.ticket_total;
								}
							});
							this.countWidgetsSource.next(oldCountWidgets);
						}
					});

				});

			}
		}
	}

	//Agents Count update

	changeAgentCount(updateAgent: any, evenName: string) {
		var oldAgentsCount = [];
		var allAgentsList = [];
		this.agentsCount.subscribe(data => oldAgentsCount = data);
		this.clientAgents.subscribe(data => allAgentsList = data);
		if (oldAgentsCount && oldAgentsCount.length > 0) {
			allAgentsList.forEach(agent => {
				if (agent.id == updateAgent.userInfo.person_id) {
					agent.liveStatus = updateAgent.isOnline ? 'online' : 'offline'
				}
			});
			var offlineAgents = allAgentsList.filter(t => t.liveStatus == 'offline');
			var onlineAgents = allAgentsList.filter(t => t.liveStatus == 'online');
			var freeAgents = allAgentsList.filter(t => t.ticket_current == '0' && t.liveStatus == 'online');
			var activeAgents = allAgentsList.filter(t => t.ticket_current > '0' && t.liveStatus == 'online');
			oldAgentsCount.forEach(oldWidgets => {
				if (oldWidgets.name.trim() == 'Offline Agents') {
					oldWidgets.count = offlineAgents.length;
				}

				if (oldWidgets.name.trim() == 'Online Agents') {
					oldWidgets.count = onlineAgents.length;
				}

				if (oldWidgets.name.trim() == 'Active Free Agents') {
					oldWidgets.count = freeAgents.length;
				}

				if (oldWidgets.name.trim() == 'Active Agents') {
					oldWidgets.count = activeAgents.length;
				}
			});
			this.agentsCountSource.next(oldAgentsCount);
		}
	}

	//Agents Count update

	updateAgentCount() {
		var oldAgentsCount = [];
		var allAgentsList = [];
		this.agentsCount.subscribe(data => oldAgentsCount = data);
		this.clientAgents.subscribe(data => allAgentsList = data);
		if (oldAgentsCount && oldAgentsCount.length > 0) {
			var offlineAgents = allAgentsList.filter(t => t.liveStatus == 'offline');
			var onlineAgents = allAgentsList.filter(t => t.liveStatus == 'online');
			var freeAgents = allAgentsList.filter(t => t.ticket_current == '0' && t.liveStatus == 'online');
			var activeAgents = allAgentsList.filter(t => t.ticket_current > '0' && t.liveStatus == 'online');
			oldAgentsCount.forEach(oldWidgets => {
				if (oldWidgets.name.trim() == 'Offline Agents') {
					oldWidgets.count = offlineAgents.length;
				}
				if (oldWidgets.name.trim() == 'Online Agents') {
					oldWidgets.count = onlineAgents.length;
				}

				if (oldWidgets.name.trim() == 'Active Free Agents') {
					oldWidgets.count = freeAgents.length;
				}

				if (oldWidgets.name.trim() == 'Active Agents') {
					oldWidgets.count = activeAgents.length;
				}
			});
			this.agentsCountSource.next(oldAgentsCount);
		}
	}

	//change Order Count update

	changeOrderCount(order: any, evenName: string) {
		var oldOrderCount = [];
		var currentStatus = null;
		var updatedStatus = null;
		this.orderCounts.subscribe(data => oldOrderCount = data);
		if (oldOrderCount && oldOrderCount.length > 0) {
			if (order.OrderStatusInfo && order.OrderStatusInfo.length > 1) {
				currentStatus = order.OrderStatusInfo[0];
				updatedStatus = order.OrderStatusInfo[1];
			} else {
				console.log('order socket OrderStatusInfo data failed');
			}

			if (currentStatus && currentStatus.status && updatedStatus && updatedStatus.status) {

				oldOrderCount.forEach(oldWidgets => {
					if (evenName == 'orderUpdate' && oldWidgets.status.trim() == updatedStatus.status) {
						oldWidgets.count = oldWidgets.count + 1;
					}
					if (evenName == 'orderUpdate' && oldWidgets.status.trim() == currentStatus.status) {
						if (oldWidgets.count > 0) {
							oldWidgets.count = oldWidgets.count - 1;
						} else {
							oldWidgets.count = 0;
						}
					}
					this.ordersCountSource.next(oldOrderCount);
				});
			}

		}
	}

	//Payment Count update

	changePaymentCount(payment: any, evenName: string) {
		var oldPaymentCount = [];
		this.paymentsCounts.subscribe(data => oldPaymentCount = data);
		if (oldPaymentCount && oldPaymentCount.length > 0) {
			if (evenName == 'paymentCreate') {
				oldPaymentCount.forEach(oldWidgets => {
					if (oldWidgets.status.toLowerCase() == 'initiated') {
						oldWidgets.count = oldWidgets.count + 1;
					}
					this.paymentsCountSource.next(oldPaymentCount);
				});
			}
			if (evenName == 'paymentUpdate') {
				var updatedStatus = payment.PaymentStatusInfo[1];
				var currentStatus = payment.PaymentStatusInfo[0];
				oldPaymentCount.forEach(oldWidgets => {
					if (oldWidgets.status.trim() == updatedStatus.status) {
						oldWidgets.count = oldWidgets.count + 1;
					}
					if (oldWidgets.status.trim() == currentStatus.status) {
						if (oldWidgets.count > 0) {
							oldWidgets.count = oldWidgets.count - 1;
						} else {
							oldWidgets.count = 0;
						}
					}
					this.paymentsCountSource.next(oldPaymentCount);
				});

				// oldPaymentCount.forEach(oldWidgets => {
				// 	var updatedStatus = payment.PaymentStatusInfo.pop();
				// 	var currentStatus = payment.PaymentStatusInfo.pop();
				// 	if (evenName == 'paymentUpdate' && oldWidgets.status.trim().toLowerCase() == updatedStatus.status.trim().toLowerCase()) {
				// 		oldWidgets.count = oldWidgets.count + 1;
				// 	}
				// 	if (evenName == 'paymentUpdate' && oldWidgets.status.trim().toLowerCase() == currentStatus.status.trim().toLowerCase()) {
				// 		if (oldWidgets.count > 0) {
				// 			oldWidgets.count = oldWidgets.count - 1;
				// 		} else {
				// 			oldWidgets.count = 0;
				// 		}
				// 	}

				// 	this.paymentsCountSource.next(oldWidgets);
				// });
			}
		}
	}

	//change agent ticket Count update

	changeAgentActive(agent: any, evenName: string) {
		var oldAgentCount = [];
		this.clientAgents.subscribe(data => oldAgentCount = data);
		if (oldAgentCount && oldAgentCount.length > 0) {
			oldAgentCount.forEach(oldWidgets => {
				if (oldWidgets.id == agent.userInfo.person_id && evenName == "logout") {
					oldWidgets.liveStatus == "offline"
				}
				if (oldWidgets.id == agent.userInfo.person_id && evenName == "login") {
					oldWidgets.liveStatus == "online"
				}
				this.clientAgentsSource.next(oldAgentCount);
			});

		}
	}

	updateAgentTicketCount(ticket: any, evenName: string) {
		var oldAgentCount = [];
		this.clientAgents.subscribe(data => oldAgentCount = data);
		if (oldAgentCount && oldAgentCount.length > 0) {
			oldAgentCount.forEach(oldWidgets => {
				if (evenName == "aticketremoved" && oldWidgets.id == ticket.agent_id) {
					oldWidgets.ticket_counts.forEach(counts => {
						if (counts.name == 'Total Tickets') {
							if (counts.count > 0) {
								counts.count = counts.count - 1;
							} else {
								counts.count = 0;
							}
						}
						if (counts.name == 'Total Active Tickets') {
							if (counts.count > 0) {
								counts.count = counts.count - 1;
							} else {
								counts.count = 0;
							}
						}
					});
				}
				if (evenName == "aticketreassigned" && oldWidgets.id == ticket.agent_id) {
					oldWidgets.ticket_counts.forEach(counts => {
						if (counts.name == 'Total Tickets') {
							counts.count = counts.count + 1;
						}
						if (counts.name == 'Total Active Tickets') {
							counts.count = counts.count + 1;
						}
					});
				}
				if (evenName == "aticketassigned" && oldWidgets.id == ticket.agent_id) {
					oldWidgets.ticket_counts.forEach(counts => {
						if (counts.name == 'Total Tickets') {
							counts.count = counts.count + 1;
						}
						if (counts.name == 'Total Active Tickets') {
							counts.count = counts.count + 1;
						}
					});
				}
				if (evenName == "ticketclosed" && oldWidgets.id == ticket.agent_id) {
					oldWidgets.ticket_counts.forEach(counts => {
						if (counts.name == 'Total Closed Tickets') {
							counts.count = counts.count + 1;
						}
						if (counts.name == 'Total Active Tickets') {
							if (counts.count > 0) {
								counts.count = counts.count - 1;
							} else {
								counts.count = 0;
							}
						}
					});
				}
				if (evenName == "aticketreopened" && oldWidgets.id == ticket.agent_id) {
					oldWidgets.ticket_counts.forEach(counts => {
						if (counts.name == 'Total Closed Tickets') {
							if (counts.count > 0) {
								counts.count = counts.count - 1;
							} else {
								counts.count = 0;
							}
						}
						if (counts.name == 'Total Active Tickets') {
							counts.count = counts.count + 1;
						}
					});
				}
				this.clientAgentsSource.next(oldAgentCount);
			});

		}
		this.updateAgentCount();
	}


	//change agent ticket Count update

	changeAiAgentActive(agent: any, evenName: string) {
		var oldAgentCount = [];
		this.aiPaymentAgents.subscribe(data => oldAgentCount = data);
		if (oldAgentCount && oldAgentCount.length > 0) {
			oldAgentCount.forEach(oldWidgets => {
				if (oldWidgets.person_id == agent.userInfo.person_id && evenName == "logout") {
					oldWidgets.liveStatus == "offline"
				}
				if (oldWidgets.person_id == agent.userInfo.person_id && evenName == "login") {
					oldWidgets.liveStatus == "online"
				}
				this.aiPaymentAgentsSource.next(oldAgentCount);
			});

		}
	}

	//Aicollection payment count update for agents

	changeAcicollectionCount(aicollection: any, evenName: string) {
		var oldAicollectionCount = [];
		this.aiPaymentAgents.subscribe(data => oldAicollectionCount = data);
		if (oldAicollectionCount && oldAicollectionCount.length > 0 && evenName == "paymentCreate") {
			oldAicollectionCount.forEach(oldWidgets => {
				if (oldWidgets.person_id == aicollection.person_id) {
					oldWidgets.payment_counts.forEach(aiStatus => {
						if (aiStatus.status == aicollection.payment_status) {
							aiStatus.count = aiStatus.count + 1;
						}
					});
				}
				this.aiPaymentAgentsSource.next(oldAicollectionCount);
			});
		}
		if (oldAicollectionCount && oldAicollectionCount.length > 0 && evenName == "paymentUpdate") {
			oldAicollectionCount.forEach(oldWidgets => {
				if (oldWidgets.person_id == aicollection.person_id) {
					var updatedStatus = aicollection.PaymentStatusInfo[1];
					var currentStatus = aicollection.PaymentStatusInfo[0];
					oldWidgets.payment_counts.forEach(aiStatus => {
						if (aiStatus.status.trim() == updatedStatus.status) {
							aiStatus.count = aiStatus.count + 1;
						}
						if (aiStatus.status.trim() == currentStatus.status) {
							if (aiStatus.count > 0) {
								aiStatus.count = aiStatus.count - 1;
							} else {
								aiStatus.count = 0;
							}
						}
					});
				}
				this.aiPaymentAgentsSource.next(oldAicollectionCount);
			});
		}
	}

	//Agents Count
	changeAgentsCount(agentsCount: Array<any>) {
		this.agentsCountSource.next(agentsCount);
	}

	//Agents CountpaymentsCountSource
	changeOrdersCount(ordersCount: Array<any>) {
		this.ordersCountSource.next(ordersCount);
	}

	changePaymentsCount(ordersCount: Array<any>) {
		this.paymentsCountSource.next(ordersCount);
	}

	changeBestSelling(ordersCount: Array<any>) {
		this.performancesBestSellingSource.next(ordersCount);
	}

	// Agents;
	changeClientAgents(clientAgents: ClientAgent[]) {
		this.clientAgentsSource.next(clientAgents);
	}

	changeClientAgent(clientAgent: ClientAgent) {
		this.changeClientAgentsByItem(clientAgent);
		this.clientAgentSource.next(clientAgent);
	}

	changeClientAgentsByItem(item: ClientAgent,) {
		let allItems = [];
		this.clientAgents.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeClientAgents(allItems);
	}

	getAllClientAgents(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll('person/all-agents', params, false)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				// this.changeClientAgents(data.data.data);
			},
				error => console.log('Error ::' + error)
			);
	}


	//LiveTickets
	changeAllLiveTickets(liveTickets: PremiumLiveTicket[]) {
		this.liveTicketsSource.next(liveTickets);
	}

	appendLiveTicket(liveTicket: any) {
		var oldLiveTickets: PremiumLiveTicket[] = [];
		this.liveTickets.subscribe(data => oldLiveTickets = data);
		if (oldLiveTickets && oldLiveTickets.length > 0) {
			oldLiveTickets.push(liveTicket);
			this.changeAllLiveTickets(oldLiveTickets);
		} else {
			this.changeAllLiveTickets([liveTicket]);
		}
	}

	shiftLiveTicket(liveTicket: PremiumLiveTicket) {
		var oldLiveTickets: PremiumLiveTicket[] = [];
		let isChanged: boolean = false;
		this.liveTickets.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => oldLiveTickets = data);
		if (oldLiveTickets && oldLiveTickets.length > 5) {
			for (var i = 0; i < oldLiveTickets.length; ++i) {
				if (oldLiveTickets[i] === liveTicket) {
					oldLiveTickets.shift();
					isChanged = true;
				}
			}
		}
		if (isChanged) { this.changeAllLiveTickets(oldLiveTickets); }
	}


	//LiveMessages
	changeAllLiveMessages(liveMessages: PremiumLiveMessage[]) {
		this.liveMessagesSource.next(liveMessages);
	}

	appendLiveMessage(liveMessage: any, event) {
		var oldLiveMessages: any;
		this.liveMessages.subscribe(data => oldLiveMessages = data);
		if (oldLiveMessages && oldLiveMessages.length > 0) {

			if (event == 'ticketReply') {
				var newLiveMessages = {
					isTicket: true,
					message: '',
					type: 'livemessges',
					ticketCode: liveMessage?.TicketInfo?.code,
					agentName: liveMessage?.UsersInfo?.username,
					client_id: liveMessage?.client_id,
					accountName: liveMessage?.clientSiteInfo?.account_name,
					siteName: liveMessage?.clientSiteInfo?.details?.name,
					peopleName: liveMessage?.peopleData.name,
					unique_ref: liveMessage?.to,
					avatar_url: "https://scrmwapp.s3.amazonaws.com/0clients/645/media/nouser.jpg",
					status: liveMessage?.status,
					created_at: moment(liveMessage?.created_at + '.000+0300').local(),

				};

				if (liveMessage.media != 'text') {
					newLiveMessages['description'] = 'is send ' + liveMessage?.media;
				} else {
					newLiveMessages['description'] = liveMessage.text
				}

				oldLiveMessages.push(newLiveMessages);
				this.changeAllLiveMessages(oldLiveMessages);
			}

			if (event == 'newmessage') {
				var newLiveMessage = {
					isTicket: true,
					message: '',
					type: 'livemessges',
					ticketCode: liveMessage?.ticketCode,
					agentName: liveMessage?.agentName,
					client_id: liveMessage?.client_id,
					accountName: liveMessage?.clientSiteName,
					siteName: liveMessage?.siteName,
					peopleName: liveMessage?.peopleLabelName,
					unique_ref: liveMessage?.from,
					avatar_url: liveMessage?.peopleAvatar,
					status: liveMessage?.status,
					created_at: liveMessage?.created_at,
				}
				if (liveMessage.media != 'text') {
					newLiveMessage['description'] = 'is send ' + liveMessage?.media;
				} else {
					newLiveMessage['description'] = liveMessage.text
				}

				oldLiveMessages.push(newLiveMessage);
				this.changeAllLiveMessages(oldLiveMessages);

			}

			if (event == 'aNewmessage') {
				var newLiveMessag = {
					isTicket: true,
					message: '',
					type: 'livemessges',
					ticketCode: liveMessage?.code,
					agentName: liveMessage?.UsersInfo?.name,
					client_id: liveMessage?.client_id,
					accountName: liveMessage?.clientSite?.account_name,
					siteName: liveMessage?.clientSite?.details?.name,
					peopleName: liveMessage?.peopleData?.name,
					unique_ref: liveMessage?.ChatData?.to,
					avatar_url: "https://scrmwapp.s3.amazonaws.com/0clients/645/media/nouser.jpg",
					status: liveMessage?.ChatData?.status,
					created_at: moment(liveMessage?.created_at + '.000+0300').local(),
				}
				if (liveMessage.ChatData.media != 'text') {
					newLiveMessag['description'] = 'is send ' + liveMessage?.ChatData?.media;
				} else {
					newLiveMessag['description'] = liveMessage.ChatData.text
				}
				oldLiveMessages.push(newLiveMessag);
				this.changeAllLiveMessages(oldLiveMessages);

			}
		} else {
			this.changeAllLiveMessages([liveMessage]);
		}
	}


	shiftLiveMessage(liveMessage: PremiumLiveMessage) {
		var oldLiveMessages: PremiumLiveMessage[] = [];
		let isChanged: boolean = false;
		this.liveMessages.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => oldLiveMessages = data);
		if (oldLiveMessages && oldLiveMessages.length > 4) {
			for (var i = 0; i < oldLiveMessages.length; ++i) {
				if (oldLiveMessages[i] === liveMessage) {
					oldLiveMessages.shift();
					isChanged = true;
				}
			}
		}
		if (isChanged) { this.changeAllLiveMessages(oldLiveMessages); }
	}


	//Performance
	changeAllPerformances(performances: any[]) {
		this.performancesSource.next(performances);
	}

	appendPerformance(performance: any, status: any) {
		var oldPerformances: any;
		this.performances.subscribe(data => oldPerformances = data);
		if (oldPerformances && oldPerformances.length > 0) {
			var newPerformances = {
				"client_id": performance?.client_id,
				"accountName": performance?.clientSite?.account_name,
				"siteName": performance?.clientSite?.details.name,
				"agentName": performance?.agentInfo?.name,
				"peopleName": performance?.leadInfo?.name,
				"createdByName": "",
				"action": status,
				"title": performance?.code,
				"ticketId": performance?.id,
				"ticketCode": performance?.code,
				'created_at': performance?.created_at, // moment(performance.created_at + '.000+0300').local(),
				"type": "performance"
			}
			oldPerformances.push(newPerformances);
			this.changeAllPerformances(oldPerformances);
		} else {
			var newPerformancess = {
				"client_id": performance?.client_id,
				"accountName": performance?.clientSite?.account_name,
				"siteName": performance?.clientSite?.details.name,
				"agentName": performance?.agentInfo?.name,
				"peopleName": performance?.leadInfo?.name,
				"createdByName": "",
				"action": status,
				"title": performance?.code,
				"ticketId": performance?.id,
				"ticketCode": performance?.code,
				'created_at': performance?.created_at, //moment(performance.created_at + '.000+0300').local(),
				"type": "performance"
			}
			this.changeAllPerformances([newPerformancess]);
		}
	}

	shiftPerformance(performance: PremiumPerformance) {
		var oldPerformances: PremiumPerformance[] = [];
		let isChanged: boolean = false;
		this.performances.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => oldPerformances = data);
		if (oldPerformances && oldPerformances.length > 4) {
			for (var i = 0; i < oldPerformances.length; ++i) {
				if (oldPerformances[i] === performance) {
					oldPerformances.shift();
					isChanged = true;
				}
			}
		}
		if (isChanged) { this.changeAllPerformances(oldPerformances); }
	}


	//LeadBoards
	changeAllLeadBoards(leadBoards: PremiumLeadBoard[]) {
		this.leadBoardsSource.next(leadBoards);
	}

	appendLeadBoard(leadBoard: any) {
		var oldLeadBoards: PremiumLeadBoard[] = [];
		this.leadBoards.subscribe(data => oldLeadBoards = data);
		if (oldLeadBoards && oldLeadBoards.length > 0) {
			oldLeadBoards.unshift(leadBoard);
			this.changeAllLeadBoards(oldLeadBoards);
		} else {
			this.changeAllLeadBoards([leadBoard]);
		}
	}

	shiftLeadBoard(leadBoard: PremiumLeadBoard) {
		var oldLeadBoards: PremiumLeadBoard[] = [];
		let isChanged: boolean = false;
		this.leadBoards.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => oldLeadBoards = data);
		if (oldLeadBoards && oldLeadBoards.length > 5) {
			for (var i = 0; i < oldLeadBoards.length; ++i) {
				if (oldLeadBoards[i] === leadBoard) {
					oldLeadBoards.pop();
					isChanged = true;
				}
			}
		}
		if (isChanged) { this.changeAllLeadBoards(oldLeadBoards); }
	}




	// Alerts
	changeAlerts(alerts: PremiumAlert[]) {
		this.alertsSource.next(alerts);
	}

	addAlert(alert: any) {
		var oldAlerts: Array<any> = [];
		this.alerts.subscribe(data => {
			oldAlerts = data;
		});
		oldAlerts.push(alert);
		this.changeAlerts(oldAlerts);
	}

	removeAlert() {
		var oldAlerts: Array<any> = [];
		this.alerts.subscribe(data => {
			oldAlerts = data;
		});
		oldAlerts.slice(1);
		this.changeAlerts(oldAlerts);
	}

	shiftAlert(alert: PremiumAlert) {
		var oldAlerts: PremiumAlert[] = [];
		let isChanged: boolean = false;
		this.alerts.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => oldAlerts = data);
		if (oldAlerts && oldAlerts.length > 4) {
			for (var i = 0; i < oldAlerts.length; ++i) {
				if (oldAlerts[i] === alert) {
					oldAlerts.shift();
					isChanged = true;
				}
			}
		}
		if (isChanged) { this.changeAlerts(oldAlerts); }
	}



	// Dashboard Items
	changeAllItems(allItems: PremiumDashboard) {
		this.allItemsSource.next(allItems);
		// if (allItems && allItems.count_widgets && allItems.count_widgets.length > 0) {
		// 	this.changeAllCountWidgets(allItems.count_widgets);
		// }
		// if (allItems && allItems.agents_count && allItems.agents_count.length > 0) {
		// 	this.changeAgentsCount(allItems.agents_count);
		// }
		// if (allItems && allItems.lead_boards && allItems.lead_boards.length > 0) {
		// 	this.changeAllLeadBoards(allItems.lead_boards);
		// }
		// if (allItems && allItems.live_tickets && allItems.live_tickets.length > 0) {
		// 	this.changeAllLiveTickets(allItems.live_tickets);
		// }
		// if (allItems && allItems.live_messages && allItems.live_messages.length > 0) {
		// 	this.changeAllLiveMessages(allItems.live_messages);
		// }
		// if (allItems && allItems.performances && allItems.performances.length > 0) {
		// 	this.changeAllPerformances(allItems.performances);
		// }
	}

	changeChartFourData(allItems: PremiumDashboard) {
		this.chartFourDataSource.next(allItems);
	}

	changeItem(item: PremiumDashboard) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: PremiumDashboard) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: PremiumDashboard) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params, false)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItems(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	getChartFourData(params: any = null) {
		params = this.paramsInit(params);
		let url = 'chartFourData';
		this.commonService.getAll(url, params, false)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeChartFourData(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new PremiumDashboardModel({}));
		}
	}


	getWebclipByKey(key: string = null) {
		if (key) {
			localStorage.removeItem('tji_user');
			localStorage.removeItem('tji_token');
			localStorage.clear();
			sessionStorage.clear();
			let url: string = 'me/pre/webclipbyotp';
			let param: any = { 'otp': key };
			this.commonService.getPremiumLogin(url, param, "optionAiOne")
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						// localStorage.setItem('pre_tk_ot', key)
						// localStorage.setItem('tji_token', data.data.token);
						var auth = {
							isPrepremiumDashboard: true,
						}
						this.commonService.tji_token = data.data.token;
						localStorage.setItem('tji_user', JSON.stringify(auth));
						localStorage.setItem('tji_token', JSON.stringify(data.data.token));
						this.changeWebclip(data.data);
					} else {
						// this.alertService.webErrorShow(data);
						this.alertMessage('warn', data.errors.message);
					}
				},
					error => {
						console.log('Error ::' + error);
						this.alertMessage('warn', error);
					}
				);
		}
		else {
			this.changeWebclip(new WebclipModel({}));
		}
	}

	getWebclipByToken(token: string = null) {
		let webkeyToken: string = (token) ? token : this.routeParams.token;
		if (webkeyToken) {
			let url: string = 'get-webclip-by-token';
			let param: any = { 'token': webkeyToken, 'with': 'client,user' };
			this.commonService.getItemByParam(url, param, false)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeWebclip(data.data);
				},
					error => {
						console.log('Error ::' + error);
						this.alertMessage('danger', error);
					}
				);
		}
		else {
			this.changeWebclip(new WebclipModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				// this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
	}

	alertMessage(type: string, message) {
		let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		this.snackBar.openFromComponent(AlertComponent, {
			panelClass: ['alert' + capitalType],
			data: {
				message: message,
				type: type.toLowerCase(),
			}
		});
	}
	//Premium Dashboard Correction
	overallPremiumDashboard(newParam: any = null) {
		this.premiumOrdersOverallCount(newParam);
		this.premiumPaymentsOverallCount(newParam);
		this.premiumBestSelling(newParam);
		this.premiumAllCount(newParam);
		this.agentliveMessage(newParam);
		this.agentTicketCounts(newParam);
		this.agentPerfomance(newParam);
		this.premiumLeadsBoard(newParam);
		this.premiumLiveTickets(newParam);
		this.premiumagentOverallCount(newParam);
		this.premiumAiAgent(newParam);
		this.premiumFlagCount(newParam);
		this.premiumLabelCount(newParam);

	}

	agentTicketCounts(newParam) {
		this.commonService.getPremium('premiumdb', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.agentsCountSource.next(data.data.agents_count);
				} else {
					this.agentsCountSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})
	}

	agentPerfomance(newParam) {
		this.commonService.getPremium('premiumdb/performances', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					data.data.forEach(element => {
						element['created_at'] = moment(element.created_at + '.000+0300').local();
					});
					this.performancesSource.next(data.data);
				} else {
					this.performancesSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}
	agentliveMessage(newParam) {
		this.commonService.getPremium('premiumdb/liveMessages', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						data.data.forEach(element => {
							element['created_at'] = moment(element.created_at + '.000+0300').local();
							if (element.media != 'text') {
								element['description'] = 'is send ' + element?.media;
								element['description'] = 'is send ' + element?.media;
							} else {
								element['description'] = element.description
							}
						});
					}

					this.liveMessagesSource.next(data.data);
				} else {
					this.liveMessagesSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})
	}

	premiumLeadsBoard(newParam) {
		this.commonService.getPremium('premiumdb/leadsBoard', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				var datasss = Intl.DateTimeFormat().resolvedOptions().timeZone
				if (data.success) {
					if (data.data && data.data.length > 0) {
						data.data.forEach(element => {
							var updateDate: any;
							updateDate = new Date(element['updated_at']).toUTCString()
							// element['updated_at'] = moment(element['updated_at']).tz("Asia/Calcutta").format(); //new Date(element.updated_at) // moment(data.data.updated_at).format('dddd MMMM D YYYY, h:mm:ss a'); // data.data.updated_at.toLocaleTimeString(datasss)// moment(data.data.updated_at).format('dddd MMMM D YYYY, h:mm:ss a'); //new Date(data.data.created_at) //moment(data.data.created_at + '.000+0300').local();
							// element['updated_at'] = moment(1369266934311).utcOffset(60).format('YYYY-MM-DD HH:mm')
							element['updated_at'] = moment(element['updated_at']).tz('Asia/Calcutta').format('YYYY-MM-DD HH:mm')
						});
					}
					this.leadBoardsSource.next(data.data);
				} else {
					this.leadBoardsSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumLiveTickets(newParam) {
		this.commonService.getPremium('premiumdb/liveTickets', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.liveTicketsSource.next(data.data);
				} else {
					this.liveTicketsSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumagentOverallCount(newParam) {
		this.commonService.getPremium('premiumdb/agentsTciketsCount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.clientAgentsSource.next(data.data);
				} else {
					this.clientAgentsSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumOrdersOverallCount(newParam) {
		this.commonService.getPremium('premiumdb/orderCount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.ordersCountSource.next(data.data.statuses);
				} else {
					this.ordersCountSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	//premium-dashboard-tickets-count
	premiumAllCount(newParam) {
		this.commonService.getPremium('premiumdb/ticketsCount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						data.data.forEach(countWidget => {
							var counts = [
								{
									"name": "Total Tickets",
									"slug": "total-tickets",
									"icon": "style",
									"color": "indigo-300-fg",
									"count": countWidget['ticket_total'] ? countWidget['ticket_total'] : 0,
								},
								{
									"name": "Active Tickets",
									"slug": "active-tickets",
									"icon": "style",
									"color": "green-400-fg",
									"count": countWidget['ticket_current'] ? countWidget['ticket_current'] : 0,
								},
								{
									"name": "Conversation(s)",
									"slug": "all_open-tickets",
									"icon": "style",
									"color": "yellow-300-fg",
									"count": countWidget['open_count'] ? countWidget['open_count'] : 0,
								},
								{
									"name": "Closed Tickets",
									"slug": "closed-tickets",
									"icon": "style",
									"color": "red-300-fg",
									"count": countWidget['ticket_closed_today'] ? countWidget['ticket_closed_today'] : 0,
								},
								{
									"name": "Tickets on Queue",
									"slug": "conversation",
									"icon": "queue",
									"color": "cyan-400-fg",
									"count": countWidget['queue_count'] ? countWidget['queue_count'] : 0,
								}
							]
							countWidget['count'] = counts;
						});
					}
					this.countWidgetsSource.next(data.data);
				} else {
					this.countWidgetsSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumPaymentsOverallCount(newParam) {
		this.commonService.getPremium('premiumdb/paymentCount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.paymentsCountSource.next(data.data.statuses);
				} else {
					this.paymentsCountSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumBestSelling(newParam) {
		this.commonService.getPremium('premiumdb/bestSelling', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var bestSelling = [];
					var additional = data.additional
					if (additional && additional.length > 0) {
						data.data.forEach(element => {
							additional.forEach(additional => {
								if (element.id == additional._id) {
									var item = {
										count: element.count,
										_id: element.id,
										name: additional.name,
										currency: additional.currency,
										price: additional.price,
										image_url: additional.image_url,
										description: additional.description,
										created_at: additional.created_at,
									}
									bestSelling.push(item);
								}
							});
						});
					}
					this.performancesBestSellingSource.next(bestSelling);
				} else {
					this.performancesBestSellingSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumAiAgent(newParam) {
		this.commonService.getPremium('premiumdb/aiCollectionLinkCount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var bestSelling = [];
					if (data.data && data.data.length > 0) {
						data.data.forEach(aiCollection => {
							var item = {
								"id": aiCollection.id,
								"name": aiCollection.username ? aiCollection.username : "Robot",
								"is_active": aiCollection.is_active ? aiCollection.is_active : 0,
								"liveStatus": aiCollection.liveStatus ? aiCollection.liveStatus : 'offline',
								"avatar_url": '',
								"person_id": aiCollection.person_id,
								"payment_counts": []
							}
							item.payment_counts = aiCollection.statuses;
							if (aiCollection.MoreInfo && aiCollection.MoreInfo.is_supervisor) {
								item['is_supervisor'] = aiCollection.MoreInfo.is_supervisor;
							} else {
								item['is_supervisor'] = 0;
							}
							if (aiCollection.person_info && aiCollection.person_info.image_api) {
								item.avatar_url = aiCollection.person_info.image_api;
							} else if (aiCollection.clientInfo && aiCollection.clientInfo.details && aiCollection.clientInfo.details.image_api) {
								item.avatar_url = aiCollection.clientInfo.details.image_api;
							} else {
								item.avatar_url = 'https://scrmwapp.s3.amazonaws.com/0clients/645/media/nouser.jpg';
							}
							bestSelling.push(item)
						});
					}
					this.aiPaymentAgentsSource.next(bestSelling);
				} else {
					this.aiPaymentAgentsSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})

	}

	premiumFlagCount(newParam) {
		this.commonService.getPremium('premiumdb/flagcount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.flagsCountSource.next(data.data);
				} else {
					this.flagsCountSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})
	}

	premiumLabelCount(newParam) {
		this.commonService.getPremium('premiumdb/labelcount', newParam, 'optionAiOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.labelCountSource.next(data.data);
				} else {
					this.labelCountSource.next([]);
					this.alertService.webErrorShow(data);
				}
			}, error => {
				this.alert('Danger', error);
			})
	}
}
