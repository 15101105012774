import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// import { FieldConfig } from 'src/app/@tji/_dbShare';
import { FieldConfig } from 'src/app/@tji/_dbShare/dynamicForm/field.interface';
@Component({
  selector: 'app-checkbox',
  template: `
    <div class="demo-full-width margin-top" [formGroup]="group">
      <mat-checkbox [formControlName]="field.name">{{
        field.label
      }}</mat-checkbox>
    </div>
  `,
  styles: ['.mat-form-field { width: 100% !important; }'],
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
