import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenav } from '@angular/material/sidenav';
import { FormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';

import { User, UserService, ClientSiteService, ClientSiteModel, SiteService, SiteModel } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	isAdmin: any;
	@ViewChild('rightSideBar') rightSideBar: MatSidenav;
	@ViewChild(ListComponent) listComponent;
	rightSideView: string = 'new-site';

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		private router: Router,
		public siteService: SiteService,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.isAdmin = this.userService.isAuthUserIsAdmin();
	}

	ngOnInit() { 
		this.setWhatsappSettings();
		this.setItrsLanguage();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	toggleRightSidebar() {
		this.rightSideView = 'new-site';
		this.rightSideBar.toggle();
	}

	setWhatsappSettings() {
		this.clientSiteService.setWhatsappSettings.pipe(untilDestroyed(this)).subscribe(data => {
			this.rightSideView = 'whatsapp-settings';
			if (data) { this.rightSideBar.open(); }
			else { this.rightSideBar.close(); }
		});
	}

	setItrsLanguage() {
		this.clientSiteService.setItrsLanguage.pipe(untilDestroyed(this)).subscribe(data => {
			this.rightSideView = 'itrs-language';
			if (data) { this.rightSideBar.open(); }
			else { this.rightSideBar.close(); }
		});
	}

		/** Profile Update ClientSite */
		// whatsappProfileUpdate(): void {
	
		// 	let dialogRef: any = null;
	
		// 			dialogRef = this.matDialog.open(TemplateFormComponent, {
		// 				width: "800px",
		// 				data: {
		// 					action: 'edit',
		// 					item: 'new template',
		// 				}
		// 			});
	
	
		// 	dialogRef.afterClosed()
		// 		.subscribe(response => {
		// 			if (!response) { return; }
		// 			const actionType: string = response[0];
		// 			const formData: FormGroup = response[1];
		// 			const id: number = (response[2]) ? response[2] : null;
		// 			switch (actionType) {
		// 				case 'new':
		// 					this.clientSiteService.store(formData.value);
		// 					break;
		// 				case 'update':
		// 					this.clientSiteService.update(id, formData.value);
		// 					break;
		// 				case 'delete':
		// 					// this.deleteSocialSite(clientSite);
		// 					break;
		// 			}
		// 		});
		// }
}
