import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Eorders, EordersService, EventService, SettingsService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { OrderStatusUpdateComponent } from '../order-status-update/order-status-update.component';
import { OrderViewComponent } from '../order-view/order-view.component';

@UntilDestroy()

@Component({
  selector: 'cart-order-list',
  templateUrl: './cart-order-list.component.html',
  styleUrls: ['./cart-order-list.component.scss'],
  providers: [OrderByPipe]
})
export class CartOrderListComponent implements OnInit {

  orderList: Eorders[] = [];
  statusInfoData: any;
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.orderList);
  displayedColumns = ['date', 'name', 'amount', 'product count', 'status'];


  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  eventData: any;

  constructor(public eordersService: EordersService,
    private orderByPipe: OrderByPipe, public eventService: EventService,
    private settingsService: SettingsService,
    public matDialog: MatDialog,) {
    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
    });

    this.eordersService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.eordersService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.orderList = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.orderList);
      this.dataSource.sort = this.sort;
    });
    this.eordersService.statusInfo.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['length'] > 0) {
        this.statusInfoData = data;
      }
    });
  }

  ngOnInit(): void {
    this.dataInit();
  }

  ngOnDestroy(): void {
    this.eordersService.isLoader.next(true);
    this.eordersService.resetParams();
    this.eordersService.unSubscribe();
    this.eordersService.unSubscribeFilter();
    this.eordersService.resetParams();
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.eordersService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  changeStatus(orderData) {
    this.eordersService.changeItem(orderData);
    let confirmDialogRef = this.matDialog.open(OrderStatusUpdateComponent, {
      data: { type: 'info' }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        var statusData = {
          "id": orderData._id,
          "status": result
        }
        this.eordersService.update('eorders/updatestatus', statusData);
      }
    });
  }

  statusChargesId: any;
  statusName: string;
  orderDataItem: any;

  onSelectOrderChnage(value) {
    if (value) {
      this.statusChargesId = value;
      this.statusInfoData.forEach(status => {
        if (status.id == value) {
          this.statusName = status.status;
        }
      });
    }
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'orderCreate':
          var orderData = this.eventData.data;
          if (orderData.peopleInfo && orderData.peopleInfo.id) {
            orderData['name'] = orderData.peopleInfo.name;
            orderData['ref'] = orderData.peopleInfo.unique_ref;
            orderData['created_name'] = orderData.peopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.eordersService.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.cities && orderData.cities.id && orderData.address && orderData.address.city_id) {
            orderData['cityName'] = orderData.cities.name;
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }
          if (orderData.brandInfo && orderData.brandInfo.clientSite && orderData.brandInfo.clientSite.length > 0) {
              orderData.brandInfo.clientSite.forEach(brandClient => {
                if (brandClient.client_site_id == orderData.client_site_id) {
                  orderData['address']['country'] = orderData.brandInfo.countryInfo.name_en;
                  orderData['address']['brand'] = orderData.brandInfo.name;
                  orderData['address']['brand_id'] = orderData.brandInfo._id;
                  orderData['tax'] = orderData.brandInfo.countryInfo.tax;
                }
              });
          }
          this.eordersService.concatItem(this.eventData.data);
          break;
        case 'orderUpdate':
          var orderData = this.eventData.data;
          if (orderData.peopleInfo && orderData.peopleInfo.id) {
            orderData['name'] = orderData.peopleInfo.name;
            orderData['ref'] = orderData.peopleInfo.unique_ref;
            orderData['created_name'] = orderData.peopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.eordersService.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.cities && orderData.cities.id && orderData.address && orderData.address.city_id) {
            orderData['cityName'] = orderData.cities.name;
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['address']['governorateName'] = governorate.name_en;
                  orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }
          if (orderData.brandInfo && orderData.brandInfo.clientSite && orderData.brandInfo.clientSite.length > 0) {
              orderData.brandInfo.clientSite.forEach(brandClient => {
                if (brandClient.client_site_id == orderData.client_site_id) {
                  orderData['address']['country'] = orderData.brandInfo.countryInfo.name_en;
                  orderData['address']['brand'] = orderData.brandInfo.name;
                  orderData['address']['brand_id'] = orderData.brandInfo._id;
                  orderData['tax'] = orderData.brandInfo.countryInfo.tax;
                }
              });
          }
          this.eordersService.changeAllItemsByItem(orderData);
          break;
        default:
          break;
      }
    }
  }

  getTotleQty(data: any = null): string {
    var totalQty = 0;
    if (data) {
      data.forEach(element => {
        if (element.quantity && element.quantity) {
          totalQty = totalQty + parseInt(element.quantity);
        }
      });
      return totalQty.toString();
    } else {
      return totalQty.toString();
    }
  }

  viewStatus(orderData) {
    this.eordersService.changeItem(orderData);
    var params = { client_site_id: orderData.client_site_id ? orderData.client_site_id : null }
    this.settingsService.getProfile(params);
    let confirmDialogRef = this.matDialog.open(OrderViewComponent, {
      width: "400px",
      data: { type: 'info' }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {

      }
    });
  }

}
