import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveService, Intractive,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-move-option',
  templateUrl: './move-option.component.html',
  styleUrls: ['./move-option.component.scss']
})
export class MoveOptionComponent {
dialogTitle: string = 'Move Option';
	authUser: User;
	intractive: Intractive;
	intractiveGroup: IntractiveGroup;
	action: string;
	intractiveGroupData: any;
  typeNumber: any;
  lastObjectData: any;
  selectedChoose: any;
  selectedIntractiveGroups: any;
	constructor(public userService: UserService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<MoveOptionComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => this.intractive = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.getInit(_data);
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.intractiveGroupData = data ? data.item : '';
    this.selectedChoose = data.selcted;
    const lastObject = this.intractiveGroupData.intractive_groups[this.intractiveGroupData.intractive_groups.length - 1];
    this.lastObjectData = lastObject.order;
    

		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Move to Order Change';
				break;
			case 'edit':
				this.dialogTitle = 'Move to Order Change';
				break;
			default:
				break;
		}
	}


	changeType(event) {
    this.selectedIntractiveGroups = this.intractiveGroupData.intractive_groups.filter(data => data.order == event)
	}

}