export class AreaDeliverySettingModel {
	_id: string;
	id: number;
	name_en: string;
	name_ar: string;
	country_id: number;
	fips_code: number;
	iso2: string;
	latitude: string;
	longitude: string;
	is_delivarable: boolean;
	price: string;
	isEditable: boolean;
	currency: string;
	client_site_id: string;
	team_id: string;

	/**
	 * Constructor
	 *
	 * @param clientDeliverySetting
	 */
	constructor(clientDeliverySetting) {
		this._id = clientDeliverySetting._id || '';
		this.id = clientDeliverySetting.id;
		this.name_en = clientDeliverySetting.name_en;
		this.name_ar = clientDeliverySetting.name_ar;
		this.country_id = clientDeliverySetting.country_id;
		this.fips_code = clientDeliverySetting.fips_code;
		this.iso2 = clientDeliverySetting.iso2;
		this.latitude = clientDeliverySetting.latitude;
		this.longitude = clientDeliverySetting.longitude;
		this.is_delivarable = clientDeliverySetting.is_delivarable;
		this.price = clientDeliverySetting.price;
		this.isEditable = clientDeliverySetting.isEditable;
		this.currency = clientDeliverySetting.currency;
		this.client_site_id = clientDeliverySetting.client_site_id;
		this.team_id = clientDeliverySetting.team_id;
	}
}
