import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import {
	User, UserService,
	TemplateSuccessService, TemplateSuccess, TemplateUsageService, TemplateUsage
} from 'src/app/@tji/_dbShare';
import { Location } from '@angular/common';
import { TemplateExportExcelComponent } from '../template-export-excel/template-export-excel.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ActivatedRoute } from '@angular/router';
import { ExportTemplateComponent } from '../export-template/export-template.component';

@UntilDestroy()
@Component({
	selector: 'template-usage-success',
	templateUrl: './template-success.component.html',
	styleUrls: ['./template-success.component.scss'],
	providers: [OrderByPipe]
})

export class TemplateSuccessComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	templateSuccesses: TemplateSuccess[] = [];
	templateSuccess: TemplateSuccess;
	param: any;
	routeParams: any | null;

	dataSource = new MatTableDataSource(this.templateSuccesses);
	displayedColumns = ['date', 'clientName', 'sentby', 'code', 'templateName', 'to', 'status'];

	templateUsage: TemplateUsage;
	templateUsageParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	templateReport: any;

	constructor(public userService: UserService,
		public templateSuccessService: TemplateSuccessService,
		public templateUsageService: TemplateUsageService,
		public matDialog: MatDialog,
		private route: ActivatedRoute,
		public location: Location,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.templateUsageService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
		this.templateSuccessService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.templateSuccessService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateSuccessService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateSuccess = data);
		this.templateSuccessService.loader.pipe(untilDestroyed(this)).subscribe(data => this.loader = data);
		this.templateSuccessService.templateReportItem.pipe(untilDestroyed(this)).subscribe(data => this.templateReport = data);
		this.templateSuccessService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateSuccesses) { this.subLoader = false; }
			this.templateSuccesses = data;
			this.dataSource = new MatTableDataSource(this.templateSuccesses);
			this.dataSource.sort = this.sort;
		});
		this.routeParams = this.route.snapshot.paramMap.get('templateUsageId');
	}

	ngOnInit() {
		// this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.templateSuccessService.unSubscribe();
		this.templateSuccessService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	exportExcel() {
		this.templateSuccessService.resetParams();
		if (this.routeParams) {

			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				data: {
					type: 'info',
					message: 'Are you sure want to Export ?'
				},
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result.data) {
					let dateFormat: any = {
						"limit": 500,
						"current": 1,

					}
					this.templateSuccessService.exportReport(dateFormat);
				}
			});
		} else {
			let confirmDialogRef = this.matDialog.open(ExportTemplateComponent, {
				data: {
					type: 'info',
				},
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.value) {
					var dt = new Date(result.value._d);
					let dateFormat: any = {
						"month": dt.getMonth() + 1,
						"year": dt.getFullYear(),
					}
					this.templateSuccessService.exportReport(dateFormat);
				}
			});

		}

	}

}
