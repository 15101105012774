import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { ChangeIntractiveGroupVideoComponent } from './../changeIntractiveGroupVideo/changeIntractiveGroupVideo.component';
import { ShortCodeFormComponent } from './../shortCodeForm/shortCodeForm.component';
import { LinkIntractiveMessageFormComponent } from './../linkIntractiveMessageForm/linkIntractiveMessageForm.component';
import { MapTeamFormComponent } from './../mapTeamForm/mapTeamForm.component';
import { TicketableIntractiveFormComponent } from './../ticketableIntractiveForm/ticketableIntractiveForm.component';
import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive, IntractiveModel,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
	IntractiveCommandService, ModalIntractiveGroupService, ModalIntractiveGroup, CatalogProductService,
	CategoryService
} from 'src/app/@tji/_dbShare';
import { ChangeIntractiveCatalogProductComponent } from '../changeIntractiveCatalogProduct/changeIntractiveCatalogProduct.component';

@UntilDestroy()
@Component({
	selector: 'itrsbot-product-form',
	templateUrl: './product-form.component.html',
	styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
	@Input() intractive: Intractive;
	authUser: User;
	itrsbot: Itrsbot;
	params: any;
	categoryParams: any;
	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public intractiveCommandService: IntractiveCommandService,
		public modalIntractiveGroupService: ModalIntractiveGroupService,
		public catalogProductService: CatalogProductService,
		public categoryService: CategoryService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.categoryService.params.subscribe(data => this.categoryParams = data);
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.itrsbot = data
			if (this.itrsbot && this.itrsbot.id) {
				this.params = {
					"itrsbot_id": this.itrsbot.id
				}
				this.catalogProductService.getAllIntractiveProduct(this.params);
				this.categoryService.resetParams();
				if (this.itrsbot && this.itrsbot['clientSiteInfo'] && this.itrsbot['clientSiteInfo'].id) {
					this.categoryParams['client_site_id'] = this.itrsbot['clientSiteInfo'].id;
				}
				this.categoryParams['limit'] = '500';
				this.categoryService.getAllItems();
			}
		});
	}

	ngOnInit(): void {
	}


	/** On destroy */
	ngOnDestroy(): void {
		this.intractiveService.changeItem(null);
	}


	/** Add / Update Tag */
	newIntractiveGroup(intractiveGroup = null): void {
		this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(SimpleFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
				isChoiceMsg: this.isItrsCombined(this.intractive),
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
			});
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
				}
			});
	}

	/** Delete Intractive */
	deleteIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this template?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete', formData);
				this.intractiveService.removelibraryById(intractive.id);

				setTimeout(() => {
					this.intractiveService.changeItem(null);
				}, 2000);
			}
		});
	}

	/** Add / Change IntractiveGroup Images */
	productIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveCatalogProductComponent, {
			width: "1000px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const product: number = (response[2]) ? response[2] : null;
				const productCount = (response[3]) ? response[3] : null;
				// const productCount = (response[4]) ? response[4] : null;
				switch (actionType) {
					case 'update':
						var intractiveData = {
							"id": intractiveGroup.id,
							"intractive_id": intractiveGroup.intractive_id,
							"is_active": intractiveGroup.is_active,
							"text": product + " - " + productCount,
							"order": intractiveGroup.order,
							"type": formDatas['product_type'],
							"interactive": formDatas["data"]
						}
						if (formDatas['product_type'] == 'category_list') {
							intractiveData['text'] = 'Category' + " - (total product " + productCount +")";
							intractiveData['catalogcategory_id'] = formDatas['category_id'];
						}
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', intractiveData);
						break;
				}

				// const intractiveId: number = (intractive && intractive.id) ? intractive.id : id;
				// var formData = {
				// 	"id": intractiveId,
				// 	"key": "intractive_message_id",
				// 	"value": response[1].value.intractive_message_id
				// }
				// this.intractiveService.updateData('Intractive/options', formData);

				// this.itrsbotService.getAllItems()
				// if (this.intractive) {
				// 	this.intractiveService.getIntractiveItem({ id: this.intractive.id });
				// }
				// setTimeout(() => {
				// 	this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
				// }, 2000);
				// if (!response) { return; }
			});
	}


	isItrsCombined(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'feedback' || slug === 'survey' || slug === 'one-time-survey')) ? true : false;
	}

	checkProductAssets(intractive: Intractive): boolean {
		if (intractive && intractive.intractive_groups && intractive.intractive_groups[0].json) {
			return true;
		} else {
			return false
		}
	}


	/** Add / Update Intractive */
	newShortCode(intractive): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(ShortCodeFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.intractiveCommandService.addShortCode('Intractive/addshortcode', formData.value);
				this.intractive.shortCode = formData.value['command'];
			});
	}

	/** Add / Update Intractive With Intractive Message Id */
	updateIntractiveWithIntractiveMessageId(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(LinkIntractiveMessageFormComponent, {
			width: "600px",
			data: {
				action: (intractive && intractive.intractive_message_id) ? 'edit' : 'new',
				item: intractive
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				const intractiveId: number = (intractive && intractive.id) ? intractive.id : id;
				var formData = {
					"id": intractiveId,
					"key": "intractive_message_id",
					"value": response[1].value.intractive_message_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
				// setTimeout(() => {
				// 	this.intractiveService.getIntractiveItem({ id: intractiveId });
				// }, 1500);
			});
	}

	removeIntractiveMessageId(intractive: Intractive) {
		var formData = {
			"id": intractive.id,
			"key": "intractive_message_id",
			"value": null
		}
		this.intractiveService.updateData('Intractive/options', formData);
		// this.intractiveService.update(intractive.id, { 'intractive_message_id': null });
		// setTimeout(() => {
		// 	this.intractiveService.getIntractiveItem({ id: intractive.id });
		// }, 1500);
	}

	checkGroupTeams(intractiveGroup: IntractiveGroup): boolean {
		return (this.getGroupTeams(intractiveGroup) && this.getGroupTeams(intractiveGroup).length > 0) ? true : false;
	}

	getGroupTeams(intractiveGroup: IntractiveGroup): Array<any> {
		let teamGroups = [];
		if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups
			&& this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
			teamGroups = this.intractive.intractive_groups_through_modal_intractive_groups.filter(x => {
				return (x.resource_type === 'team' && x.intractive_group_id === intractiveGroup.id) ? true : false;
			});
		}
		return teamGroups;
	}

	checkMapTeamBtn(intractive): boolean {
		let result: boolean = false;
		result = (intractive.intractive_groups.length == 1 && this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug != 'live-seperate-message') ? true : false;
		return result;
	}

	mapTeam(intractiveGroup: IntractiveGroup) {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups &&
			this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
			this.modalIntractiveGroupService.changeAllItems(this.intractive.intractive_groups_through_modal_intractive_groups);
		}

		let dialogRef = this.matDialog.open(MapTeamFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: intractiveGroup
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				this.modalIntractiveGroupService.newTems('interactiveGroup/modal-intractive-group', formData.value, intractiveGroup);
				// setTimeout(() => {
				// 	this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
				// }, 1500);
			});
	}

	removeGroupTeam(intractiveGroup: IntractiveGroup, groupTeam: ModalIntractiveGroup) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to Remove this Team?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var datas = {
					intractive_group_id: intractiveGroup.id,
					resource_id: groupTeam.resource_id
				}
				this.modalIntractiveGroupService.destroy('interactiveGroup/removeTeam', datas);
				setTimeout(() => {
					this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
				}, 2000);
			}
		});
	}

	/** Add / Update Change Intractive */
	markIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(TicketableIntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
			});
	}

	checkMarkIntractiveGroupBtn(intractive): boolean {
		let result: boolean = false;
		result = (intractive.intractive_groups.length == 1 &&
			this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug == 'products') ? true : false;
		return result;
	}

}
