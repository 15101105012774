import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ClientUsage, AccountUsageReport, TemplateUsageReport } from './clientUsage.interface';
import { ClientUsageModel } from './clientUsage_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')
import * as moment from 'moment-timezone';
@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ClientUsageService implements Resolve<any> {
	url: string = 'accountUsage/ticketVsMessage';
	routeParams: any;
	defaultParams: any = {
		'client_id': '',
		'from_date': '',
		'to_date': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<ClientUsage[]>([]);
	allItems = this.allItemsSource.asObservable();

	private accountUsagesSource = new BehaviorSubject<AccountUsageReport[]>([]);
	accountUsages = this.accountUsagesSource.asObservable();

	private accountTicketUsagesSource = new BehaviorSubject<AccountUsageReport[]>([]);
	accountTicketUsages = this.accountTicketUsagesSource.asObservable();

	private accountAgentTicketUsagesSource = new BehaviorSubject<AccountUsageReport[]>([]);
	accountAgentTicketUsages = this.accountAgentTicketUsagesSource.asObservable();

	private templateUsagesSource = new BehaviorSubject<TemplateUsageReport[]>([]);
	templateUsages = this.templateUsagesSource.asObservable();

	private itemSource = new BehaviorSubject<ClientUsage>(new ClientUsageModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<ClientUsage>(new ClientUsageModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				// this.getAllItems(),
				// this.getAllAccountsVsTickets(),
				// this.getAllAccountsVsTemplate(),
				// this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ClientUsageService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ClientUsageService');
	}

	changeAllItems(allItems: ClientUsage[]) {
		this.allItemsSource.next(allItems);
	}

	changeAccountUsages(accountUsages: AccountUsageReport[]) {
		this.accountUsagesSource.next(accountUsages);
	}

	changeTicketUsages(accountUsages: AccountUsageReport[]) {
		this.accountTicketUsagesSource.next(accountUsages);
	}

	changeAgentTicketUsages(accountUsages: AccountUsageReport[]) {
		this.accountAgentTicketUsagesSource.next(accountUsages);
	}

	changeTemplateUsages(templateUsages: TemplateUsageReport[]) {
		this.templateUsagesSource.next(templateUsages);
	}

	changeItem(item: ClientUsage) {
		this.itemSource.next(item);
		if (item && item.accountUsageReport) {
			this.changeAccountUsages(item.accountUsageReport);
		}
		if (item && item.templateUsageReport) {
			this.changeTemplateUsages(item.templateUsageReport);
		}
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: ClientUsage) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: ClientUsage) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'client_id': '',
			'from_date': '',
			'to_date': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketVsMessage = data.data;
					var dataFormat = [];
					if (ticketVsMessage && ticketVsMessage.length > 0) {
						ticketVsMessage.forEach(message => {
							var dataFormats = {
								"account_name": message.account_name,
								"site_name": message?.details?.name,
								"site_id": message.site_id,
								"client_site_id": message.id,
								"client_id": message.client_id
							}

							if (message.ticketCount && message.ticketCount.count) {
								dataFormats["totalTickets"] = message.ticketCount.count;
							} else {
								dataFormats["totalTickets"] = 0;
							}

							if (message.messageCount && message.messageCount.length > 0) {
								message.messageCount.forEach(count => {

									if (count.status == "received") {
										dataFormats["receivedMessages"] = count.countdata ? count.countdata : 0;
									}
									if (count.status == "sent") {
										dataFormats["sendMessages"] = count.countdata ? count.countdata : 0;
									}

								});

								dataFormats["totalMessages"] = parseInt(dataFormats["receivedMessages"] ? dataFormats["receivedMessages"] : "0") + parseInt(dataFormats["sendMessages"] ? dataFormats["sendMessages"] : "0");

							} else {
								dataFormats["receivedMessages"] = 0;
								dataFormats["sendMessages"] = 0;
								dataFormats["totalMessages"] = 0;
							}
							dataFormat.push(dataFormats);
						});

					}
					this.changeAccountUsages(dataFormat);
				}
			},
				error => console.log('Error ::' + error)
			);
	}


	getAllAccountsVsTickets(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("accountUsage/accountsVsTickets", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var accountsVsTickets = data.data;
					var dataFormat = [];
					if (accountsVsTickets && accountsVsTickets.length > 0) {
						accountsVsTickets.forEach(Tickets => {
							var dataFormats = {
								"account_name": Tickets.account_name,
								"site_name": Tickets?.details?.name,
								"site_id": Tickets.site_id,
								"client_site_id": Tickets.id,
								"client_id": Tickets.client_id
							}

							if (Tickets.Count && Tickets.Count.length > 0) {
								Tickets.Count.forEach(count => {

									if (count.event == "abandon") {
										dataFormats["abandonCount"] = count.countdata ? count.countdata : 0;
									}
									if (count.event == "assigned") {
										dataFormats["assignedCount"] = count.countdata ? count.countdata : 0;
									}
									if (count.event == "closed") {
										dataFormats["closedCount"] = count.countdata ? count.countdata : 0;
									}
									if (count.event == "re-assigned") {
										dataFormats["reAssignedCount"] = count.countdata ? count.countdata : 0;
									}

								});

							} else {
								dataFormats["abandonCount"] = 0;
								dataFormats["assignedCount"] = 0;
								dataFormats["reAssignedCount"] = 0;
								dataFormats["closedCount"] = 0;
							}

							if (Tickets.CountTicket && Tickets.CountTicket.length > 0) {
								Tickets.CountTicket.forEach(ticket => {

									if (ticket.event == "closed") {
										dataFormats["closedTickets"] = ticket.count ? ticket.count : 0;
									}
									if (ticket.event == "Open") {
										dataFormats["openTickets"] = ticket.count ? ticket.count : 0;
									}

								});

								dataFormats["totalTickets"] = parseInt(dataFormats["openTickets"] ? dataFormats["openTickets"] : "0") + parseInt(dataFormats["closedTickets"] ? dataFormats["closedTickets"] : "0");

							} else {
								dataFormats["closedTickets"] = 0;
								dataFormats["openTickets"] = 0;
								dataFormats["totalTickets"] = 0;
							}

							dataFormat.push(dataFormats);
						});

					}
					this.changeTicketUsages(dataFormat);
				}
			},
				error => console.log('Error ::' + error)
			);
	}


	getAllAgentVsTickets(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("accountUsage/agentVsTickets", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var accountsVsAgent = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (accountsVsAgent && accountsVsAgent.length > 0) {

						additionalData.clientSites.forEach(clientSites => {
							var clients = {
								agentReports: [],
							}
							clients["account_name"] = clientSites.account_name,
								clients["site_name"] = clientSites.details.name,
								clients["site_id"] = clientSites.site_id,
								clients["client_site_id"] = clientSites.id,
								clients["client_id"] = clientSites.client_id
							dataFormat.push(clients);
						});

						dataFormat.forEach(clientData => {
							accountsVsAgent.forEach(agent => {
								var agentData = {
									agent_id: '',
								}

								if (agent.TicketActivityCount) {

									agent.TicketActivityCount.forEach(ticketActivity => {

										if (ticketActivity.client_site_id == clientData.client_site_id) {
											if (ticketActivity.event == "abandon") {
												agentData["abandonCount"] = ticketActivity.countdata ? ticketActivity.countdata : "0"
											}
											if (ticketActivity.event == "assigned") {
												agentData["assignedCount"] = ticketActivity.countdata ? ticketActivity.countdata : "0"
											}
											if (ticketActivity.event == "closed") {
												agentData["closedTickets"] = ticketActivity.countdata ? ticketActivity.countdata : "0"
											}
											if (ticketActivity.event == "re-assigned") {
												agentData["reAssignedCount"] = ticketActivity.countdata ? ticketActivity.countdata : "0"
											}
											if (ticketActivity.event == "taken") {
												agentData["openTickets"] = ticketActivity.countdata ? ticketActivity.countdata : "0"
											}

											agentData["account_name"] = clientData.account_name,
												agentData["site_name"] = clientData.site_name,
												agentData["agent_id"] = agent.id,
												agentData["agent_name"] = agent.name
											agentData["totalTickets"] = parseInt(agentData["abandonCount"] ? agentData["abandonCount"] : "0") + parseInt(agentData["assignedCount"] ? agentData["assignedCount"] : "0") + parseInt(agentData["closedTickets"] ? agentData["closedTickets"] : "0") + parseInt(agentData["reAssignedCount"] ? agentData["reAssignedCount"] : "0") + parseInt(agentData["openTickets"] ? agentData["openTickets"] : "0");
										}

									});

									if (agentData && agentData.agent_id) {
										clientData["agentReports"].push(agentData);
									}
								}
							});

						});
						this.changeAgentTicketUsages(dataFormat);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllAccountsVsTemplate(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("accountUsage/accountsVsTemplate", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketVsMessage = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					var dataFormatNew = [];
					if (ticketVsMessage && ticketVsMessage.length > 0) {
						
						let duplicateLoop = [];
						ticketVsMessage.forEach((element, index) => {
							if (!duplicateLoop[element.template_id]) {
								duplicateLoop.push(element.template_id);
								let templateData = ticketVsMessage.filter((filterData) => filterData.template_id == element.template_id);
								let totalCount = templateData.reduce((acc, item) => parseInt(acc) + parseInt(item.totalCount), 0);
								let successCount = templateData.reduce((acc, item) => parseInt(acc) + parseInt(item.successCount), 0);
								let rejectedCount = templateData.reduce((acc, item) => parseInt(acc) + parseInt(item.rejectedCount), 0);
								let jobsCount = element.JobsCount //templateData.reduce((acc, item) => parseInt(acc) + parseInt(item.jobsCount), 0);;
								dataFormat.push({
									"total_count": totalCount,
									"success_count": successCount,
									"template_id": parseInt(element.template_id),
									"failed_count": 0,
									"total_jobs": jobsCount,
									"rejectedCount":rejectedCount
								})

							}

						});

						if (additionalData.Templates && additionalData.Templates.length > 0) {
							dataFormat.forEach(template => {
								additionalData.Templates.forEach(Templates => {
									if (template.template_id == Templates.id) {
										template["template_name"] = Templates.name
										template["client_site_id"] = Templates.client_site_id
									}
								});
							});
						}

						if (additionalData.clientSites && additionalData.clientSites.length > 0) {
							dataFormat.forEach(template => {
								additionalData.clientSites.forEach(clientSites => {
									if (template.client_site_id == clientSites.id) {
										let dataNew = {
											account_name: clientSites.account_name,
											site_name: clientSites.details.name,
											template: []
										}
										dataNew["template"].push(template)

										let data = dataFormatNew.filter((filterData) => filterData.account_name == clientSites.account_name);
										if (data && data.length > 0) {
											dataFormatNew.forEach(oldData => {
												if (oldData.account_name == clientSites.account_name) {
													oldData["template"].push(template)
												}
											});
										} else {
											dataFormatNew.push(dataNew)
										}
									}
								});
							});
						}

					}

					this.changeTemplateUsages(dataFormatNew);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					// this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ClientUsageModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				// this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(params: any, title: any = null) {
		this.commonService.storeItem("accountUsage/excel", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var AgnetsInfo = data.additional.AgnetsInfo;
					var clientSIteInfo = data.additional.clientSIteInfo;
					var peopleInfo = data.additional.peopleInfo;
					var ticketActivity = data.additional?.ticketActivity;
					var dataFormat = [];
					if (data.data && data.data.length > 0) {
						data.data.forEach(AccountVSMessage => {
							var AccountVSmsg = {
								code : AccountVSMessage.code,
								status : AccountVSMessage.is_closed?'Completed':'Open',
								openDate : moment(AccountVSMessage.created_at + '.000+0300').local().format("DD-MMM-yyy-hh:mm a"),
								closedDate : moment(AccountVSMessage.updated_at + '.000+0300').local().format("DD-MMM-yyy-hh:mm a"), 
							}
							if (AccountVSMessage.updated_at == '0000-00-00 00:00:00') {
								AccountVSmsg['closedDate'] =  moment(AccountVSMessage.created_at + '.000+0300').local().format("DD-MMM-yyy-hh:mm a");
							}

							AgnetsInfo.forEach(Agnets => {
								if (AccountVSMessage.agent_id == Agnets.id) {
									AccountVSmsg['agentName'] = Agnets.name;
								}
							});

							if (clientSIteInfo) {
								if (AccountVSMessage.client_site_id == clientSIteInfo.id) {
									AccountVSmsg['clientName'] = clientSIteInfo.account_name;
								}
							}

							peopleInfo.forEach(people => {
								if (AccountVSMessage.people_id == people.id) {
									AccountVSmsg['leadName'] = people.name;
									AccountVSmsg['unique_ref'] = people.unique_ref;
								}
							});
							ticketActivity.forEach(ticket => {
								if (AccountVSMessage.id == ticket.resource_id) {
									AccountVSmsg['duration_taken'] = ticket.duration_taken;
								}
							});
							dataFormat.push(AccountVSmsg);
						});

						this.downloadAccountVSMessageFile(dataFormat, title);
						
					}
					
				}else{
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}


	// downloadFile
	downloadAccountVSMessageFile(AccountVSMessage, title = null) {
		var excelData = [];
		if (AccountVSMessage && AccountVSMessage.length > 0) {
			AccountVSMessage.forEach(accVsMsg=> {
				if (accVsMsg) {
					var data = {
						"Code": accVsMsg.code,
						"Agent Name": accVsMsg.agentName,
						"Client Name": accVsMsg.clientName,
						"Lead Name": accVsMsg.leadName,
						"Lead unique": accVsMsg.unique_ref,
						"Status": accVsMsg.status,
						"Open Date": accVsMsg.openDate,
						"Close / Updated Date": accVsMsg.closedDate,
						"First Response Time (sec)": accVsMsg.duration_taken
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Code", "Agent Name", "Client Name", "Lead Name", "Lead unique", "Status","Open Date","Close / Updated Date","First Response Time (sec)"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", title+'_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Code", "Agent Name", "Client Name", "Lead Name", "Lead unique", "Status","Open Date","Close / Updated Date","First Response Time (sec)"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}


}