import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	PeopleService, People, PeopleModel,
	TemplateMessageService, TemplateMessage, ClientSiteService, ClientSite, FbTemplateMessageService, AlertService,
	MediaService,
	LabelService,
	Label
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FbItrsbotComponent } from 'src/app/appAccount/fbTemplate/fbItrsbot/fbitrsbot.component';
import { TemplateGalleryComponent } from 'src/app/appAccount/gallery/template-gallery/template-gallery.component';

@UntilDestroy()
@Component({
	selector: 'app-lable-template-form',
	templateUrl: './lable-template-form.component.html',
	styleUrls: ['./lable-template-form.component.scss']
})
export class LableTemplateFormComponent {
	dialogTitle: string = 'Send Template Message';
	authUser: User;
	label: Label;
	template: any;
	templates: any;
	filteredTemplates: Observable<any[]>;
	action: string;
	sendTo: string = '';
	isReserved: boolean = false;
	templateForm: UntypedFormGroup;
	data: any;
	clientSites: ClientSite[] = [];
	isCloudTemplates: boolean = false;
	templateParam: any;
	fbTemplateParam: any;
	isCarousel: boolean = false;
	clientSiteId: any;
	templateForms: FormControl;
	cardLengthData: any = [];
	loader: boolean = true;
	templateView: any = 100;
	mediaParam: any;
	leadsCount: number;
	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public templateMessageService: TemplateMessageService, public clientSiteService: ClientSiteService,
		public fbTemplateMessageService: FbTemplateMessageService,
		private alertService: AlertService,
		public mediaService: MediaService,
		public labelService: LabelService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<LableTemplateFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.templateForms = new FormControl();
		this.getInit(_data);
		this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.mediaParam = data;
		});
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.labelService.leadsCount.pipe(untilDestroyed(this)).subscribe(data => {
			this.leadsCount = data
			this.sendTo = data;
		});

		this.labelService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.label = data;
			this.createForm();
		});
		this.templateMessageService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.templates = data;
		});
		this.fbTemplateMessageService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.templates = data;
		});

		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
			this.clientSites = this.clientSites.filter(client => client.site_id == 2)
		});

		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateParam = data);
		this.fbTemplateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.fbTemplateParam = data);

		this.filteredTemplates = this.templateForms.valueChanges.pipe(
			startWith(''),
			map((Temp) =>
				Temp ? this.filterTemplate(Temp) : this.templates.slice()
			)
		);

		this.fbTemplateMessageService.getMediaIdData.pipe(untilDestroyed(this)).subscribe(data => {
			if (data['success']) {
				this.onChangeCaroselValue(data['data'].mediaId.id, data['data'].place, data['data'].identify, data['data'].idx);
			}
		});

	}

	ngOnInit() {
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.template = null;
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Send Template Message';
				break;
			case 'edit':
				this.dialogTitle = 'Send Template Message';
				break;
			default:
				break;
		}
	}

	dataInit() {
		this.templateParam.limit = 500;
		this.templateParam.current = 1;
		this.templateParam.status = "yes";
		this.fbTemplateParam.limit = 500;
		this.fbTemplateParam.current = 1;
		this.fbTemplateParam.status = "APPROVED";
	}


	changeSendTo(event) {
		this.sendTo = event;
		if (this.data && this.data.to) {
			this.data.to = event;
		}
	}

	filterTemplate(name: string) {
		let arr = this.templates.filter(
			(temp) => temp.name.toLowerCase().indexOf(name.toLowerCase()) === 0
		);

		return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
	}

	onChangeTemplate(template: TemplateMessage) {
		if (this.isCloudTemplates) {
			this.templateView = 70;
			if (template.required_values && template.required_values["data"]) {
				template.required_values["data"].forEach(template => {
					if (template.type == "header" && template.parameters && template.parameters.length > 0) {
						if (template.parameters[0].type == 'image') {
							template.parameters[0].image.link = '';
						}
						if (template.parameters[0].type == 'video') {
							template.parameters[0].video.link = '';
						}
					}

					if (template.type == 'body' && template.parameters && template.parameters.length > 0) {
						template.parameters.forEach((body, bodyIdx) => {
							body.text = '';
						});
					}

					if (template.type == "button" && template.sub_type == "url" && template.parameters && template.parameters.length > 0) {
						template.parameters.forEach((cardButton, cardButtonIdx) => {
							cardButton.text = '';
						});
					}

					if (template.type == "button" && template.sub_type == "quick_reply" && template.parameters && template.parameters.length > 0) {
						template.parameters.forEach((cardButton, cardButtonIdx) => {
							cardButton.payload = '';
						});
					}

					if (template.type == "button" && template.sub_type == "copy_code" && template.parameters && template.parameters.length > 0) {
						template.parameters.forEach((cardButton, cardButtonIdx) => {
							cardButton.coupon_code = '';
						});
					}

					if (template.type == 'CAROUSEL' && template.cards && template.cards.length > 0) {
						template.cards.forEach((cards, cardsIdx) => {
							cards.components.forEach((components, componentsIdx) => {
								if (components.type == "header" && components.parameters && components.parameters.length > 0) {
									if (components.parameters[0].type == 'image') {
										components.parameters[0].image.id = '';
									}
									if (components.parameters[0].type == 'video') {
										components.parameters[0].video.id = '';
									}
								}
								if (components.type == "body" && components.parameters && components.parameters.length > 0) {
									components.parameters.forEach((cardBody, cardBodyIdx) => {
										cardBody.text = '';
									});
								}
								if (components.type == "button" && components.sub_type == "url" && components.parameters && components.parameters.length > 0) {
									components.parameters.forEach((cardButton, cardButtonIdx) => {
										cardButton.text = '';
									});
								}
								if (components.type == "button" && components.sub_type == "quick_reply" && components.parameters && components.parameters.length > 0) {
									components.parameters.forEach((cardButton, cardButtonIdx) => {
										cardButton.payload = '';
									});
								}
							});

						});
					}
				});

				template["templateComponent"] = {};
				if (template && template["components"] && template['components'].length > 0) {
					template['components'].forEach(component => {
						if (component.type.toLowerCase() == 'header') {
							template["templateComponent"]["header"] = component;
							template["templateComponent"]["header"]["format"] = component.format.toLowerCase();
						}
						if (component.type.toLowerCase() == 'body') {
							template["templateComponent"]["body"] = component;
							template["templateComponent"]["body"]["type"] = component.type.toLowerCase();
						}
						if (component.type.toLowerCase() == 'footer') {
							template["templateComponent"]["footer"] = component;
							template["templateComponent"]["footer"]["type"] = component.type.toLowerCase();
						}
						if (component.type.toLowerCase() == 'buttons') {
							template["templateComponent"]["buttons"] = component;
							template["templateComponent"]["buttons"]["type"] = component.type.toLowerCase();
						}
						if (component.type.toLowerCase() == 'carousel') {
							template["templateComponent"]["carousel"] = component;
							template["templateComponent"]["carousel"]["type"] = component.type.toLowerCase();
							if (component.cards && component.cards.length > 0) {
								var cardId = 0;
								component.cards.forEach(cards => {
									cardId = cardId + 1;
									var data = {
										item: cardId
									}
									this.cardLengthData.push(data);
									if (cards.components && cards.components.length > 0) {
										cards.components.forEach(carousel => {
											carousel["type"] = carousel.type.toLowerCase();
											if (carousel.format) {
												carousel["format"] = carousel.format.toLowerCase();
											}
										});
									}
								});
							}
						}
					});
				}
			}


		}


		this.template = template;
		let whatsappNumber = "";
		var newData: any = {};
		if (!this.isCloudTemplates) {
			this.templateView = 100;
			newData.to = whatsappNumber;
			if (template && template.requiredAllValues && template.requiredAllValues.length > 0) {
				for (let i = 0; i == template.requiredAllValues.length; i++) {
					if (template.requiredAllValues[i] !== 'to') {
						newData[template.requiredAllValues[i]] = '';
					}
				}
			}
			this.data = newData;
		} else {
			if (this.template.required_values && this.template.required_values['data']) {
				this.data = this.template.required_values['data'];
				this.data.to = whatsappNumber;
			}
			if (this.template && this.template['components'] && this.template['components'].length > 0) {
				this.template['components'].forEach(component => {
					if (component.type.toLowerCase() == 'carousel') {
						this.isCarousel = true;
					}
				});
			}
		}

	}

	getClientSiteName(): string {
		if (this.template && this.template.id && this.template.clientSiteName) {
			return this.template.clientSiteName;
		}
		return '';
	}

	onChangeReserve(value: boolean) {
		this.isReserved = value;
	}

	onChangeValue(value, place) {
		this.data[place] = value;
	}

	onChangeValues(value, place, idx) {
		this.data.forEach((required, indexed) => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'header' && parameters.type == 'document') {
						var index = value.lastIndexOf("/") + 1;
						var filename = value.substr(index);
						parameters.document.link = value
						parameters.document.filename = filename ? filename : ''
					}
					if (required.type == 'header' && parameters.type == 'image') {
						parameters.image.link = value
					}
					if (required.type == 'header' && parameters.type == 'video') {
						parameters.video.link = value
					}
					if (required.type == 'header' && parameters.type == 'text') {
						parameters.text = value;
					}
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'payload') {
						parameters.payload = "temp_itrs_" + value;
					}
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'coupon_code') {
						parameters.coupon_code = value;
					}
					if (required.type != 'header' && index == idx && parameters.type != 'payload' && parameters.type != 'coupon_code') {
						parameters.text = value;
					}

				});
			}
		});
	}


	onChangeBodyValue(value, place, idx) {
		this.data.forEach(required => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'header' && parameters.type == 'document') {
						var index = value.lastIndexOf("/") + 1;
						var filename = value.substr(index);
						parameters.document.link = value
						parameters.document.filename = filename ? filename : ''
					}
					if (required.type == 'header' && parameters.type == 'image') {
						parameters.image.link = value
					}
					if (required.type == 'header' && parameters.type == 'video') {
						parameters.video.link = value
					}
					if (required.type != 'header' && index == idx) {
						parameters.text = value
					}
				});
			}
		});
	}

	onChangeCaroselValue(value, place, identify, idx) {
		if (this.data) {
			this.data.forEach(required => {
				if (required.type && required.type.toLowerCase() == 'carousel') {
					required.cards.forEach((cards, index) => {
						if (cards.card_index == place.card_index) {

							cards.components.forEach(components => {
								components.parameters.forEach((parameters, index) => {
									if (components.type == 'header' && parameters.type == 'document' && parameters.type == identify.type) {
										var index = value.lastIndexOf("/") + 1;
										var filename = value.substr(index);
										parameters.document.id = value
										parameters.document.filename = filename ? filename : ''
									}
									if (components.type == 'header' && parameters.type == 'image' && parameters.type == identify.type) {
										parameters.image.id = value
									}
									if (components.type == 'header' && parameters.type == 'video' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'header' && parameters.type == 'text' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'body' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
									if (components.type == 'button' && parameters.type == identify.type && index == idx && parameters.type == 'payload') {
										parameters.payload = "temp_itrs_" + value;
									}
									if (components.type == 'button' && parameters.type != 'payload' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
								});
							});

						}
					});
				}
			});
		}

	}

	getHandle(value, place, identify, idx) {
		if (value && value.length > 1) {
			var data = {
				url: value,
				place: place,
				idx: idx,
				identify: identify,
				"client_site_id": this.clientSiteId
			}
			this.fbTemplateMessageService.getMediaId('fbTemplate/getMediaId', data);
		}

	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			template: [this.template || null, [Validators.required]],
		});
	}
	onClientSiteChange(value) {
		this.clientSiteId = value;
		this.fbTemplateMessageService.changeAllItems([]);
		this.templateMessageService.changeAllItems([]);
		var clientSitesData = this.clientSites.filter(client => client.id == value)
		if ((clientSitesData[0].id && clientSitesData[0].service_provider == 'option-3') || (clientSitesData[0].id && clientSitesData[0].service_provider == 'option-7')) {
			this.fbTemplateMessageService.resetParams();
			 this.fbTemplateParam.search = '';
			this.isCloudTemplates = true;
			this.fbTemplateMessageService.getAllItems();
		} else {
			this.isCloudTemplates = false;
			this.templateMessageService.getAllItems();
		}
	}

	sendTemplate() {

		var keepHeader = true;
		var keepBody = true;
		var keepButton = true;
		var keepCardHeader = true;
		var keepCardBody = true;
		var keepCardButton = true;



		if (!keepHeader || !keepBody || !keepButton || !keepCardHeader || !keepCardButton || !keepCardBody) {
			return;
		}

		var datas = {
			"template_id": this.template?.id.toString(),
			"variables": this.data,
			"client_site_id": this.clientSiteId,
			"lable_id": this.label.id.toString(),
		}
		if (this.isCloudTemplates) {
			// var newVariable =  Object.assign({'data': this.data})
			var toNumber = this.data.to
			delete this.data.to;
			var newVariable = Object.assign({ 'to': '' }, { 'data': this.data })
			var sendingData = [];
			sendingData.push(newVariable);
			datas["variables"] = sendingData;
		} else {
			this.data.to = '';
			datas["variables"] = this.data;
		}
		// var is_reserved = (this.isReserved && (this.isReserved === true || this.isReserved === "true")) ? true : false;
		// if (is_reserved) {
		// 	datas['is_reserved'] = 1
		// }
		if (datas && datas.template_id) {
			this.labelService.sendSingleTemplate(datas);
			this.labelService.changeleadsCount(null);
			delete this.templates;
			delete this.clientSiteId;
			this.matDialogRef.close();
		}

	}

	getRequiredValues(requiredValues: Array<string> = []) {
		let output = [];
		if (requiredValues && requiredValues.length > 0) {
			output = requiredValues.filter(x => {
				return (x === 'to') ? false : true;
			});
		}
		return output;
	}

	/** Add / Update Itrsbot */
	newItrs(requireValue: any, idx: any, parameter: any = null): void {
		var itrsbotData: any;
		if (this.clientSiteId) {
			itrsbotData = {
				client_site_id: this.clientSiteId,
				requireValue: requireValue,
				idx: idx,
			}
		} else {
			this.alertService.webShow('Danger', 'Please Select Account');
		}
		this.fbTemplateMessageService.changelinkItrs(itrsbotData);
		let dialogRef = this.matDialog.open(FbItrsbotComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: itrsbotData,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				if (response[1].value && parameter != null) {
					this.onChangeCaroselItrs(response[1].value, requireValue, parameter, idx);
				} else {
					this.onChangeValues(response[1].value, requireValue, idx);
				}
			});
	}


	onChangeCaroselItrs(value, place, identify, idx) {
		this.data.forEach(required => {
			if (required.type && required.type.toLowerCase() == 'carousel') {
				required.cards.forEach((cards, index) => {
					if (cards.card_index == place.card_index) {

						cards.components.forEach((components, compIndex) => {
							if (compIndex == idx) {
								components.parameters.forEach((parameters, index) => {
									if (components.type == 'header' && parameters.type == 'document' && parameters.type == identify.type) {
										var index = value.lastIndexOf("/") + 1;
										var filename = value.substr(index);
										parameters.document.id = value
										parameters.document.filename = filename ? filename : ''
									}
									if (components.type == 'header' && parameters.type == 'image' && parameters.type == identify.type) {
										parameters.image.id = value
									}
									if (components.type == 'header' && parameters.type == 'video' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'header' && parameters.type == 'text' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'body' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
									if (components.type == 'button' && parameters.type == identify.type && parameters.type == 'payload') {
										parameters.payload = "temp_itrs_" + value;
									}
									if (components.type == 'button' && parameters.type != 'payload' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
								});
							}

						});

					}
				});
			}
		});
	}

	deleteItrs(place, idx, identify: any = null, componetIdx = null) {
		if (identify) {
			this.data.forEach(required => {
				if (required.type && required.type.toLowerCase() == 'carousel') {
					required.cards.forEach((cards, index) => {
						if (cards.card_index == place.card_index) {
							cards.components.forEach((components, compIndex) => {
								if (compIndex == componetIdx) {
									components.parameters.forEach((parameters, index) => {
										if (components.type == 'button' && parameters.type == identify.type && index == idx && components.sub_type == 'quick_reply') {
											parameters.payload = "";
										}
									});
								}
							});

						}
					});
				}
			});
		} else {
			this.data.forEach(required => {
				if (required.type && required.type == place.type && required.index == place.index) {
					required.parameters.forEach((parameters, index) => {
						if (required.type == 'button' && index == idx && parameters.type == 'payload') {
							parameters.payload = "";
						}
					});
				}
			});
		}

		this.alertService.webShow('Danger', 'Intractive Removed success');
	}



	/** addAssets From Gallery */
	addAssetsTemp(requireValue: any = null, idx: any = null, type: any = null): void {
		this.mediaService.resetParams();
		this.mediaParam.type = type;
		if (type == 'image') {
			this.mediaParam.formats = ["png","jpg","jpeg"]
		}
		if (type == 'video') {
			this.mediaParam.formats = ["mp4","jpg","jpeg"]
		}
		this.mediaService.getAllItems();
		let dialogRef = this.matDialog.open(TemplateGalleryComponent, {
			width: "600px",
			data: {
				action: 'new',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response.type;
				const formData = response.url;
				switch (actionType) {
					case 'new':
						this.onChangeValues(formData, requireValue, idx)
						break;
				}
			});
	}

	/** addAssets From Gallery */
	addAssetsCurTemp(card: any = null, parameter: any = null, idx: any = null, type: any = null): void {
		this.mediaService.resetParams();
		this.mediaParam.type = type;
		if (type == 'image') {
			this.mediaParam.formats = ["png","jpg","jpeg"]
		}
		if (type == 'video') {
			this.mediaParam.formats = ["mp4","jpg","jpeg"]
		}
		this.mediaService.getAllItems();
		let dialogRef = this.matDialog.open(TemplateGalleryComponent, {
			width: "600px",
			data: {
				action: 'new',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response.type;
				const formData = response.url;
				switch (actionType) {
					case 'new':
						this.getHandle(formData, card, parameter, idx);
						break;
				}
			});
	}


}
