import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { AssignSupervisorComponent } from './../assignSupervisor/assignSupervisor.component';
import { SelectionModel } from '@angular/cdk/collections';

import {
	User, UserService,
	AgentService, Agent, AgentModel, AlertService,
	EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	agentsAll: Agent[] = [];
	agents: Agent[] = [];
	param: any;
	agentResponse: any;
	dataSource = new MatTableDataSource(this.agents);
	displayedColumns = ['select', 'code', 'avatar', 'name', 'supervisorBy', 'contact', 'ticket', 'status', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	eventData: any;

	constructor(public userService: UserService,
		public agentService: AgentService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private eventService: EventService,
		private orderByPipe: OrderByPipe,
		private alertService: AlertService) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.agentsAll) { this.subLoader = false; }
			if (data && data.length > 0) {
				data.forEach(agent => {
					if (agent && agent.CountInfo && agent.CountInfo.length > 0) {
						var counts = 0
						agent.CountInfo.forEach(count => {
							counts = count.count + counts;
						});
						agent.CountInfo = counts;
					}
					if (agent["supervisoragentids"] && agent["supervisoragentids"].length > 0) {
						agent["supervisoragentids"].forEach(supervisor => {
							data.forEach(supervisorName => {
								if (supervisorName.id == supervisor) {
									supervisorName["supervisorAgent"] = agent.name;
								}
							});
						});
					}
				});
			}
			this.agentsAll = data;
			this.agents = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.agents);
			this.dataSource.sort = this.sort;
		});
		this.agentService.isAcitveLogout.pipe(untilDestroyed(this)).subscribe(data => {
			if (data == true) {
				this.forceLogoutAgent();
			}
		});
		this.agentService.allSearchAgents.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.length > 0) {
				this.searchAgentData(data);
			} else {
				this.searchClear();
			}
		});
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.agentService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.agentService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Agent */
	newAgent(agent = null): void {
		var clientSettings = JSON.parse(localStorage.getItem('client_settings'));
		// var agentLimit = Number(clientSettings['agent_limits'])
		if (clientSettings && clientSettings['agent_limits']) {
			
			this.agentService.changeItem((agent) ? agent : new AgentModel({}));
			let dialogRef = this.matDialog.open(FormComponent, {
				width: "600px",
				data: {
					action: (agent) ? 'edit' : 'new',
					item: agent,
				}
			});

			dialogRef.afterClosed()
				.subscribe(response => {
					if (!response) { return; }
					const actionType: string = response[0];
					const formData: UntypedFormGroup = response[1];
					// if (!formData.value.username) {
					// 	formData.value.username = formData.value.email;
					// } 
					formData.value.username = formData.value.email;
					formData.value.email.toLowerCase();
					const id: number = (response[2]) ? response[2] : null;
					switch (actionType) {
						case 'new':
							this.agentService.store('agent/add', formData.value);
							break;
						case 'update':
							formData.value.username = formData.value.name;
							formData.value['id'] = id;
							this.agentService.update('agent/update', formData.value, agent);
							break;
						case 'delete':
							this.deleteAgent(agent);
							break;
					}
				});
		} else {
			this.alertService.webShow('Danger', 'please update agent limits in client settings !!!');
		}

	}

	/** Delete Social Site */
	deleteAgent(agent: Agent): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					id: agent.id
				}
				this.agentService.destroy('agent/delete', id);
			}
		});
	}

	statusChange(id, event, agent) {
		var is_active;
		if (event == false) {
			is_active = 0;
		} else {
			is_active = 1;
		}

		this.agentService.statusUpdate('agent/status', { "id": id, 'is_active': is_active }, agent);
	}

	linkSupervisorAgent(agent) {
		this.agentService.changeItem(agent);
		let confirmDialogRef = this.matDialog.open(AssignSupervisorComponent, {
			data: { type: 'info' }
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.selectedAgentId) {
				var mark = {
					"id": agent.id,
					"supervisor_id": result.selectedAgentId,
					"agentName": result.agentName
				}
				this.agentService.linkSupervisorAgent("agent/linksupervisor", mark, agent);
				// this.agentService.getUrl("person/link-supervisor/" + result + "/" + agent.id);
			}
		});
	}

	removeSupervisorAgent(agent) {
		this.agentService.changeItem(agent);
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Unlink the Supervisor?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var mark = {
					"supervisor_id": 0,
					"id": agent.id
				}
				this.agentService.linkSupervisorAgent("agent/linksupervisor", mark, agent);
				// this.agentService.getUrl("agent/markSupervisor" + mark);
			}
		});
	}

	markAsSupervisor(agent) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to make this agent as Supervisor?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var mark = {
					"is_supervisor": 1,
					"id": agent.id
				}
				this.agentService.markAsSupervisor("agent/markSupervisor", mark, agent);
			}
		});
	}

	removeSupervisorAccess(agent) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to Remove Supervisor Access to this agent ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var mark = {
					"is_supervisor": 0,
					"id": agent.id
				}
				this.agentService.markAsSupervisor("agent/markSupervisor", mark, agent);
			}
		});
	}

	selection = new SelectionModel<any>(true, []);

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		// this.agentService.isSelectAgent = true;
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		if (numSelected > 0) {
			this.agentService.isSelectAgent = true;
		} else {
			this.agentService.isSelectAgent = false;
		}
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}
		this.agentService.isSelectAgent = true;
		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		// this.agentService.isSelectAgent = true;
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
	}
	forceLogoutAgent() {
		var selectedAgent = { userIds: [] };
		if (this.selection && this.selection['_selected'] && this.selection['_selected'].length > 0) {
			this.selection['_selected'].forEach(element => {
				if (this.authUser && this.authUser.person_id != element.id) {
					selectedAgent.userIds.push(element.userInfo.id);
				}
			});
			this.agentService.forceLogout('agent/forcelogout', selectedAgent);
			setTimeout(() => {
				this.selection.clear();
			}, 2000);
		} else {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
		}
	}

	searchAgentData(searchData) {
		var filterData = [];
		var agentDataSearch: any = [];
		var filterInteractiveKeys = ['name']
		agentDataSearch = this.agentsAll;
		if (searchData === '') {
			filterData = null;
			agentDataSearch = this.agentsAll;
			this.agents = agentDataSearch;
		} else {
			if (agentDataSearch.length > 0) {
				for (let i = 0; i < agentDataSearch.length; i++) {
					if (filterInteractiveKeys.length > 0) {
						filterInteractiveKeys.forEach((key) => {
							if (typeof agentDataSearch[i][key] === 'string' && typeof searchData === 'string') {
								if (agentDataSearch[i][key].toLowerCase().indexOf(searchData.toLowerCase()) > -1) {
									const found = filterData.some(el => el._id === agentDataSearch[i]._id);
									if (!found) {
										filterData.push(agentDataSearch[i]);
									}
								}
							}
						});
					} else {
						if (agentDataSearch[i].name.toLowerCase().indexOf(searchData.toLowerCase()) > -1) {
							filterData.push(agentDataSearch[i]);
						}
					}
				}
			}
			this.agents = filterData;
			this.dataSource = new MatTableDataSource(this.agents);
			this.dataSource.sort = this.sort;
		}
	}


	searchClear() {
		this.agents = this.agentsAll;
		this.dataSource.sort = this.sort;
		this.agentService.allSearchSource.next(null);
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'logout': case 'Logout':
					this.agentService.changeIsagent(data, "logout");
					break;
				case 'login': case 'Login':
					this.agentService.changeIsagent(data, "login");
					break;
				case 'availabilityforticket': case 'availabilityforticket':
					this.agentService.changeIsavailability(data, "availabilityforticket");
					break;
				default:
					break;
			}
		}
	}

}
