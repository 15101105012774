import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService, ClientSiteService, ClientSite, AgentService, LogService } from 'src/app/@tji/_dbShare';
import { UntypedFormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderByPipe } from 'ngx-pipes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CsvModule } from '@ctrl/ngx-csv';
export interface Button {
  title: string;
  icon: string;
  color?: string;
  tooltip?: string;
}

export interface Dropdown {
  title: string;
  icon: string;
  color?: string;
  image?: string;
  id?: number;
  description?: string;
  defaultSelection?: number;
  menus: any[];
  tooltip?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-breadcrump',
  templateUrl: './breadcrump.component.html',
  styleUrls: ['./breadcrump.component.scss'],
  providers: [OrderByPipe]
})

export class BreadcrumpComponent implements OnInit {

  @Input() icon: string = 'fa fa-home';
  @Input() svgIcon: string = 'home';
  @Input() showSvgIcon: string = 'false';
  @Input() title: string = 'Dashboard';
  @Input() subTitle: string = 'A Deep Analysis of Your App...';
  @Input() PageTitile: string = '';

  @Input() search: string = 'false';
  @Input() productdownload: string = 'false';
  @Output() onSearch = new EventEmitter();
  @Output() onSearchKeyPress = new EventEmitter();
  searchControl: UntypedFormControl;
  searchInput: string = '';

  @Input() addButton: Button = null;
  @Output() addNew = new EventEmitter();

  @Input() addSecondButton: Button = null;
  @Output() addSecondNew = new EventEmitter();

  @Input() addThirdButton: Button = null;
  @Output() addThirdNew = new EventEmitter();

  @Input() addFourthButton: Button = null;
  @Output() addFourthNew = new EventEmitter();

  @Input() addLogoutAgent: Button = null;
  @Output() logoutAgent = new EventEmitter();

  @Input() backButton: Button = null;
  @Output() back = new EventEmitter();

  @Input() dropdownButton: Dropdown = null;
  @Output() onSelect = new EventEmitter();
  selectedMenu: any = null;

  @Input() clientSitesSelection: string = 'false';
  @Input() canShowAllAccount: string = 'true';
  clientSites: ClientSite[] = [];
  @Output() onClientSiteData = new EventEmitter();
  @Output() onClientSite = new EventEmitter();
  selectedClientSite: any = null;

  @Input() intractiveButton: Dropdown = null;
  @Input() dashboardModeButton: Dropdown = null;
  @Output() onSelectIntractive = new EventEmitter();
  selectedIntractive: any = null;
  activeUrl: string = this.router.url;
  agents: any = null;
  logsData: any = null;
  constructor(public clientSiteService: ClientSiteService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private orderByPipe: OrderByPipe,
    public agentService: AgentService,
    public logService: LogService) {
    this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.clientSites = data;
      this.onSelectClientSite();
    });
    this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.agents = data;
      }
    });
    this.logService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.logsData = this.orderByPipe.transform(data, '-id');
      }
    });
    this.userService.isSearch.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == false) {
        this.searchControl = new UntypedFormControl('');
      }
    });
  }

  ngOnInit() {
    this.searchInit();
    // this.dropdownButton = {
    //   title: 'Active',
    //   menus: [
    //     {title : 'All', value: 'all'},
    //     {title : 'Active', value: 'active'},
    //     {title : 'In-Active', value: 'in-active'},
    //   ]
    // };
    this.getClientSites();
  }

  isDashboard(): boolean {
    return (window.location.href.includes('/app/dashboard') || window.location.href.includes('/app/agent-dashboard')) ? true : false;
  }

  getClientSites() {
    if (this.clientSitesSelection) {
      this.clientSiteService.resetParams();
      if (!this.userService.roleMatch(['Super Admins','Super Admin', 'Reseller'])) {
        this.clientSiteService.getAllItems();
      }

      // this.agentService.getAllItems();
    }
  }

  onSelectMenu(menu) {
    this.selectedMenu = menu;
    this.onSelect.emit(menu.value);
  }

  onSelectIntractiveMode(intractive) {
    this.selectedIntractive = intractive;
    this.onSelectIntractive.emit(intractive);
  }

  onSelectClientSite(clientSite = null) {
    if (clientSite && clientSite.id) {
      this.selectedClientSite = clientSite;
      this.onClientSite.emit(clientSite.id);
      this.onClientSiteData.emit(clientSite);
    } else {
      this.selectedClientSite = null;
      this.onClientSite.emit(null);
      this.onClientSiteData.emit(null);
    }
    if (this.canShowAllAccount === 'false' && this.clientSites.length > 0 && !this.selectedClientSite) {
      this.selectedClientSite = this.clientSites[0];
      this.onClientSiteData.emit(this.clientSites[0]);
      this.onClientSite.emit(this.clientSites[0].id);
    }
  }

  searchInit() {
    this.searchControl = new UntypedFormControl('');
    this.searchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(query => {
        this.searchInput = query;
        this.onSearch.emit(query);
      });
  }

  searchKeyPress(event) {
    this.onSearchKeyPress.emit(event);
  }

  getTooltip(item) {
    return (item && item.tooltip) ? item.tooltip : '';
  }

}
