import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
// import { TemplateFormComponent } from './../templateForm/templateForm.component';

import {
	User, UserService, EventService,
	AutoTemplateService,
	FbTemplateMessage,
	FbTemplateMessageService
} from 'src/app/@tji/_dbShare';
import { FbTemplateFormComponent } from '../templateForm/templateForm.component';
import { FbBulkImportComponent } from '../bulkImport/bulkImport.component';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
	selector: 'ticket-greeting-template',
	templateUrl: './ticket-greeting-template.component.html',
	styleUrls: ['./ticket-greeting-template.component.scss'],
	providers: [OrderByPipe]
})
export class TicketGreetingTemplateComponent {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	templateLists: any;
	param: any;
	usageParam: any;
	selectedTemplate: any = null;
	@ViewChild('templateViewRightSideBar') templateViewRightSideBar: MatSidenav;


	constructor(public userService: UserService,
		public autoTemplateService: AutoTemplateService,
		public eventService: EventService,
		private snackBar: MatSnackBar,
		private router: Router,
		private fbTemplateMessageService: FbTemplateMessageService,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.autoTemplateService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedTemplate = data);
		this.autoTemplateService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateLists) { this.subLoader = false; }
			this.templateLists = data;
		});
		// this.autoTemplateService.templateView.pipe(untilDestroyed(this)).subscribe(data => {
		// 	if (data && this.selectedTemplate && this.selectedTemplate.id) {
		// 		this.templateViewSideBar();
		// 	}
		// });
	}
	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.fbTemplateMessageService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 3000);
	}

	viewTemplate(template) {
		// this.fbTemplateMessageService.changeItem(template)
		// this.fbTemplateMessageService.templateViewsidebar.next(true);
	}

	templateViewSideBar() {
		this.templateViewRightSideBar.toggle();
	}

	/** Send Template Message to Lead */
	sendTemplateMessage(template: any): void {
		this.fbTemplateMessageService.changeItem(template.templateInfo);
		// this.consumerGroupService.changeItem(consumerGroup);
		let templateDialogRef = this.matDialog.open(FbTemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: template.templateInfo
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				// result['client_site_id'] = template.client_site_id;
				// this.fbTemplateMessageService.postUrl(result,'send-bulk-template');
			}
		});
	}

}
