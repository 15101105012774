import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ClientSettings } from './client_settings.interface';
import { ClientSettingsModel } from './client_settings_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { SiteService } from 'src/app/@tji/_dbShare/core/site/site.service';
import { ClientSiteService } from '../client_site/client_site.service';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class SettingsService implements Resolve<any> {
	url: string = 'client-site/profileData';
	urlSite: string = 'client-site/AddInit'
	routeParams: any;
	authUser: any; // = this.userService.user;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'client_site_id': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};
	// accountStatus: any;
	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();
	@Output() onChangeLoginUrl = new EventEmitter();
	setWhatsappSettings: EventEmitter<boolean> = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<ClientSettings[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<ClientSettings>(new ClientSettingsModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private socialSiteUrl = new BehaviorSubject<any>(this.defaultParams);
	socialSiteUrlRedirectUrl = this.socialSiteUrl.asObservable();

	private clientSiteProfileSource = new BehaviorSubject<any>([]);
	clientSiteProfile = this.clientSiteProfileSource.asObservable();

	public itrsLanguageDataSource = new BehaviorSubject<any>([]);
	itrsLanguageData = this.itrsLanguageDataSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		public userService: UserService,
		public clientSiteService: ClientSiteService,
		private snackBar: MatSnackBar,
		public siteService: SiteService,) {
		this._unsubscribeAll = new Subject();
		this.userService.user.subscribe(data => {
			this.authUser = data
			this.defaultParams = {
				'limit': 25,
				'current': 1,
				'client_site_id': this.authUser.person_id,
			};
		});
		this.setWhatsappSettings.emit(false);
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				// this.resetParams(),
				// this.getAllItems(),
				// this.getAllLanguage(),
				// this.getItem(),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ClientSettingsService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ClientSettingsService');
	}

	concatlibrary(allItems: ClientSettings[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	// removelibrary(item: ClientSettings) {
	//     let oldLists = [];
	//     this.libraries.subscribe(data => oldLists = data);
	//     if (oldLists && oldLists.length > 0 && item && item.id) {
	//         oldLists = oldLists.filter(x => {
	//             return x.id !== item.id;
	//         });
	//     }
	//     this.changeLibraries(oldLists);
	// }

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	// replacelibrary(item: ClientSettings) {
	//     let oldLists = [];
	//     let isReplaced: boolean = false;
	//     this.libraries.subscribe(data => oldLists = data);
	//     if (oldLists && oldLists.length > 0 && item && item.id) {
	//         for (var i = 0; i < oldLists.length; ++i) {
	//             if (oldLists[i].id === item.id) {
	//                 oldLists.splice(i, 1, item);
	//                 isReplaced = true;
	//                 break;
	//             }
	//         }
	//     }
	//     if (!isReplaced) { oldLists.push(item); }
	//     this.changeLibraries(oldLists);
	// }

	changeLibraries(libraries: ClientSettings[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: ClientSettings[]) {
		// this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: ClientSettings) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	// changeAllItemsByItem(item: ClientSettings) {
	// 	let allItems = [];
	// 	this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
	// 	if (allItems && allItems.length > 0 && item) {
	// 		for (var i = 0; i < allItems.length; ++i) {
	// 			if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
	// 		}
	// 	}
	// 	this.changeAllItems(allItems);
	// }

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params != null || params.length !== 0) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'client_site_id': this.authUser.person_id,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		if (params === null || (params && params.length === 0)) {
			params = this.defaultParams;
		}
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					data.data["success"] = true;
					this.changeAllItems(data.data);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}


	getAllLanguage(url: string = '', params: any = null) {
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					// var language = [];
					// const keysArray = Object.keys(data.data);

					// keysArray.forEach((item, index) => {
					// 	var newArr = data.data.filter(el => Object.keys(el).includes(item))
					// 	var datas = {
					// 		"language": item,
					// 		"english": data.data.$item.english,
					// 		"arabic": data.data.$item.arabic

					// 	}
					// 	language.push(datas);
					// });


					// const languageArray = [];

					// Object.entries(data.data).forEach(([key, value]) => {
					// const english = value['english'];
					// const arabic = value['arabic'];
					// var lang =  value
					// languageArray.push({ key, lang });
					// });


					this.itrsLanguageDataSource.next(data.data);
					// var dataaaa =  Object.keys(data.data).map(key => ({
					// key,
					// value: data.data[key]
					// }));

				} else {
					this.itrsLanguageDataSource.next([]);
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getProfile(params: any = null) {
		if (params === null || (params && params.length === 0)) {
			params = this.defaultParams;
		}
		params = this.paramsInit(params);
		this.commonService.storeItem("client-site/viewprofilepic", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.clientSiteProfileSource.next(data.data);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	updateProfile(params: any = null) {
		this.commonService.storeItem("client-site/profilepic", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.getProfile({ "client_site_id": params.client_site_id })
					this.alertService.webShow('Info', data.data.message);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	updateClientSite(params: any = null) {
		this.commonService.storeItem("client-site/profileData", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					console.log
					this.alertService.webShow('Info', 'Updated Successfully !!!');
					data.data["success"] = true;
					this.clientSiteService.getAllItems();
					this.changeAllItems(data.data);
				} else {
					this.alertService.webErrorShow(data);
					this.getAllItems({ "client_site_id": params.client_site_id });
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	updateEcommercePayemnt(params: any = null) {
		this.commonService.storeItem("deliverySettings/updatePaymentsMethod", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getAllItems({ "client_site_id": params.client_site_id });
			},
				error => console.log('Error ::' + error)
			);
	}

	updateEcommerceSettings(params: any = null) {
		this.commonService.storeItem("client-site/setVals", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getAllItems({ "client_site_id": params.client_site_id });
			},
				error => console.log('Error ::' + error)
			);
	}

	deliverySettings(params: any = null) {
		this.commonService.storeItem("deliverySettings/add", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getAllItems({ "client_site_id": params.client_site_id });
			},
				error => console.log('Error ::' + error)
			);
	}

	accountSettings(params: any = null) {
		this.commonService.storeItem("client-site/settings", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getAllItems({ "client_site_id": params.client_site_id });
			},
				error => console.log('Error ::' + error)
			);
	}

	updateSettings(params: any, url = null) {
		url = (url) ? url : this.url;
		this.commonService.storeItem("client-site/settings", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getAllItems({ "client_site_id": params.client_site_id });
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data);
				});
				data.data["success"] = true;
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: ClientSettings) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			// this.url, this.routeParams.id
			var id = {
				id: this.routeParams.id
			}
			this.commonService.storeItem("client-site/view", id, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ClientSettingsModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	updateLanguage(url: string, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				// this.changeItem(data.data);
				this.itrsLanguageDataSource.next(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}


	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	postUrl(data: any, url = null) {
		url = (url) ? url : this.url;
		this.commonService.storeItem(url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	post(data: any, url: string = null) {
		let postUrl = (url) ? url : this.url;
		this.commonService.storeItem(postUrl, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.data) {
					this.changeItem(data.data);
					this.alert('Success', 'Account Updated Successfully');
				}
				if (data.message) {
					// this.alert('Info', data.message);
				}
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Server Busy. Try after Sometimes !!!');
				}
			);
	}

	get(data: any, url: string = null) {
		let postUrl = (url) ? url : this.url;
		this.commonService.storeItem(postUrl, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.data) {
					this.changeItem(data.data);
					this.alert('Success', 'Account Updated Successfully');
				}
				if (data.message) {
					// this.alert('Info', data.message);
				}
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Server Busy. Try after Sometimes !!!');
				}
			);
	}

	getUrl(url, params: any = null) {
		this.commonService.getItemByParam(url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeItem(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	downloadWebchatKeyUrl(data: any) {
		let domain = this.commonService.backendUrl;
		let param = btoa(JSON.stringify(data));
		let url = domain + 'client-site-webchat-key/' + param;
		return url;
	}

	facebookClientSiteUrl(url = null, data) {
		url = (url) ? url : this.urlSite;
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.onChangeLoginUrl.emit(data);
					this.socialSiteUrl.next(data);
				} else {
					this.alertService.webErrorShow(data);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	postClientSiteUrl(data: any, url = null) {
		url = (url) ? url : this.url;
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				// console.log("successs", data)
				// this.socialSiteData.next(data);
				this.getAllItems();
				this.alert('Info', 'Updated Successfully !!!');
				// this.accountStatus = true;
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					// this.accountStatus = false;
				}
			);
	}

	deleteClientSite(data: any, url = null) {
		url = (url) ? url : this.url;
		this.commonService.deleteData(data, url, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					// console.log("successs", data)
					this.getAllItems();
					this.alert('Danger', 'Destroyed Successfully !!!');
					this.siteService.getAllItems();
				} else {
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

}
