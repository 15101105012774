import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
  User, UserService,
  ClientSiteService, ClientSite, ClientSiteModel,
  PersonSettingService, PersonSetting,
  SiteService, Site, AlertService, CommonService, TeamService, SettingsService, Team,
  ItrsbotService
} from 'src/app/@tji/_dbShare';
import { DomSanitizer } from '@angular/platform-browser';
import { WhatsappFormComponent } from '../whatsappForm/whatsappForm.component';

@UntilDestroy()
@Component({
  selector: 'itrs-language',
  templateUrl: './itrs-language.component.html',
  styleUrls: ['./itrs-language.component.scss']
})
export class ItrsLanguageComponent {
  @Output() onClose = new EventEmitter();
  itrsLanguage: any;
  loader: boolean = true;
  subLoader: boolean = false;
  clientSite: any;

  constructor(public userService: UserService,
    public clientSiteService: ClientSiteService,
    public settingsService: SettingsService,
    private alertService: AlertService,
    private commonService: CommonService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public itrsbotService: ItrsbotService,
    public matDialog: MatDialog,
    public teamService: TeamService,
    private _formBuilder: UntypedFormBuilder
  ) {

    this.settingsService.itrsLanguageData.pipe(untilDestroyed(this)).subscribe(data => {
      this.itrsLanguage = data
      //       const types = this.itrsLanguage.map(item => typeof item);
      // console.log(types);
      // this.itrsLanguage.data.forEach(element => {
      //   console.log(typeof element);
      // });
    });

    this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);


  }

  ngOnInit() {
    this.setSubLoader();
    // this.subscribePusher();
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }

  close() {
    this.onClose.emit(false);
  }
  checkType(item: any) {
    const baseType = typeof item;
    console.log(typeof item);
    return baseType;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  submit() {
    var result = {
      client_site_id: this.clientSite.id.toString(),
      orderLang: this.itrsLanguage
    }
    this.settingsService.updateLanguage('client-site/updateitrsLang', result)
  }
  changeObjectLanguage(value: string, key: string, lang, object: any): void {
    this.itrsLanguage[key][lang] = value;
  }

  changeArrayLanguage(value: string, order: any, key: string, lang, object: any): void {
    this.itrsLanguage[key][order][lang] = value;
  }

  reset() {
    var results = {
      client_site_id: this.clientSite.id.toString()
    }
    this.settingsService.updateLanguage('client-site/deleteItrsLang', results)
  }

  
}
