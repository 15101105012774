import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  User, UserService, CustomvalidationService,
  AgentService, Agent, AgentModel,
  AicollectionService,
  ChatService,
  FieldConfig,
  AlertService,
  EventService,
  AutoTemplateService,
  TemplateMessageService,
  FbTemplateMessageService
} from 'src/app/@tji/_dbShare';
import { DynamicFormComponent } from 'src/app/library/dynamic-form/dynamic-form/dynamic-form.component';
import { OrderByPipe } from 'ngx-pipes';
import { FbTemplateFormComponent } from '../../fbTemplate/templateForm/templateForm.component';
export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
  selector: 'app-generate-payment',
  templateUrl: './generate-payment.component.html',
  styleUrls: ['./generate-payment.component.scss'],
  providers: [OrderByPipe]
})
export class GeneratePaymentComponent {
  dialogTitle: string = 'Ai-Collection Generate Payment';
  authUser: User;
  paymentGenerate: any;
  action: string;
  paymentForm: UntypedFormGroup;
  param: any;
  ticket: any;
  loader: boolean = false;
  paymentData: any;
  paymentInfo: boolean = false;
  currentPayment: boolean = false;
  orderCharges: any;
  paymentSummaryData: any;
  paymentAutoSummaryData: boolean = false;
  autoTemplates: any;
  clientSiteList: any;
  isAutoTemplates: any;

  isFormLoaded: boolean = false;
  @ViewChild(DynamicFormComponent) aiCollectionForm: DynamicFormComponent;
  dynamicFormData: FieldConfig[] = [];
  eventData: any;
  selectedClientSite: any;

  constructor(private eventService: EventService, public userService: UserService,
    private customValidator: CustomvalidationService, private alertService: AlertService,
    public aicollectionService: AicollectionService, public chatService: ChatService,
    public autoTemplateService: AutoTemplateService,
    public fbTemplateMessageService: FbTemplateMessageService,
    public templateMessageService: TemplateMessageService,
    public matDialogRef: MatDialogRef<GeneratePaymentComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {

    this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
      this.ticket = data;
    });

    this.autoTemplateService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.autoTemplates = data;
      if (this.autoTemplates && this.autoTemplates.length > 0) {
        this.isAutoTemplates = true;
      }
    });

    this.autoTemplateService.clientSite.pipe(untilDestroyed(this)).subscribe(data => {
      this.clientSiteList = data;
      if (this.clientSiteList && this.clientSiteList.length > 0) {
        this.selectedClientSite = this.clientSiteList[0].id;
      }
    });

    this.paymentForm = this.createForm();
    // this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
    //   this.param = data;
    //   this.param.people_id = this.ticket.people_id
    // });
    this.aicollectionService.isAuthentication.pipe(untilDestroyed(this)).subscribe(data => {
      if (data['data'] && !data['data'].success) {
        this.loader = true;
        if (data['data'] && !data['data']["result"] && !data['data']["requiredfileds"]) {
          this.loader = false;
          this.paymentInfo = true;
          this.paymentData = data['data']
        }
        if (data['data'] && !data['data']["result"] && data['data']["requiredfileds"] && data['data']["requiredfileds"].length > 0) {
          this.paymentInfo = false;
          this.currentPayment = true;
          this.loader = false;
        }
        if (data['data'] && data['data']["result"]) {
          this.paymentInfo = false;
          this.currentPayment = true;
          this.loader = false;
        }
      }
    });

    if (this.aicollectionService.isAuthUser == true) {
      this.isFormLoaded = false;
      this.orderCharges = 0;
    }

    this.aicollectionService.isAuthentication.pipe(untilDestroyed(this)).subscribe(data => {
      if (data['data'] && !data['data'].success) {
        if (data['data'] && !data['data']["result"]) {
          this.dynamicFormData = data['data']["requiredfileds"];
          this.paymentData = data['data'];
          if (this.dynamicFormData) {
            this.dynamicFormData.forEach(fileds => {
              if (fileds.type == 'text') {
                fileds.type = 'input'
              }
              if (fileds.validations) {
                fileds.validations.forEach(validation => {
                  if (validation.validator) {
                    validation.validator = Validators.required;
                  }
                });
              }
            });
            var submit = {
              type: 'button',
              label: 'Authorization',
            }
            this.dynamicFormData.push(submit);
            this.isFormLoaded = true;
            // this.isFormListLoaded = false;
          }
        } else if (data['data'] && data['data']["result"] == true) {
          // this.isFormListLoaded = true;
          this.isFormLoaded = false;
          delete this.orderCharges;
        }
      } else {
        this.orderCharges = 0;
      }
    });

    this.getInit(_data);

    this.aicollectionService.isSendPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['_id']) {
        this.paymentSummaryData = data
        // this.aicollectionService.isSendPaymentData.next([]);
      }

    });
    this.userService.user.subscribe(data => this.authUser = data);
    this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });
  }

  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void {
    this.aicollectionService.isSendPaymentData.next([]);
  }

  getInit(data) {
    // Set the defaults
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Ai-Collection Generate Payment';
        break;
      case 'edit':
        this.dialogTitle = 'Ai-Collection Generate Payment';
        break;
      case 'isAgent':
        this.ticket = null;
        this.dialogTitle = 'Ai-Collection Generate Payment';
        break;
      default:
        break;
    }
  }

  /** Create form */
  createForm(): UntypedFormGroup {
    var user: any = JSON.parse(localStorage.getItem('tji_user'));
    var email = user.email.toLowerCase();
    return this._formBuilder.group({
      name: [this.ticket?.peopleName || '', [Validators.minLength(0), Validators.maxLength(25)]],
      email: [email ? email : '', [Validators.minLength(0), Validators.maxLength(60), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNumber: [(this.ticket?.recipientId) ? this.ticket?.recipientId : '', [Validators.minLength(0), Validators.maxLength(60), Validators.pattern("^[0-9]*$")]],
      amount: [this.orderCharges ? this.orderCharges : 0 || 0, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
      language: ['en', [Validators.minLength(0), Validators.maxLength(40)]],
      comments: [this.paymentGenerate?.comments ? this.paymentGenerate?.comments : '', [Validators.minLength(0), Validators.maxLength(100)]],
    });
  }

  submit(value: any) {
    if (value) {
      this.aicollectionService.verifyAuth(value);
    }
  }

  isSubmit: boolean = false;
  numberOnly(event: any): boolean {
    this.isSubmit = false;
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      if (this.orderCharges) {
        var len = this.orderCharges.length;
        if (this.orderCharges[0] == 0 && this.orderCharges[1] == 0) {
          this.alertService.webShow('Danger', 'this value not allowed');
          delete this.orderCharges;
        }
        var index = this.orderCharges.indexOf('.');
        if (index > 0 && charCode == 46) {
          return false;
        }
        if (index > 0) {
          var CharAfterdot = (len + 1) - index;
          if (CharAfterdot > 4) {
            return false;
          }
        }
      }
    }
    this.isSubmit = false;
    return true;
  }

  generatePayment() {
    this.aicollectionService.isPaymentLoader.next(true);
    this.paymentForm.value.phoneNumber = parseInt(this.paymentForm.value.phoneNumber);
    this.aicollectionService.generatePayment(this.paymentForm.value);
  }
  closedPayment() {
    this.matDialogRef.close();
    this.aicollectionService.isSendPaymentData.next([]);
  }

  checkEventData() {
    let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
    if (eventName) {
      let eventData = this.eventData;
      let data = this.eventData.data;
      switch (eventName) {
        case 'Login': case 'login':
          let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
          if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
            this.matDialogRef.close();
          }
          break;
        case 'logout': case 'Logout':
          let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
          if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
            this.matDialogRef.close();
          }
          break;
        default:
          break;
      }
    }
  }

  sendTemplate() {
    var template: any;
    var templateParameeter: number = 0;
    var bodyParameeter: number = 0;
    var buttonParameeter: number = 0;
    var totalButtonParameeter: number = 0;
    var totalParameeter: number = 0;
    var headerParameeter: number = 0;
    if (this.autoTemplates && this.autoTemplates.length > 1) {
      template = this.autoTemplates.filter(template => template.language == this.paymentSummaryData.set_language && template.client_site_id == this.selectedClientSite)
    } else {
      template = this.autoTemplates;
    }
    var parser = document.createElement('a');
    parser.href = this.paymentSummaryData.payment_link;

    if (template && template.length > 0) {
      var sendTemplateData = template[0].templateInfo.required_values;
      sendTemplateData.to = this.paymentSummaryData.direct_phone
      templateParameeter = sendTemplateData.data.length;
      sendTemplateData.data.forEach(temp => {
        if (temp.type == 'button' && temp.sub_type == 'url') {
          buttonParameeter = temp.parameters.length;
        }
        if (temp.type == 'button' && temp.sub_type != 'url') {
          totalButtonParameeter = temp.parameters.length;
        }
        if (temp.type == 'body') {
          bodyParameeter = temp.parameters.length;
        }
        if (temp.type == 'header') {
          headerParameeter = temp.parameters.length;
        }
      });

      if (template[0] && template[0].templateInfo && template[0].templateInfo.components.length > 0) {
        template[0].templateInfo.components.forEach(component => {
          if (component.type.toLowerCase() == 'carousel') {
            bodyParameeter = 0;
            templateParameeter = 0;
            buttonParameeter = 0;
            totalParameeter = 0;
            headerParameeter = 0;
          }
        });
      }


      totalParameeter = bodyParameeter + buttonParameeter + totalButtonParameeter + headerParameeter;
      if (templateParameeter == 2 && bodyParameeter == 1 && buttonParameeter == 1) {
        sendTemplateData.data.forEach(temp => {
          if (temp.type == 'button' && temp.sub_type == 'url') {
            temp.parameters[0].text = parser.pathname.substring(1);
          }
          if (temp.type == 'body') {
            temp.parameters[0].text = this.paymentSummaryData.amount + ' ' + this.paymentSummaryData.currency;
          }
        });

        var datas = {
          "template_id": template[0]?.templateInfo?.id.toString(),
          "variables": sendTemplateData,
          "client_site_id": this.selectedClientSite.toString(),
          "isAutomation": '3',
        }
        this.templateMessageService.sendSingleTemplate(datas);
        this.closedPayment();
      } else if (totalParameeter > 2) {
        template[0]["templateInfo"]["payment"] = {
          paymentUrl: parser.pathname.substring(1),
          amount: this.paymentSummaryData.amount + ' ' + this.paymentSummaryData.currency,
          to: this.paymentSummaryData.direct_phone?this.paymentSummaryData.direct_phone:'',
        }
        /** Send Template Message to Lead */
        this.fbTemplateMessageService.changeItem(template[0]["templateInfo"]);
        // this.consumerGroupService.changeItem(consumerGroup);
        let templateDialogRef = this.matDialog.open(FbTemplateFormComponent, {
          disableClose: false,
          width: '600',
          data: template
        });
        templateDialogRef.afterClosed().subscribe(result => {
          if (result) {
            // result['client_site_id'] = template.client_site_id;
            // this.fbTemplateMessageService.postUrl(result,'send-bulk-template');
            this.closedPayment();
          }
        });

      } else {
        this.alertService.webShow('Danger', 'Automation not set');
      }

    } else {
      this.alertService.webShow('Danger', 'Automation not set');
    }

  }

}
