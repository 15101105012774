import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, CommonService, EventService,
	InstagramTicketService, InstagramService,
	InstadmTicketService, InstadmService,
	WhatsappService, WhatsappTicketService,
	WebchatService, WebchatTicketService,
	YoutubeService, YoutubeTicketService,
	MessengerService, MessengerTicketService,
	TweetService, TweetTicketService,
	TweetdmService, TweetdmTicketService, ChatService
} from 'src/app/@tji/_dbShare';
import * as moment from 'moment-timezone';
@UntilDestroy()

@Component({
	selector: 'utils-pusher-event',
	templateUrl: './pusherEvent.component.html',
	styleUrls: ['./pusherEvent.component.scss']
})
export class PusherEventComponent implements OnInit {

	eventData: any;
	@Input() siteName: string;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public eventService: EventService,
		public commonService: CommonService,
		private tweetService: TweetService,
		private tweetTicketService: TweetTicketService,
		private tweetdmService: TweetdmService,
		private tweetdmTicketService: TweetdmTicketService,
		private messengerService: MessengerService,
		private messengerTicketService: MessengerTicketService,
		private whatsappService: WhatsappService,
		private whatsappTicketService: WhatsappTicketService,
		private webchatService: WebchatService,
		private webchatTicketService: WebchatTicketService,
		private youtubeService: YoutubeService,
		private youtubeTicketService: YoutubeTicketService,
		private instagramService: InstagramService,
		private instagramTicketService: InstagramTicketService,
		private instadmService: InstadmService,
		private instadmTicketService: InstadmTicketService) {
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() { }

	checkEventData() {
		if (this.siteName && this.eventData && this.eventData.siteName &&
			this.eventData.siteName.toLowerCase() == this.siteName.toLowerCase()) {
			let event: string = this.eventData.event;
			let data: any = this.eventData.data;
			if (data && this.eventData.siteName) {
				switch (event) {
					case 'newmessage':
						this.chatService.newMessageEvent(data, data.siteName);
						break;
					case 'aNewmessage':
						this.chatService.newMessageEvent(data, this.eventData.siteName);
						break;
					case 'delivery_failed':
						this.chatService.newDeliveryFailedEvent(data, this.eventData.siteName);
						break;
					case 'ticketReply':
						this.chatService.replyEvent(data, this.eventData.siteName);
						break;
					case 'ticketassigned':
					case 'ticketreassigned':
					case 'ticketabandon':
						this.chatService.ticketChangeAllItemsByItemEvent(data, data.siteName);
						break;
					case 'aticketassigned':
					case 'aticketreassigned':
					case 'aticketabandon':
						this.concatItemData(data, data.siteName);
						// this.chatService.ticketChangeAllItemsByItemEvent(data, data.siteName);
						break;
					case 'ticketremoved':
						this.chatService.ticketRemoveEvent(data, data.siteName);
						break;
					case 'aticketremoved':
						this.chatService.ticketRemoveEvent(data, data.siteName);
						break;
					case 'ticketclosed':
						if (this.eventData.data && this.eventData.data.agentInfo) {
							this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
							if (this.eventData.data.agentInfo.person_details) {
								this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
								this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
								this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
							}
						} 
						if (this.eventData.data.client_site_id) {
							this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
						}
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
							this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
							this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
							this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
							if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
								this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
								this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
								this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
								this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
							}
						}
						this.chatService.ticketRemoveEvent(data, data.siteName, true);
						break;
					case 'aticketclosed':
						this.chatService.ticketRemoveEvent(data, data.siteName, true);
						break;
					default:
						break;
				}
			}
		}

		if (this.eventData && this.eventData.notification &&
			this.eventData.notification.siteName.toLowerCase() == this.siteName.toLowerCase()) {
			let event: string = this.eventData.event;
			let data: any = this.eventData.data;
			if (data && this.eventData.notification.siteName) {
				switch (event) {
					case 'ticketReply':
						this.chatService.replyEvent(data, this.eventData.notification.siteName);
						break;
					default:
						break;
				}
			}
		}
	}

	concatItemData(data, siteName) {
		var ticketList = data;
		var dataFormat;
		if (ticketList && ticketList.id) {
			var dataFormats = {
				id: ticketList.id,
				ticketId: ticketList.id,
				code: ticketList.code,
				client_id: ticketList.client_id,
				agent_id: ticketList.agent_id,
				priority: ticketList.priority,
				is_active: ticketList.is_active,
				is_closed: ticketList.is_closed ? true : false,
				comment: ticketList.comment,
				people_id: ticketList.people_id,
				created_by: ticketList.created_by,
				updated_by: ticketList.updated_by,
				created_at: moment(ticketList.created_at + '.000+0300').local(),
				last_message_on: ticketList.updated_at,
				deleted_at: ticketList.deleted_at,
				is_closeable: ticketList.is_closeable,
				statusChanges: null,
			}

			if (ticketList.agentInfo) {
				dataFormats["agentName"] = ticketList.agentInfo.name;
				if (ticketList.agentInfo.person_details) {
					dataFormats["agentAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["createdByAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["agentLiveStatus"] = ticketList.agentInfo.person_details.agentLiveStatus;
				}
			}

			if (ticketList.clientSite) {
				dataFormats["clientSiteTextColor"] = ticketList.clientSite?.textcolor;
				dataFormats["clientSiteName"] = ticketList.clientSite?.account_name;
				dataFormats["clientSiteId"] = ticketList.clientSite?.id;
				if (ticketList && ticketList.clientSite && ticketList.clientSite.details) {
					dataFormats["siteName"] = ticketList.clientSite.details?.name;
					dataFormats["siteId"] = ticketList.clientSite.details?.id;
					dataFormats["siteIcon"] = ticketList.clientSite.details?.icon;
					dataFormats["siteColor"] = ticketList.clientSite.details?.color;
					dataFormats["clientSiteColor"] = ticketList.clientSite.details?.color;
				}
			}

			if (ticketList.leadInfo && ticketList.leadInfo.id) {
				dataFormats["peopleName"] = ticketList.leadInfo?.name;
				dataFormats["peopleAvatar"] = ticketList.leadInfo?.peopleAvatar;
				dataFormats["recipientId"] = ticketList.leadInfo?.unique_ref;
				if (ticketList.leadInfo && ticketList.leadInfo.labelInfo) {
					dataFormats["peopleLabelId"] = ticketList.leadInfo.labelInfo?.id;
					dataFormats["peopleLabelName"] = ticketList.leadInfo.labelInfo?.name;
					dataFormats["peopleLabelColor"] = ticketList.leadInfo.labelInfo?.color;
					dataFormats["peopleLabelColorText"] = ticketList.leadInfo.labelInfo?.color_text;
				}
			}
			var statusData = [];
			if (ticketList.ticketStatus && ticketList.ticketStatus.length > 0) {
				let lastStatusData = ticketList.ticketStatus.pop();
				dataFormats["statusName"] = lastStatusData.name;
				dataFormats["className"] = lastStatusData.model_type;
			}
		}

		dataFormat = dataFormats;

		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Messenger": case "messenger":
					dataFormat["messenger_id"] = ticketList.messenger_id,
						this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Whatsapp": case "whatsapp":
					dataFormat["whatsapp_id"] = ticketList.whatsapp_id,
						this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Webchat": case "webchat":
					this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Youtube": case "youtube":
					this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Instagram": case "instagram":
					dataFormat["instagram_id"] = ticketList.instagram_id,
						this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, siteName);
					break;
				case "Instadm": case "instadm":
					dataFormat["instagram_id"] = ticketList.instagram_id,
						this.chatService.ticketChangeAllItemsByItemEvent(dataFormat, data.siteName);
					break;
				default:
					break;
			}

		}

	}

}
