import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import * as XLSX from 'xlsx';

import {
	User, UserService,
	FbTemplateMessageService, FbTemplateMessage, FbTemplateMessageModel, FirebaseJobService, FirebaseJob,
	ClientSite,
	ClientSiteService,
	EventService
} from 'src/app/@tji/_dbShare';
import { FbItrsbotComponent } from '../fbItrsbot/fbitrsbot.component';

import * as moment from 'moment-timezone';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@UntilDestroy()
@Component({
	selector: 'fb-bulk-import',
	templateUrl: './bulkImport.component.html',
	styleUrls: ['./bulkImport.component.scss']
})

export class FbBulkImportComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Bulk Import';
	error: string = '';
	excelJsonData: any = [];
	selectedExcelData: any = [];
	convertTemplateJson = [];
	worksheetDataKey: any;
	isUploadFile: boolean = true;
	isAssingDate: boolean = false;
	selectWorkSheet: string = '';
	fileData: any;
	template: FbTemplateMessage;
	isSend: boolean = true;
	isRepeat: boolean = false;
	convertTemplateJsonLength: any;
	TemplateJsonLength: any;
	client_site_id: any;
	clientSites: ClientSite[] = [];
	templateOrgin: any;
	templateChange: any;
	eventData: any;
	authUser: User;

	constructor(public userService: UserService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public firebaseJobService: FirebaseJobService,
		private router: Router,
		private _formBuilder: FormBuilder,
		private cd: ChangeDetectorRef,
		private alertService: AlertService,
		private clientSiteService: ClientSiteService,
		private eventService: EventService,
		public matDialogRef: MatDialogRef<FbBulkImportComponent>,
		public matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		public location: Location) {
		this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.id) {
				if (data.required_values && data.required_values.data) {
					if (data.required_values && data.required_values.data) {

						data.required_values.data.forEach(template => {
							if (template.type == "header" && template.parameters && template.parameters.length > 0) {
								if (template.parameters[0].type == 'image') {
									template.parameters[0].image.link = '';
								}
								if (template.parameters[0].type == 'video') {
									template.parameters[0].video.link = '';
								}
								if (template.parameters[0].type == 'document') {
									template.parameters[0].document.link = '';
									template.parameters[0].document.filename = '';
								}
							}

							if (template.type == 'body' && template.parameters && template.parameters.length > 0) {
								template.parameters.forEach((body, bodyIdx) => {
									body.text = '';
								});
							}

							if (template.type == "button" && template.sub_type == "url" && template.parameters && template.parameters.length > 0) {
								template.parameters.forEach((cardButton, cardButtonIdx) => {
									cardButton.text = '';
								});
							}

							if (template.type == "button" && template.sub_type == "quick_reply" && template.parameters && template.parameters.length > 0) {
								template.parameters.forEach((cardButton, cardButtonIdx) => {
									cardButton.payload = '';
								});
							}

							if (template.type == "button" && template.sub_type == "copy_code" && template.parameters && template.parameters.length > 0) {
								template.parameters.forEach((cardButton, cardButtonIdx) => {
									cardButton.coupon_code = '';
								});
							}
						});

					}
				}

			}
			let templateClone = null;
			this.templateOrgin = data;
			this.templateChange = JSON.stringify(data);
			if (this.templateChange && this.templateChange.length > 1) {
				if (this.templateChange['required_values']) {
					this.templateChange['required_values'] = {};
				}

				templateClone = JSON.parse(this.templateChange); //Object.assign(data, { isCone: true }); //Object.create(data) //JSON.parse(JSON.stringify(data)) //Object.assign({}, data) // [data].map(a => {return {...a}}); // Object.assign({...data});
				var templateData = templateClone;
				templateData.required_values = { ...templateData.required_values };
				templateData.required_values['required'] = ["to"]
				if (templateData.required_values && templateData.required_values.data) {
					templateData.required_values.data.forEach(element => {

						if (element.type == "header") {
							element.parameters.forEach(param => {
								if (param.type == "document") {
									element.type = "header"
									var filteName = {
										parameters: [{
											"type": "Document Name",
											"subType": "document",
											"document": {
												"link": "",
												"filename": ""
											}
										}],
										type: "header"
									}
									templateData.required_values.data.push(filteName)
								}
							});
						}
					});
				}

				this.template = templateData;
			}
		});
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
			this.clientSites = this.clientSites.filter(client => (client.site_id == 2 && client.service_provider == "option-7") || client.site_id == 2 && client.service_provider == "option-3")
		});

		this.fbTemplateMessageService.temSend.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data[0] && data[0].jobcode) {
				this.matDialogRef.close();
				if (this.isRepeat) {
					var msg = "Job created with Job Code " + data[0].jobcode + " " + this.convertTemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msg);
				} else {
					var msgs = "Job created with Job Code " + data[0].jobcode + " " + this.TemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msgs);
				}

			}
		});

		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		// this.cd.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.fbTemplateMessageService.bulkTemSend.next([]);
		this.fbTemplateMessageService.unSubscribe();
		this.firebaseJobService.changeJobcode(null);
	}

	// doLoader() {
	// 	setTimeout(() => {
	// 		this.loader = !this.loader;
	// 	}, 5000);
	// }

	// timer() {
	// 	let data = 'bulk';
	// 	setTimeout(() => {
	// 		if (this.remainingCounts != 0) {
	// 			this.templateMessageService.getCompletedCounts({ 'queue_name': data });
	// 			this.completeCounts = this.totalCounts - this.remainingCounts;
	// 			this.rangeValue = (100 / this.totalCounts) * this.completeCounts;
	// 			// this.timer();
	// 		} else {
	// 			this.rangeValue = 100;
	// 			this.completeCounts = this.totalCounts;
	// 		}
	// 	}, 3000);
	// }

	readExcel(event) {
		this.fileData = event;
		var types = event.target.files[0].name.split(/\.(?=[^\.]+$)/);
		var typeDatas = types[1];
		if (typeDatas == 'xlsx' || typeDatas == 'xls' || typeDatas == 'csv') {
			let workBook = null;
			let jsonData = null;
			const reader = new FileReader();
			const file = event.target.files[0];
			reader.onload = (event) => {
				const data = reader.result;
				workBook = XLSX.read(data, { type: 'binary' });
				jsonData = workBook.SheetNames.reduce((initial, name) => {
					const sheet = workBook.Sheets[name];
					initial[name] = XLSX.utils.sheet_to_json(sheet);
					initial['keys'] = sheet
					var excel = {
						keys: name,
						data: XLSX.utils.sheet_to_json(sheet, { defval: "" }) //XLSX.utils.sheet_to_json(workBook.Sheets[sheet], {defval:""}) //XLSX.utils.sheet_to_json(sheet)
					}
					this.excelJsonData.push(excel);
					return initial;
				}, {});
				const dataString = jsonData //JSON.stringify(jsonData);
				if (jsonData) {
					this.isUploadFile = false;
					this.isAssingDate = true;

				}
			}
			reader.readAsBinaryString(file);
		} else {
			this.error = "upload file formate is worng. only accept xlsx, xls and csv"
		}
	}

	isWorksheetDataKey = 'false';
	selectWorkSheetData(e, work) {
		this.worksheetDataKey = null;
		this.excelJsonData.forEach(workbook => {
			if (workbook.keys == e.keys) {
				if (!workbook.data[0]) {
					this.isWorksheetDataKey = 'false';
					return;
				}
				this.selectedExcelData = workbook.data;

				var keys = Object.keys(workbook.data[0]);
				if (keys && keys.length > 0) {
					this.worksheetDataKey = [];
					keys.forEach(element => {
						var iss = {
							key: element,
							isdIsabled: false
						}
						this.worksheetDataKey.push(iss);
					});
				} else {
					this.isWorksheetDataKey = 'false';
				}

			}
		});
		if (this.worksheetDataKey && this.worksheetDataKey.length > 0) {
			this.isWorksheetDataKey = 'true';
		}
	}

	isLoder = false;
	selectData(requireValueData, selectedItem, bodyIndex: any = null) {
		this.isLoder = true;
		this.worksheetDataKey.forEach(element => {
			if (element.key == selectedItem) {
				this.worksheetDataKey.forEach(keys => {
					if (keys.requireValue == requireValueData) {
						delete keys.requireValue;
						keys['isdIsabled'] = false;
						delete element.requireValue;
						element['isdIsabled'] = false;
					}
					if (keys.requireValue && keys.requireValue.type && keys.requireValue.type == 'body' && keys.requireValue.type == requireValueData.type && requireValueData.parameters && requireValueData.parameters.length > 0 && keys.requireValue.index == bodyIndex) {
						// requireValueData.parameters.forEach((bodyPar, bodyIndexed) => {
						// if ( keys.requireValue && keys.requireValue.index && keys.requireValue.index == bodyIndex) {
						delete keys.requireValue;
						keys['isdIsabled'] = false;
						delete element.requireValue;
						element['isdIsabled'] = false;
						// }
						// });
					}
					if (keys.requireValue && keys.requireValue.type && keys.requireValue.type == 'button' && keys.requireValue.type == requireValueData.type && requireValueData.parameters && requireValueData.parameters.length > 0 && keys.requireValue.index == bodyIndex) {
						// requireValueData.parameters.forEach(bodyPar => {
						// 	if (keys.requireValue && keys.requireValue.index && keys.requireValue.index == bodyIndex) {
						delete keys.requireValue;
						keys['isdIsabled'] = false;
						delete element.requireValue;
						element['isdIsabled'] = false;
						// 	}
						// });
					}
				});

				if (requireValueData.type == 'header' && requireValueData.parameters && requireValueData.parameters.length > 0) {
					// requireValue.parameters.forEach((headerParmeter, idx) => {
					// bodyParmeter['requireValue'] = bodyIndex.toString();
					// bodyParmeter['isdIsabled'] = true;
					element['requireValue'] = requireValueData;
					element['requireValue']['type'] = 'header';
					element['isdIsabled'] = true;
					// });

				}
				else if (requireValueData.type == 'body' && requireValueData.parameters && requireValueData.type == 'body' && requireValueData.parameters.length > 0) {
					requireValueData.parameters.forEach((bodyParmeter, idx) => {
						if (idx == bodyIndex) {
							// bodyParmeter['requireValue'] = bodyIndex.toString();
							// bodyParmeter['isdIsabled'] = true;
							element['requireValue'] = bodyParmeter;
							element['requireValue']['index'] = idx;
							element['requireValue']['type'] = 'body';
							element['isdIsabled'] = true;
						}
					});

				} else if (requireValueData.type == 'button' && requireValueData.parameters && requireValueData.type == 'button' && requireValueData.parameters.length > 0) {
					requireValueData.parameters.forEach((buttonParmeter, idx) => {
						if (idx == bodyIndex) {
							// buttonParmeter['requireValue'] = bodyIndex.toString();
							// buttonParmeter['isdIsabled'] = true;
							element['requireValue'] = buttonParmeter;
							element['requireValue']['index'] = requireValueData.index;
							element['requireValue']['type'] = 'button';
							element['isdIsabled'] = true;
						}
					});

				} else {
					element['requireValue'] = requireValueData
					element['isdIsabled'] = true;
				}
			}
			if (selectedItem == '') {
				if (element.requireValue === requireValueData) {
					delete element['requireValue'];
					element['isdIsabled'] = false;
				}
				if (element.requireValue && element.requireValue.type && element.requireValue.type == 'body' && requireValueData.type == 'body' && requireValueData.parameters && requireValueData.parameters.length > 0 && element.requireValue.index == bodyIndex) {
					// requireValueData.parameters.forEach(bodyPar => {
					// 	if (element.requireValue && element.requireValue.index && element.requireValue.index == bodyPar.index) {
					delete element.requireValue;
					element['isdIsabled'] = false;
					delete element.requireValue;
					element['isdIsabled'] = false;
					// 	}
					// });
				}
				if (element.requireValue && element.requireValue.type && element.requireValue.type == 'button' && requireValueData.type == 'button' && requireValueData.parameters && requireValueData.parameters.length > 0 && element.requireValue.index == bodyIndex) {
					// requireValueData.parameters.forEach(bodyPar => {
					// 	if (element.requireValue && element.requireValue.index && element.requireValue.index == bodyPar.index) {
					delete element.requireValue;
					element['isdIsabled'] = false;
					delete element.requireValue;
					element['isdIsabled'] = false;
					// 	}
					// });
				}
			}
		});

		setTimeout(() => {
			this.isLoder = false;
		}, 200);

		this.isSend = false;
	}



	sendTemplate() {

		if (!this.client_site_id) {
			this.alertService.webShow('Success', 'Please select Account');
			return;
		}
		if (this.worksheetDataKey && this.worksheetDataKey.length > 0 && this.templateOrgin && this.templateOrgin.required_values && this.templateOrgin.required_values.data && this.templateOrgin.required_values.data.length > 0) {
			var workSheetEnabile = this.worksheetDataKey.filter(worksheet => worksheet.isdIsabled == true).length;
			var requiredValuesCount = 1;
			this.templateOrgin.required_values.data.forEach(count => {
				if (count.type == 'header' && count.parameters) {
					requiredValuesCount = requiredValuesCount + count.parameters.length;
					if (count.parameters && count.parameters[0] && count.parameters[0].type == 'document') {
						requiredValuesCount = requiredValuesCount + 1;
					}
				}
				if (count.type == 'body' && count.parameters) {
					requiredValuesCount = requiredValuesCount + count.parameters.length;
				}
				if (count.type == 'button' && count.parameters) {
					requiredValuesCount = requiredValuesCount + count.parameters.length;
				}
			});

			if (this.quickItrs && this.quickItrs.length) {
				workSheetEnabile = workSheetEnabile + this.quickItrs.length
			}

			// if (requiredValuesCount != workSheetEnabile) {
			// 	this.alertService.webShow('danger', 'Please select all fileds');
			// 	return
			// }

		}
		var convertTemplateJson = [];
		var convertTemplateJsons = [];

		this.selectedExcelData.forEach((excel) => {
			var templateDatas: any = {};
			this.worksheetDataKey.forEach(element => {
				Object.keys(excel).forEach(excelKey => {
					if (this.templateOrgin.required_values) {
						templateDatas = this.templateOrgin.required_values

						if (excelKey.toLowerCase() == element['key'].toLowerCase() && element['requireValue'] == "to") {
							templateDatas.to = excel[element['key']].toString();
						}

						if (templateDatas.data && templateDatas.data.length > 0) {
							templateDatas.data.forEach(payLoad => {
								if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == "header" && payLoad.type == 'header') {
									if (payLoad.parameters[0].type == 'document' && element['requireValue']["parameters"][0].type == payLoad.parameters[0].type) {
										payLoad.parameters[0].document.link = excel[element['key']];
									}
									if (payLoad.parameters[0].type == 'text' && element['requireValue']["parameters"][0].type == payLoad.parameters[0].type) {
										payLoad.parameters[0].text = excel[element['key']];
									}
									if (payLoad.parameters[0].type == 'image' && element['requireValue']["parameters"][0].type == payLoad.parameters[0].type) {
										payLoad.parameters[0].image.link = excel[element['key']];
									}
									if (payLoad.parameters[0].type == 'video' && element['requireValue']["parameters"][0].type == payLoad.parameters[0].type) {
										payLoad.parameters[0].video.link = excel[element['key']];
									}
								}

								if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == "header" && payLoad.type == 'header') {

									if (payLoad.parameters[0].type == 'document' && element['requireValue']["parameters"][0].subType && element['requireValue']["parameters"][0].subType == payLoad.parameters[0].type) {
										payLoad.parameters[0].document.filename = excel[element['key']];
									}
								}


								if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == "body" && payLoad.type == 'body') {
									payLoad.parameters.forEach((parameter, index) => {
										if (index == element['requireValue'].index) {
											parameter.text = excel[element['key']];
										}

									});
								}

								if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == "button" && payLoad.type == 'button') {


									// if (payLoad.sub_type == 'quick_reply') {
									// 	payLoad.parameters.forEach((quickParameter, index) => {
									// 		if (payLoad.index == element['requireValue'].index) {
									// 			quickParameter.payload = "temp_itrs_" + excel[element['key']]
									// 		}
									// 	});

									// }
									if (payLoad.sub_type == 'url') {
										payLoad.parameters.forEach((urlParameter, index) => {
											if (payLoad.index == element['requireValue'].index) {
												urlParameter.text = excel[element['key']];
											}
										});

									}
									if (payLoad.sub_type == 'copy_code') {
										payLoad.parameters.forEach((copyParameter, index) => {
											if (payLoad.index == element['requireValue'].index) {
												copyParameter.coupon_code = excel[element['key']];
											}
										});
									}
								}
							});
						}

					}

				});
			});
			var datass = JSON.stringify(templateDatas);
			convertTemplateJsons.push(JSON.parse(datass));
		});

		if (this.isRepeat) {
			convertTemplateJsons = convertTemplateJsons.filter(function (e) { return e });
			convertTemplateJsons = [...new Map(convertTemplateJsons.map((json) => [json.to, json])).values()];
			this.convertTemplateJsonLength = convertTemplateJsons.length;
		}
		this.TemplateJsonLength = convertTemplateJsons.length;
		var datas = {
			"template_id": this.template.id,
			"variables": convertTemplateJsons,
			"client_site_id": this.client_site_id
		}

		if (this.isSendLater) {
			var next = moment(this.next_shot).format('YYYY-MM-DD');
			var nextTime = new Date(next + ' ' + this.time_shot);
			var nextTimes = new Date(moment(nextTime).format('YYYY-MM-DD h:mm A'));
			var untile = moment(this.shot_until).format('YYYY-MM-DD');
			var untileTime = new Date(untile + ' ' + this.time_shot);
			var untileTimeIncrease = moment(new Date(untileTime)).add(2, 'minutes').format('YYYY-MM-DD h:mm A');
			var untileTimes = new Date(moment(untileTimeIncrease).format('YYYY-MM-DD h:mm A'));
			datas["next_shot"] = nextTimes.toISOString();
			datas["type"] = this.type;
			if (this.type != '1') {
				datas["shot_until"] = untileTimes.toISOString();
			}
		}

		if (convertTemplateJsons && convertTemplateJsons.length > 0) {
			// console.log("convertTemplateJson", datas)
			// this.isSend = true;
			this.fbTemplateMessageService.sendTemplateExcel(datas);
		} else {
			this.alertService.webShow('Success', 'Please select a correct data');
		}
	}

	quickItrs = [];

	/** Add / Update Itrsbot */
	newItrs(requireValue: any, idx: any, parameter: any = null): void {
		var itrsbotData: any;
		if (!this.client_site_id) {
			this.alertService.webShow('Danger', 'Please Select Account');
			return
		}
		if (this.client_site_id) {
			itrsbotData = {
				client_site_id: this.client_site_id,
				requireValue: requireValue,
				idx: idx,
			}
		}
		this.fbTemplateMessageService.changelinkItrs(itrsbotData);
		let dialogRef = this.matDialog.open(FbItrsbotComponent, {
			width: "600px",
			data: {
				action: "new",
				item: itrsbotData,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				this.onChangeValue(response[1].value, requireValue, idx);
				this.quickItrs.push(response[1].requireValue);
			});
	}


	deleteItrs(place, idx, identify: any = null, componetIdx = null) {

		if (this.quickItrs && this.quickItrs.length > 0) {

			this.quickItrs.forEach((itrs, index, object) => {
				if (itrs.sub_type == place.sub_type && itrs.index == place.index && itrs.parameters[0].payload == place.parameters[0].payload) {
					object.splice(index, 1);
				}
			});

			this.templateOrgin.required_values.data.forEach(required => {
				if (required.type && required.type == place.type && required.index == place.index) {
					required.parameters.forEach((parameters, index) => {
						if (required.type == 'button' && index == idx && parameters.type == 'payload') {
							parameters.payload = "";
						}
					});
				}
			});

			this.template.required_values.data.forEach(required => {
				if (required.type && required.type == place.type && required.index == place.index) {
					required.parameters.forEach((parameters, index) => {
						if (required.type == 'button' && index == idx && parameters.type == 'payload') {
							parameters.payload = "";
						}
					});
				}
			});
			this.alertService.webShow('Danger', 'Intractive Removed success');
		}

	}

	onChangeValue(value, place, idx, fileName: any = null) {
		this.templateOrgin.required_values.data.forEach((required, indexed) => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'payload') {
						parameters.payload = "temp_itrs_" + value;
					}
				});
			}
		});
		this.template.required_values.data.forEach((required, indexed) => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'payload') {
						parameters.payload = "temp_itrs_" + value;
					}
				});
			}
		});

	}



	isSendLater: any = false;
	typeData = [
		{
			id: 1,
			name: 'Once'
		},
		{
			id: 2,
			name: 'Daily'
		},
		{
			id: 3,
			name: 'Weekly'
		},
		{
			id: 4,
			name: 'Monthly'
		},
		{
			id: 5,
			name: 'Yearly'
		},
	]
	next_shot: any = null;
	shot_until: any = null;
	type: any = null;
	time_shot: any = null;
	currentDate: any = new Date();
	minTime: any = null;
	maxTime: any = null;

	onChangeSendLater($event: any = null) {
		var currentDate = moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
		this.minTime = currentDate;
		this.maxTime = '11:59 PM';
		this.isSendLater = $event.checked;
	}

	onDateChange(event: MatDatepickerInputEvent<Date>): void {
		var date = new Date(event.value);
		var selectedDate = moment(new Date(event.value)).format('YYYY-MM-DD');
		var current = moment(new Date()).format('YYYY-MM-DD'); // moment(new Date()).format('h:mm A');
		if (selectedDate != current) {
			this.minTime = '12:00 AM';
			this.maxTime = '11:59 PM';

		} else {
			var currentDate = moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
			this.minTime = currentDate;
			this.maxTime = '11:59 PM';
		}

		if (this.type == '1') {
			this.shot_until = new Date(date);
		}
		if (this.type == '2') {
			let day = date;
			this.shot_until = new Date(date.setDate(date.getDate() + 10));
		}
		if (this.type == '3') {
			let week = date;
			this.shot_until = new Date(week.setDate(week.getDate() + 70));
		}
		if (this.type == '4') {
			this.shot_until = new Date(new Date(date).setMonth(date.getMonth() + 10));
		}
		if (this.type == '5') {
			let year = date.getFullYear();
			date.setFullYear(year + 10);
			this.shot_until = date;
		}
	}

	selectType(e: any) {
		this.next_shot = null;
		this.shot_until = null;
		this.time_shot = null;
		var currentDate = moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
		this.minTime = currentDate;
		this.maxTime = '11:59 PM';
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'Login': case 'login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				case 'logout': case 'Logout':
					let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				default:
					break;
			}
		}
	}
}
