import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// import { FieldConfig } from 'src/app/@tji/_dbShare';
import { FieldConfig } from 'src/app/@tji/_dbShare/dynamicForm/field.interface';
@Component({
  selector: 'app-select',
  template: `
    <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
      <mat-select [placeholder]="field.label" [formControlName]="field.name">
        <mat-option *ngFor="let item of field.options" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: ['.mat-form-field { width: 100% !important; }'],
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
