import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  User, UserService, AicollectionService, FieldConfig, ChatService, EventService, AlertService,
  AutoTemplateService
} from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { DynamicFormComponent } from "../../../library/dynamic-form/dynamic-form/dynamic-form.component";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
import { GeneratePaymentComponent } from '../../aiCollection/generate-payment/generate-payment.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'agent-aicollection-list',
  templateUrl: './agent-aicollection-list.component.html',
  styleUrls: ['./agent-aicollection-list.component.scss']
})
export class AgentAicollectionListComponent {
  @ViewChild(DynamicFormComponent) aiCollectionForm: DynamicFormComponent;
  dynamicFormData: FieldConfig[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  isFormLoaded: boolean = false;
  isFormListLoaded: boolean = false;
  ticket: any = null;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
  orderCharges: any;
  paymentList: any;
  statusData: any;
  paymentData: any;
  eventData: any;
  isPaymentLoaded: boolean = false;
  language: string = 'en';
	aiCollectionParam: any;
  searchTerm: string;
  autoTemplateParam: any;
  paymentInfo: any;

  constructor(
    public aicollectionService: AicollectionService,
    public eventService: EventService,
    private cdRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    public chatService: ChatService,
    public autoTemplateService: AutoTemplateService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private orderByPipe: OrderByPipe,) {

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    if (this.aicollectionService.isAuthUser == true) {
      this.isFormListLoaded = true;
      this.isFormLoaded = false;
      this.orderCharges = 0;
    }

    this.aicollectionService.isPaymentLoader.pipe(untilDestroyed(this)).subscribe(data => {
      this.isPaymentLoaded = data;
      if (!this.isPaymentLoaded) {
        delete this.orderCharges;
      }
    });

    this.aicollectionService.isAuthentication.pipe(untilDestroyed(this)).subscribe(data => {
      if (data['data'] && !data['data']["result"] && !data['data']["requiredfileds"]) {
          this.loader = false;
          this.paymentInfo = true;
          this.paymentData = data['data']
        }
      if (data['data'] && !data['data'].success) {
        if (data['data'] && !data['data']["result"]) {
          this.dynamicFormData = data['data']["requiredfileds"];
          this.paymentData = data['data'];
          if (this.dynamicFormData) {
            this.dynamicFormData.forEach(fileds => {
              if (fileds.type == 'text') {
                fileds.type = 'input'
              }
              if (fileds.validations) {
                fileds.validations.forEach(validation => {
                  if (validation.validator) {
                    validation.validator = Validators.required;
                  }
                });
              }
            });
            var submit = {
              type: 'button',
              label: 'Authorization',
            }
            this.dynamicFormData.push(submit);
            this.paymentInfo = false;
            this.isFormLoaded = true;
            this.isFormListLoaded = false;
          }
        } else if (data['data'] && data['data']["result"] == true) {
            this.paymentInfo = false;
          this.isFormListLoaded = true;
          this.isFormLoaded = false;
          delete this.orderCharges;
        }
      } else {
        this.orderCharges = 0;
      }
    });

    this.aicollectionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = data; 
      // this.cdRef.detectChanges();
      // this.orderByPipe.transform(data, '-_id');
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.aicollectionService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.subLoader = data;
    });

    this.aicollectionService.isPaymentGenerate.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.orderCharges = 0;
      }
    });

    this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.aiCollectionParam = data;
			var user: any = JSON.parse(localStorage.getItem('tji_user'));
			if (user && user.id) {
				this.aiCollectionParam.user_id = user.id ? user.id : null;
			}
		});
    this.autoTemplateService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.autoTemplateParam = data;
    });
    this.aiCollectionData();
  }

  ngOnInit(): void {
    var number = /^[0-9]*\.?[0-9]*$/;
    this.isSubmit = false;
    this.doLoader();
  }

  /** On destroy */
  ngOnDestroy(): void {
    // delete this.orderCharges;
    // delete this.statusData;
    // this.aicollectionService.isStatusPaymentData.next([]);
    this.aicollectionService.isAuthentication;
    // this.aicollectionService.isAuthentication.next([]);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    delete this.orderCharges;
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 2000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  aiCollectionData() {
    this.aicollectionService.checkAuth();
    this.aicollectionService.getCurrentItems();
    this.aicollectionService.isLoader.next(true);
  }

  submit(value: any) {
    if (value) {
      this.aicollectionService.verifyAuth(value);
    }
  }

  isSubmit: boolean = false;

  numberOnly(event: any): boolean {
    this.isSubmit = false;
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      if (this.orderCharges) {
        var len = this.orderCharges.length;
        if (this.orderCharges[0] == 0 && this.orderCharges[1] == 0) {
          this.alertService.webShow('Danger', 'this value not allowed');
          delete this.orderCharges;
        }
        var index = this.orderCharges.indexOf('.');
        if (index > 0 && charCode == 46) {
          return false;
        }
        if (index > 0) {
          var CharAfterdot = (len + 1) - index;
          if (CharAfterdot > 4) {
            return false;
          }
        }
      }
    }
    this.isSubmit = false;
    return true;
  }

  onChange(event: any = null) {
    this.language = event.value;
  }

  /** create New Payment */
  createNewPayment() {
     this.autoTemplateService.resetParams();
    this.aicollectionService.checkAuth();
    this.autoTemplateParam["type"] = '1',
    this.autoTemplateParam["current"] = '1',
      this.autoTemplateService.getAllItems();
    var clientSettings = JSON.parse(localStorage.getItem('client_settings'));
    let dialogRef = this.matDialog.open(GeneratePaymentComponent, {
      width: "600px",
      data: {
        action: 'isAgent',
        item: 'payment',
        
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        switch (actionType) {
          case 'new':
            // this.aicollectionService.isPaymentLoader.next(true);
            // formData.value.phoneNumber = parseInt(formData.value.phoneNumber);
            // this.aicollectionService.generatePayment(formData.value);
            break;
        }
      });
  }
  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }
  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'paymentCreate':
          this.aicollectionService.concatItem(this.eventData.data);
          break;
        case 'paymentUpdate':
          this.aicollectionService.changeAllItemsByItems(this.eventData.data);
          break;
        default:
          break;
      }
    }
  }

}
