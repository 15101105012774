import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import * as XLSX from 'xlsx';

import {
  CatalogProductService,
  User, UserService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
  selector: 'app-bulk-upload-product',
  templateUrl: './bulk-upload-product.component.html',
  styleUrls: ['./bulk-upload-product.component.scss']
})
export class BulkUploadProductComponent {
  dialogTitle: string = 'Bulk Import';
  error: string = '';
  excelJsonData: any = [];
  selectedExcelData: any = [];
  convertTemplateJson = [];
  isUpload: boolean = true;
  fileData: any;
  params: any;
  isUploadFile: boolean = true;
  isFileData: boolean = false;
  fileName: string = '';
  productOrder: string = '1';
  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private catalogProductService: CatalogProductService,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<BulkUploadProductComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public location: Location) {
    // this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
    // 	this.template = data;
    // });
    this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
    this.catalogProductService.isUpdatedCount.pipe(untilDestroyed(this)).subscribe(data => {
      this.updatedProduct = data;
      if (this.totalProducts != 0 && this.updatedProduct != 0 && (this.totalProducts == this.updatedProduct) || (this.updatedProduct > this.totalProducts)) {
        setTimeout(() => {
          this.matDialogRef.close(null);
          this.isUpload = false;
          this.excelJsonData = [];
          delete this.fileData;
          delete this.fileName;
          this.isUploadFile = true;
          this.isFileData = false;
          this.updatedProduct = 0;
          this.totalProducts = 0;
          this.catalogProductService.isUpdatedCountSource.next(this.updatedProduct);
        }, 2000);
      }
    });
  }

  ngOnInit() {
    // this.cd.detectChanges();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.catalogProductService.unSubscribe();
  }

  readExcel(event) {
    this.fileData = event;
    this.fileName = event.target.files[0].name;
    var types = event.target.files[0].name.split(/\.(?=[^\.]+$)/);
    var typeDatas = types[1];
    if (typeDatas == 'xlsx' || typeDatas == 'xls' || typeDatas == 'csv') {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          initial['keys'] = sheet
          var excel = {
            keys: name,
            data: XLSX.utils.sheet_to_json(sheet, { defval: "" }) //XLSX.utils.sheet_to_json(workBook.Sheets[sheet], {defval:""}) //XLSX.utils.sheet_to_json(sheet)
          }
          this.excelJsonData.push(excel);
          return initial;
        }, {});
        this.isUpload = false;
        this.isUploadFile = false;
        this.isFileData = true;
      }
      reader.readAsBinaryString(file);
    } else {
      this.error = "upload file formate is worng. only accept xlsx, xls and csv"
    }
  }

  totalProducts: number = 0;
  updatedProduct: number = 0;
  isProductOrderUnique: boolean = false;
  uploadProduct() {
    this.isUpload = true;
    var keepCurrency = true;
    var keepDescription = true;
    var keepImage = true;
    var keepName = true;
    var keepPrice = true;
    var keepSalePrice = true;
    var keepId = false;

    if (this.excelJsonData && this.excelJsonData[0] && this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"].length > 0) {
      if (this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"][0] && this.excelJsonData[0]["data"][0].id) {
        keepId = true;
      }

      this.excelJsonData[0]["data"].forEach(element => {
        element["auto_generate_gtn"] = true;
        element["catalog_id"] = this.params.catalog_id.toString();
        if (element["S.No"]) {
          delete element["S.No"];
        }
        if (!element.currency && keepCurrency && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter currency Type');
          keepCurrency = false;
          return;
        }
        if (element.currency && !keepId) {
          element.currency = element.currency.toUpperCase();
        }
        if (!element.description && keepDescription && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter description');
          keepDescription = false;
          return;
        }
        if (!element.image_url && keepImage && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter image_url');
          keepImage = false;
          return;
        }
        if (!element.name && keepName && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter name');
          keepName = false;
          return;
        }
        if (!element.price && keepPrice && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter price');
          keepPrice = false;
          return;
        }
        // if (!element.sale_price && keepSalePrice && !keepId) {
        //   this.alertService.webShow('Danger', 'Please Enter price');
        //   keepSalePrice = false;
        //   return;
        // }
        if (element.availability && !keepId) {
          element.availability = element.availability.toString();
        }
        if (element.id) {
          element.id = element.id.toString();
        }
        if (element.price) {
          var priceData = element.price.toString().split('.');
          if (priceData[0] && priceData[0].length < 1) {
            priceData[0] = 0;
          }
          if (priceData[1] && priceData[1].length == 1) {
            priceData = priceData[0] + '.' + priceData[1] + '00'
          } else if (priceData[1] && priceData[1].length == 2) {
            priceData = priceData[0] + '.' + priceData[1] + '0'
          } else if (priceData[1] && priceData[1].length == 3) {
            var decimelsData = priceData[1].split('');
            if (decimelsData[2] != 0) {
              decimelsData[2] = 0
            }
            priceData = priceData[0] + '.' + decimelsData[0] + decimelsData[1] + decimelsData[2];
          } else {
            priceData = priceData[0] + '.' + '000'
          }
          element.price = priceData;
          // element.price.toFixed(3);
        }

        if (element.sale_price) {
          var priceData = element.sale_price.toString().split('.');
          if (priceData[0] && priceData[0].length < 1) {
            priceData[0] = 0;
          }
          if (priceData[1] && priceData[1].length == 1) {
            priceData = priceData[0] + '.' + priceData[1] + '00'
          } else if (priceData[1] && priceData[1].length == 2) {
            priceData = priceData[0] + '.' + priceData[1] + '0'
          } else if (priceData[1] && priceData[1].length == 3) {
            var decimelsData = priceData[1].split('');
            if (decimelsData[2] != 0) {
              decimelsData[2] = 0
            }
            priceData = priceData[0] + '.' + decimelsData[0] + decimelsData[1] + decimelsData[2];
          } else {
            priceData = priceData[0] + '.' + '000'
          }
          element.sale_price = priceData;
          // element.price.toFixed(3);
        }else{
          element.sale_price = 0;
        }
      });
      var result = {
        data: this.excelJsonData[0]["data"]
      }
      if (this.productOrder == '2') {
        this.isProductOrderUnique = true;
        if (this.excelJsonData && this.excelJsonData[0] && this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"].length > 0) {
          this.excelJsonData[0]["data"].reverse()
          this.totalProducts = this.excelJsonData[0]["data"].length;
          
          this.loopThroughPayloads(0);

          // this.excelJsonData[0]["data"].forEach((payload, index) => {
          //   setTimeout(() => {
          //     var updatedProduct = index + 1;
          //     var results = {
          //       data: this.excelJsonData[0]["data"]
          //     }
          //     this.catalogProductService.productUnique('fbProduct/add', payload, updatedProduct, this.totalProducts);
          //   }, index * 500);
          // });

          // this.catalogProductService.productUnique('fbProduct/manage', this.excelJsonData[0]["data"]);

        }
        return;
      }
      if (keepCurrency && keepDescription && keepImage && keepImage && keepName && keepPrice && keepSalePrice && !keepId) {
        this.matDialogRef.close(result);
        this.isUpload = false;
        this.excelJsonData = [];
        delete this.fileData;
        delete this.fileName;
        this.isUploadFile = true;
        this.isFileData = false;
        this.isProductOrderUnique = false;
        this.updatedProduct = 0;
        this.totalProducts = 0;
      } else if (keepId) {
        this.matDialogRef.close(result);
        this.isUpload = false;
        this.excelJsonData = [];
        delete this.fileData;
        delete this.fileName;
        this.isUploadFile = true;
        this.isFileData = false;
        this.isProductOrderUnique = false;
        this.updatedProduct = 0;
        this.totalProducts = 0;
      } else {
        this.deleteFile();
      }


    } else {
      this.alertService.webShow("danger", 'no records found in uploaded excel sheet');
    }
  }

  countUpdate: number = 0;
  loopThroughPayloads(index: number) {
    const payloads = this.getPayloads(); // Get your payloads array

    if (index < payloads.length) {
      this.catalogProductService.singleProduct('fbProduct/add', payloads[index]).toPromise()
        .then(response => {
          this.countUpdate = this.countUpdate + 1;
          if (this.totalProducts == this.countUpdate) {
            this.catalogProductService.getAllItems();
          }
          this.catalogProductService.isUpdatedCountSource.next(this.countUpdate);
          this.loopThroughPayloads(index + 1); // Call the next payload
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  // const tjiToken = localStorage.getItem("tji_token") ? localStorage.getItem("tji_token") : "";

  //   console.time('.map()')
  //   Promise.all(
  //     this.excelJsonData[0]["data"].map(data => {
  //       return new Promise<void>((resolve) => {
  //         fetch(`https://napp.ai-octopus.com/api/fbProduct/add`, {
  //           method: 'POST',
  //           body: data,
  //           headers: {
  //             'Content-type': 'application/json; charset=UTF-8',
  //             "Authorization": "Bearer " + tjiToken
  //           }
  //         })
  //           .then(response => {
  //             return new Promise(() => {
  //               response.json()
  //                 .then(todo => {
  //                   console.log(todo._id)
  //                   resolve()
  //                 })
  //             })
  //           })
  //       })
  //     })
  //   )
  //     .then(() => {
  //       console.timeEnd('.map()');
  //     })

  }

  getPayloads() {
    // Return an array of payloads
    if (this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"].length > 0) {
      return this.excelJsonData[0]["data"];
    }
  }

  deleteFile() {
    this.isUpload = true;
    this.excelJsonData = [];
    delete this.fileData;
    delete this.fileName;
    this.isUploadFile = true;
    this.isFileData = false;
    this.isProductOrderUnique = false;
  }

  sampleFile() {
    window.open('/assets/sample-files/sample-facebook-product.csv', '_blank');
  }

  radioChange(event: any = null) {
    this.productOrder = event.value;
  }

}
