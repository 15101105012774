import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// import { FieldConfig } from 'src/app/@tji/_dbShare';
import { FieldConfig } from 'src/app/@tji/_dbShare/dynamicForm/field.interface';

@Component({
  selector: 'app-radiobutton',
  template: `
    <div class="fieldContainer">
        <div class="demo-full-width margin-top" [formGroup]="group">
          <label class="radio-label-padding">{{ field.label }}:</label>
          <mat-radio-group [formControlName]="field.name">
            <mat-radio-button
              *ngFor="let item of field.options"
              [value]="item"
              >{{ item }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
    </div>
  `,
  styles: [
  '.mat-form-field { width: 100% !important; } .radio-label-padding{margin-right: 5px;} :host ::ng-deep .mat-radio-label-content{padding-left: 4px; padding-right: 10px;} :host ::ng-deep .mat-radio-container{height: 14px; width: 14px;} :host ::ng-deep .mat-radio-outer-circle{ height: 14px; width: 14px;} :host ::ng-deep .mat-radio-inner-circle{ height: 14px; width: 14px; } :host ::ng-deep .mat-radio-button .mat-radio-ripple{ height: 20px; width: 20px; left: calc(50% - 10px); top: calc(50% - 10px); }'
  ],
})
export class RadiobuttonComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
