import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { OpenConversationRouteModule } from './openConversation-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';

import { AssignFormComponent } from './assignForm/assignForm.component';
import { ConversationHistoryComponent } from './conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './conversationInstagramHistory/conversationInstagramHistory.component';
import { ConversationYoutubeHistoryComponent } from './conversationYoutubeHistory/conversationYoutubeHistory.component';

import { FeedService, ClientSiteService } from 'src/app/@tji/_dbShare';
import { MatDialogRef } from '@angular/material/dialog';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        AssignFormComponent,
        ConversationHistoryComponent,
        ConversationInstagramHistoryComponent,
        ConversationYoutubeHistoryComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        OpenConversationRouteModule,
        UtilsModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
    ],
    providers: [
        FeedService,
        ClientSiteService,
        {
            provide: MatDialogRef,
            useValue: {}
        },
    ]
})

export class OpenConversationModule { }
