import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
// import { TemplateFormComponent } from './../templateForm/templateForm.component';

import {
  User, UserService,
  FbTemplateMessageService, FbTemplateMessage, TemplateMessageModel,
  TemplateUsageService, TemplateUsage, EventService,
  SendLaterTemplateService
} from 'src/app/@tji/_dbShare';
import { RescheduleComponent } from '../reschedule/reschedule.component';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-template-send-later-list',
  templateUrl: './template-send-later-list.component.html',
  styleUrls: ['./template-send-later-list.component.scss'],
  providers: [OrderByPipe]
})
export class TemplateSendLaterListComponent {
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  templateLists: any[] = [];
  param: any;
  usageParam: any;
  dataSource = new MatTableDataSource(this.templateLists);
  displayedColumns = ['name', 'nextShot', 'tillSchedule', 'category', 'account', 'numberCount', 'total_pro', 'type', 'action'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  eventData: any;

  constructor(public userService: UserService,
    public fbTemplateMessageService: FbTemplateMessageService,
    public sendLaterTemplateService: SendLaterTemplateService,
    public templateUsageService: TemplateUsageService,
    public eventService: EventService,
    private snackBar: MatSnackBar,
    private router: Router,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.templateUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.usageParam = data);
    this.sendLaterTemplateService.allITicketLater.pipe(untilDestroyed(this)).subscribe(data => {
      if (data !== this.templateLists) { this.subLoader = false; }
      this.templateLists = data;
      this.templateLists = this.orderByPipe.transform(data, '-id');
      this.dataSource = new MatTableDataSource(this.templateLists);
      this.dataSource.sort = this.sort;
    });
    this.dataSource = new MatTableDataSource(this.templateLists);
    this.dataSource.sort = this.sort;
    this.sendLaterTemplateService.templateStatusUpdate.next(false);
    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      // this.checkEventData();
    });
    this.sendLaterTemplateService.templateStatus.pipe(untilDestroyed(this)).subscribe(data => {
      this.subLoader = data;
    });
  }

  ngOnInit() {
    this.doLoader();
    this.dataInit();
  }

  /** On destroy */
  ngOnDestroy(): void {
    // this.fbTemplateMessageService.unSubscribe();
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.sendLaterTemplateService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    this.sendLaterTemplateService.totalLaterItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  /** Delete Social Site */
  deleteTemplate(template: FbTemplateMessage): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var temp = {
          id: template.id
        }
        this.sendLaterTemplateService.destroySchedule("template/deleteschedule", temp);
      }
    });
  }


  // checkEventData() {
  // 	if (this.eventData) {
  // 		let event: string = this.eventData.event;
  // 		switch (event) {
  // 			case 'templatecreate':
  // 				this.templateUpdate(this.eventData.data, "templatecreate")
  // 				break;
  // 			case 'templatedelete':
  // 				this.fbTemplateMessageService.spliceItem(this.eventData.data._id);
  // 				break;
  // 			case 'templateupdate':
  // 				this.templateUpdate(this.eventData.data, "templateupdate")
  // 				break;
  // 			case 'templatevent':
  // 				this.templateUpdate(this.eventData.data, "templatevent")
  // 				break;
  // 			default:
  // 				break;
  // 		}
  // 	}
  // }

  templateUpdate(templateData: any, eventData: any) {

    if (templateData.clientSiteInfo) {
      if (templateData.clientSiteInfo.id) {
        templateData["siteName"] = templateData.clientSiteInfo?.details?.name;
        templateData["clientSiteName"] = templateData.clientSiteInfo?.account_name;
        templateData["clientSiteColor"] = templateData.clientSiteInfo?.color;
        templateData["clientSiteTextColor"] = templateData.clientSiteInfo?.textcolor;
        if (templateData.clientSiteInfo.mongo && templateData.clientSiteInfo.mongo.wabaFullInfo) {
          templateData["waba_name"] = templateData.clientSiteInfo.mongo.wabaFullInfo.name ? templateData.clientSiteInfo.mongo.wabaFullInfo.name : '--'
        }
        if (templateData.clientSiteInfo.mongo && templateData.clientSiteInfo.mongo.business_info) {
          templateData["business_name"] = templateData.clientSiteInfo.mongo.business_info.name ? templateData.clientSiteInfo.mongo.business_info.name : '--'
        }
      }
    }
    if (templateData.components && templateData.components.length > 0) {
      templateData.components.forEach(component => {
        if (component.type.toLowerCase() == 'carousel') {
          templateData['isCarousel'] = true;
        }
      });
    }

    if (eventData == 'templatecreate') {
      this.sendLaterTemplateService.concatItem(templateData);
    }
    if (eventData == 'templateupdate') {
      this.sendLaterTemplateService.changeAllItemsByItem(this.eventData.data);
    }
    if (eventData == 'templatevent') {
      this.sendLaterTemplateService.changeAllItemsByItem(this.eventData.data);
    }

  }


  viewHistory(template) {
    // this.usageParam.template_id = template.id;
    // this.templateUsageService.getAllItems();
    this.fbTemplateMessageService.changeItem(template);
    this.router.navigate(['/app/template/history/' + template.template_id]);
  }


  statusChange(temp: any) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'alert',
        message: 'You are about to cancel the campaign.?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var update = {
          "is_completed": 'yes',
          "id": temp.id
        }
        this.sendLaterTemplateService.storeSendLaterItems('template/managetempschedule', update);
      }
    });
  }

  /** Add / Update Team */
  updateTempSchedule(temp = null): void {
    let dialogRef = this.matDialog.open(RescheduleComponent, {
      width: "600px",
      data: {
        action: (temp) ? 'edit' : 'new',
        item: temp,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        switch (actionType) {
          case 'update':
            var next = moment(formData["next_shot"]).format('YYYY-MM-DD');
            var nextTime = new Date(next + ' ' + formData['time_shot']);
            var nextTimes = new Date(moment(nextTime).format('YYYY-MM-DD h:mm A'));
            if (!isNaN(formData['shot_until'])) {
              var untile = moment(formData['shot_until']).format('YYYY-MM-DD');
              var untileTime = new Date(untile + ' ' + formData['time_shot']);
              var untileTimeIncrease = moment(new Date(untileTime)).add(2, 'minutes').format('YYYY-MM-DD h:mm A');
              var untileTimes = new Date(moment(untileTimeIncrease).format('YYYY-MM-DD h:mm A'));
            }

            var update = {
              id: temp.id,
              next_shot: nextTimes.toISOString(),
              type: formData['type'],

            }
            if (untileTimes) {
              update["shot_until"] = untileTimes.toISOString();
            }

            this.sendLaterTemplateService.storeSendLaterItems('template/managetempschedule', update);
            break;
        }
      });
  }

}
