import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import {
  UserService, ClientToggleService, Client,
  MenuGroupService, MenuGroup,
  NotifyService, UnreadCount, CountService, WhatsappTicketService, TweetTicketService, TweetdmTicketService, MessengerTicketService, YoutubeTicketService, InstadmTicketService, WebchatTicketService
} from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  @ViewChild('leftSideBar', { static: true }) leftSideBar: MatSidenav;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  isExpanded = true;
  isShowing = false;
  showSubmenu: boolean = false;

  selectedClient: Client = null;

  isAdmin: any;
  menuGroups: MenuGroup[] = [];
  menuGroupParam: any;
  selectedMenu: any = null;
  selectedSubMenu: any = null;
  logoImg = './assets/images/tji/logo_black_bg.png';

  unreadCounts: UnreadCount[] = [];

  activeUrl: string = this.router.url;
  userInfo: any = null;
  supperAdmin = [
    {
      "id": 1,
      "title": "Octopus",
      "slug": "octopus",
      "type": "collapse",
      "translate": "TJI.Octopus",
      "icon": null,
      "image": null,
      "url": "/dashboard",
      "route": "/super/dashboard",
      "order": 1,
      "permission": null,
      "json": null,
      "is_hidden": 0,
      "is_open_new_tab": 0,
      "is_active": 1,
      "created_by": 1,
      "updated_by": 1,
      "created_at": "2020-08-06 08:55:07",
      "updated_at": "2020-08-06 08:55:07",
      "deleted_at": null,
      "role": null,
      "isPermitted": true,
      "permissions": [
        "view dashboard"
      ],
      "roles": [
        "Super Admins"
      ],
      "menus": [
        {
          "id": 32,
          "menu_group_id": 1,
          "title": "Admin Desk",
          "slug": "admin-desk",
          "type": "item",
          "translate": "TJI.AdminDesk",
          "icon": "fa-tachomerter",
          "image": "speed",
          "url": "super-dashboard",
          "route": "/super/dashboard",
          "order": 4,
          "permission": "view dashboard",
          "json": null,
          "is_hidden": 0,
          "is_open_new_tab": 0,
          "is_active": 1,
          "created_by": 1,
          "updated_by": 1,
          "created_at": "2020-08-06 08:55:14",
          "updated_at": "2020-08-06 08:55:14",
          "deleted_at": null,
          "role": null,
          "isPermitted": true,
          "permissions": [
            "view dashboard"
          ],
          "roles": [
            "Super Admins"
          ],
          "sub_menus": []
        }
      ]
    },
    {
      "id": 10,
      "title": "Template",
      "slug": "template",
      "type": "collapse",
      "translate": "TJI.Template",
      "icon": null,
      "image": null,
      "url": null,
      "route": null,
      "order": 3,
      "permission": null,
      "json": null,
      "is_hidden": 0,
      "is_open_new_tab": 0,
      "is_active": 1,
      "created_by": 1,
      "updated_by": 1,
      "created_at": "2022-11-06 16:16:26",
      "updated_at": "2022-11-06 16:16:29",
      "deleted_at": null,
      "role": null,
      "isPermitted": true,
      "permissions": [
        "view template usage",
        "view template",
        "manage template"
      ],
      "roles": [],
      "menus": [
        {
          "id": 37,
          "menu_group_id": 10,
          "title": "Template Usages",
          "slug": "template-usages",
          "type": "collapse",
          "translate": "TJI.TemplateUsage",
          "icon": "fa-files-o",
          "image": "summarize",
          "url": "template-usage",
          "route": "/super/template-usage",
          "order": 6,
          "permission": "view template usage",
          "json": null,
          "is_hidden": 0,
          "is_open_new_tab": 0,
          "is_active": 1,
          "created_by": 1,
          "updated_by": 1,
          "created_at": "2022-11-06 16:18:55",
          "updated_at": "2022-11-06 16:18:58",
          "deleted_at": null,
          "role": null,
          "isPermitted": true,
          "permissions": [
            "view template usage"
          ],
          "sub_menus": [
            {
              "id": 34,
              "menu_id": 37,
              "title": "Queue",
              "slug": "template-queue",
              "type": "item",
              "translate": "TJI.TemplateQueue",
              "icon": "fa-wrench",
              "image": "double_arrow",
              "url": "template-queue",
              "route": "/super/template-usage/queue",
              "order": 1,
              "permission": "view template,manage template",
              "json": null,
              "is_hidden": 0,
              "is_open_new_tab": 0,
              "is_active": 1,
              "created_by": 1,
              "updated_by": 1,
              "created_at": "2021-07-24 22:37:09",
              "updated_at": "2021-07-24 22:37:09",
              "deleted_at": null,
              "role": "Super Admins",
              "isPermitted": true,
              "permissions": [
                "view template",
                "manage template"
              ],
              "roles": [
                "Super Admins"
              ]
            },
            {
              "id": 51,
              "menu_id": 37,
              "title": "Template Usage",
              "slug": "template-usage",
              "type": "item",
              "translate": "TJI.TemplateUsage",
              "icon": "fa-wrench",
              "image": "double_arrow",
              "url": "template-usage",
              "route": "/super/template-usage",
              "order": 1,
              "permission": "view template usage",
              "json": null,
              "is_hidden": 0,
              "is_open_new_tab": 0,
              "is_active": 1,
              "created_by": 1,
              "updated_by": 1,
              "created_at": "2022-11-06 16:21:01",
              "updated_at": "2022-11-06 16:21:04",
              "deleted_at": null,
              "role": null,
              "isPermitted": true,
              "permissions": [
                "view template usage"
              ]
            }
          ]
        },
        {
          "id": 38,
          "menu_group_id": 10,
          "title": "Template",
          "slug": "template",
          "type": "item",
          "translate": "TJI.Template",
          "icon": "fa-file-o",
          "image": "summarize",
          "url": "template",
          "route": "/super/template",
          "order": 7,
          "permission": "view template, manage template",
          "json": null,
          "is_hidden": 0,
          "is_open_new_tab": 0,
          "is_active": 1,
          "created_by": 1,
          "updated_by": 1,
          "created_at": "2022-11-06 16:28:12",
          "updated_at": "2022-11-06 16:28:14",
          "deleted_at": null,
          "role": null,
          "isPermitted": true,
          "permissions": [
            "view template",
            "manage template"
          ],
          "sub_menus": []
        }
      ]
    },
    {
      "id": 7,
      "title": "Log",
      "slug": "log",
      "type": "collapse",
      "translate": "TJI.Log",
      "icon": null,
      "image": null,
      "url": null,
      "route": null,
      "order": 10,
      "permission": null,
      "json": null,
      "is_hidden": 0,
      "is_open_new_tab": 0,
      "is_active": 1,
      "created_by": 1,
      "updated_by": 2,
      "created_at": "2020-08-06 08:55:07",
      "updated_at": "2022-09-26 13:32:44",
      "deleted_at": null,
      "role": null,
      "isPermitted": true,
      "permissions": [
        "view logs",
        "delete logs"
      ],
      "roles": [],
      "menus": [
        {
          "id": 13,
          "menu_group_id": 7,
          "title": "Log",
          "slug": "log",
          "type": "item",
          "translate": "TJI.Log",
          "icon": "fa-cog",
          "image": "psychology",
          "url": "activity",
          "route": "/super/activity",
          "order": 1,
          "permission": "view logs, delete logs",
          "json": null,
          "is_hidden": 0,
          "is_open_new_tab": 0,
          "is_active": 1,
          "created_by": 1,
          "updated_by": 1,
          "created_at": "2020-08-06 08:55:14",
          "updated_at": "2020-08-06 08:55:14",
          "deleted_at": null,
          "role": null,
          "isPermitted": true,
          "permissions": [
            "view logs",
            "delete logs"
          ],
          "sub_menus": []
        }
      ]
    }
  ]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private _formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public countService: CountService,
    public clientToggleService: ClientToggleService,
    public menuGroupService: MenuGroupService,
    public notifyService: NotifyService,
    private router: Router,
    public tweetTicketService: TweetTicketService,
    public tweetdmTicketService: TweetdmTicketService,
    public messengerTicketService: MessengerTicketService,
    public whatsappTicketService: WhatsappTicketService,
    public youtubeTicketService: YoutubeTicketService,
    public instadmTicketService: InstadmTicketService,
    public webchatTicketService: WebchatTicketService,) {
    this.userService.authUser();
    this.clientToggleService.item.subscribe(data => this.selectedClient = data);
    this.menuGroupService.allItems.subscribe(data => this.menuGroups = data);
    this.menuGroupService.params.subscribe(data => this.menuGroupParam = data);
    this.notifyService.unreadCounts.subscribe(data => this.unreadCounts = data);
    this.isAdmin = this.userService.isAuthUserIsAdmin();
  }

  ngOnInit() {
    // this.getMenuGroups();
    if (!this.userService.roleMatch(['Super Admins'])) {
      this.menuGroupService.getAllItems();
    }
    this.userInfo = JSON.parse(localStorage.getItem('tji_user'));
  }

  isDashboard(): boolean {
    return (window.location.href.includes('/app/dashboard') || window.location.href.includes('/app/agent-dashboard')) ? true : false;
  }

  getMenuGroups() {
    this.menuGroupParam.all = 1;
    this.menuGroupParam.with = 'menus,menus.subMenus';
    this.menuGroupService.getAllItems();
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  toggleShortMenu() {
    this.isExpanded = !this.isExpanded;
  }

  toggleLeftSideBar() {
    this.leftSideBar.toggle();
  }

  getUnreadCount(menu): number {
    let output: number = 0;
    if (menu && menu.json) {
      let input: any = JSON.parse(menu.json);
      let siteName = (input && input.badgeSite) ? input.badgeSite : null;
      let slug = (input && input.badgeName) ? input.badgeName : null;
      if (siteName && slug) {
        output = this.countService.getItemCount(slug, siteName);
      }
    }
    return output;

    // if (menu && menu.json && this.unreadCounts.length > 0) {
    //   let input: any = JSON.parse(menu.json);
    //   let siteName = (input && input.badgeSite) ? input.badgeSite : null;
    //   let selectedUnreads = [];
    //   let output: number = 0;
    //   if (siteName) {
    //     selectedUnreads = this.unreadCounts.filter(x => x.name === input.badgeName).filter(y => y.site === siteName)
    //   } else {
    //     selectedUnreads = this.unreadCounts.filter(x => x.name === input.badgeName)
    //   }
    //   if (selectedUnreads && selectedUnreads.length > 0) {
    //     if (input && input.isBadgeTotal) {
    //       output = selectedUnreads.reduce((a, item) => a + item.count, 0);
    //     } else {
    //       output = selectedUnreads[0].count;
    //     }
    //   }
    //   return output;
    // }
    return 0;
  }

  isShowBadge(menu): boolean {
    if (menu && menu.json && this.unreadCounts.length > 0) {
      let input: any = JSON.parse(menu.json);
      return (input && input.isShowBadge) ? true : false;
    }
    return false;
  }

  isShowMenu(res): boolean {
    if (res) {
      let input: any = JSON.parse(res);
      return input.isReadAdmin
    }
    return true;
  }

  getClientName() {
    if (this.selectedClient && this.selectedClient.id) {
      return this.selectedClient.name;
    } else {
      return "-- Click to Select --";
    }
  }

  checkRoles(item): boolean {
    if (item.role) {
      let roles: Array<any> = item.roles;
      if (roles.length > 0) {
        return this.userService.personTypeMatch(roles);
      }
    }
    return true;
  }

  // checkMenuGroupPermissions(menuGroup): boolean {
  //   return true;
  //   let allPermissions = [];
  //   allPermissions = allPermissions.concat(menuGroup.permissions);
  //   if (menuGroup && menuGroup.menus && menuGroup.menus.length > 0) {
  //     for (var i = 0; i < menuGroup.menus.length; ++i) {
  //       let newMenu = menuGroup.menus[i];
  //       allPermissions = allPermissions.concat(newMenu.permissions);
  //       if (newMenu && newMenu.subMenus && newMenu.subMenus.length > 0) {
  //         for (var i = 0; i < newMenu.subMenus.length; ++i) {
  //           let newSubMenu = newMenu.subMenus[i];
  //           allPermissions = allPermissions.concat(newSubMenu.permissions);
  //         }
  //       }
  //     }
  //   }
  //   return (allPermissions && allPermissions.length > 0) ? this.userService.permissionMatch(allPermissions) : false;
  // }

  // checkMenuPermissions(menu): boolean {
  //   return false;
  //   let allPermissions = [];
  //   allPermissions = allPermissions.concat(menu.permissions);
  //   if (menu && menu.subMenus && menu.subMenus.length > 0) {
  //     for (var i = 0; i < menu.subMenus.length; ++i) {
  //       let newSubMenu = menu.subMenus[i];
  //       allPermissions = allPermissions.concat(newSubMenu.permissions);
  //     }
  //   }
  //   return (allPermissions && allPermissions.length > 0) ? this.userService.permissionMatch(allPermissions) : false;
  // }
  resetParams() {
    this.tweetTicketService.isParams = true;
    this.tweetdmTicketService.isParams = true;
    this.messengerTicketService.isParams = true;
    this.whatsappTicketService.isParams = true;
    this.youtubeTicketService.isParams = true;
    this.instadmTicketService.isParams = true;
    this.webchatTicketService.isParams = true;
    this.tweetTicketService.resetParams();
    this.tweetdmTicketService.resetParams();
    this.messengerTicketService.resetParams();
    this.whatsappTicketService.resetParams();
    this.youtubeTicketService.resetParams();
    this.instadmTicketService.resetParams();
    this.webchatTicketService.resetParams();
  }
}
