import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, filter, retry } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../form/form.component';
import { EditColorFormComponent } from 'src/app/appAccount/utils/editColorForm/editColorForm.component';
import { MessengerFormComponent } from '../messengerForm/messengerForm.component';
import { WhatsappFormComponent } from '../whatsappForm/whatsappForm.component';
import { WhatsappProfileUpdateFormComponent } from '../whatsapp-profile-update-form/whatsapp-profile-update-form.component';
import { TwitterFormComponent } from '../twitterForm/twitterForm.component';
import { YoutubeFormComponent } from '../youtubeForm/youtubeForm.component';
import { InstagramFormComponent } from '../instagramForm/instagramForm.component';
import { InstadmFormComponent } from '../instadmForm/instadmForm.component';
import { WebchatFormComponent } from '../webchatForm/webchatForm.component';
import { LogViewComponent } from '../logView/logView.component';

import {
	User, UserService, CommonService,
	ClientSiteService, ClientSite, ClientSiteModel,
	SiteService, Site, SiteModel, PusherService, AlertService, TeamService, EventService, SettingsService,
	ToastData,
	ToastModel,
	ToastService,
	ClientDeliverySettingService
} from 'src/app/@tji/_dbShare';
import { exit } from 'process';
import { ProductSettingsComponent } from '../../productSettings/product-settings/product-settings.component';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
	selector: 'social-site-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	isAdmin: any;
	clientSitesAll: ClientSite[] = [];
	clientSites: ClientSite[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.clientSites);

	displayedColumns = ['socialStatus', 'media', 'color', 'account', 'clientName', 'createdat', 'updatedat', 'login', 'status', 'action'];
	now = +new Date();
	oneday = 60 * 60 * 24 * 1000;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	twitterRedirectUrl: string = null;
	channel: any;
	profileImageForm: UntypedFormGroup;
	loginUrl: any;
	clientSiteDetails: any;

	constructor(public userService: UserService,
		public commonService: CommonService,
		public clientSiteService: ClientSiteService,
		public pusherService: PusherService,
		public settingsService: SettingsService,
		public siteService: SiteService,
		public teamService: TeamService,
		private router: Router,
		public matDialog: MatDialog,
		private alertService: AlertService,
		private eventService: EventService,
		private _formBuilder: UntypedFormBuilder,
		private cd: ChangeDetectorRef,
		private toastService: ToastService,
		private toastr: ToastrService,
		private clientDeliverySettingService: ClientDeliverySettingService,
		private orderByPipe: OrderByPipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.twitterRedirectUrl.pipe(untilDestroyed(this)).subscribe(data => this.twitterRedirectUrl = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.clientSitesAll) { this.subLoader = false; }
			this.clientSitesAll = data;
			this.clientSites = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.clientSites);
			this.dataSource.sort = this.sort;
		});
		this.isAdmin = this.userService.isAuthUserIsAdmin();
		this.clientSiteService.socialSiteUrlRedirectUrl.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.data) {
				this.loginUrl = data.data ? data.data.RedirectUrl : '';
			}
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.clientSiteService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.clientSiteService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	subscribePusher() {
		this.channel = this.pusherService.subscribeChannel('authuser');
		this.channel.bind('accountsessionexpired', (data) => {
			this.clientSiteService.getAllItems();
		});
	}

	/** Add / Update ClientSite */
	newClientSite(clientSite = null): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let dialogRef: any = null;
		switch (clientSite.details.slug) {
			case "Twitter": case "twitter":
			case "Tweet": case "tweet":
			case "Twitterdm": case "twitterdm":
			case "Tweetdm": case "tweetdm":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "messenger":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "whatsapp":
				this.clientSiteService.getAllModule();
				this.teamService.getAllItems();
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "instagram":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "instadm":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "webchat":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			case "facebook":
				break;
			case "youtube":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				break;
			default:
				break;
		}
		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response;
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.clientSiteService.store(formData.value);
						break;
					case 'update':
						this.clientSiteService.update(id, formData.value);
						break;
					case 'whatsapp':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
						}, 1000);
						break;
					case '360':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
						}, 2000);
						break;
					case 'updateProfile':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
							// console.log("reloaded data source");
						}, 1000);
						break;
					case 'updateSettings':
						this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
							if (data !== this.clientSitesAll) { this.subLoader = false; }
							this.clientSitesAll = data;
							this.clientSites = this.orderByPipe.transform(data, '-id');
							this.dataSource = new MatTableDataSource(this.clientSites);
							this.dataSource.sort = this.sort;
						});
						// console.log("reloaded data source");
						break;
					case 'delete':
						this.deleteSocialSite(clientSite);
						break;
				}
			});
	}


	/** Profile Update ClientSite */
	whatsappProfileUpdate(clientSite = null): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let dialogRef: any = null;
		switch (clientSite.details.slug) {
			case "whatsapp":
				dialogRef = this.matDialog.open(WhatsappProfileUpdateFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			default:
				break;
		}

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.clientSiteService.store(formData.value);
						break;
					case 'update':
						this.clientSiteService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteSocialSite(clientSite);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteSocialSite(socialSite): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
				info: 'If we delete account below connection will disable need to update to new number',
				infoDetails: "Flags (can't retry)",
				infoDetails1: "ITRS (can't retry)"
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					id: socialSite.id
				}
				this.clientSiteService.deleteClientSite(data, 'client-site/delete');
			}
		});
	}

	/** Delete Social Site */
	loginForm(clientSite): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		let site_id = (clientSite && clientSite.site_id) ? clientSite.site_id : clientSite.site_id;
		var data = {
			"site_id": site_id,
			"client_site_id": clientSite.id,
		}
		this.clientSiteService.facebookClientSiteUrl(null, data)
		setTimeout(() => {
			window.open(this.loginUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
		}, 1000);
	}

	/** Delete Social Site */
	loginTwitterForm(clientSite): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		let site_id = (clientSite && clientSite.site_id) ? clientSite.site_id : clientSite.site_id;
		var data = {
			"site_id": site_id,
		}
		this.clientSiteService.facebookClientSiteUrl(null, data)
		setTimeout(() => {
			this.clientSiteService.getTwitterRedirectUrl(this.loginUrl);
		}, 1000);
		setTimeout(() => {
			this.loader = !this.loader;
			if (this.twitterRedirectUrl) {
				window.open(this.twitterRedirectUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
			}
		}, 3000);

		// let url = (clientSite && clientSite.loginUrl) ? clientSite.loginUrl : clientSite.site.loginUrl;
		// window.open(url, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
	}

	calculateDiff(dateSent) {
		let currentDate = new Date();
		dateSent = new Date(dateSent);

		return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
	}

	getStatus(socialSite) {
		let statusName = 'open';
		if (!socialSite.is_authorized) {
			statusName = 'close';
		} else {
			if (socialSite.clientSiteActivity && socialSite.clientSiteActivity.recentSentOn) {
				if (this.calculateDiff(socialSite.clientSiteActivity.recentSentOn) == 0) {
					statusName = 'open';
				} else if (this.calculateDiff(socialSite.clientSiteActivity.recentSentOn) > 5) {
					statusName = 'high';
				} else {
					statusName = 'low';
				}
			} else {
				statusName = 'open-nochat';
			}
		}
		return statusName;
	}

	onFileSelected(event, socialSite: ClientSite) {
		let reader = new FileReader();
		let fileData = null;
		this.profileImageForm = this._formBuilder.group({
			file_data: null,
			file_name: null,
			file_type: null,
			client_site_id: socialSite.id
		});
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				this.profileImageForm.patchValue({
					file_data: reader.result,
					file_name: file.name,
					file_type: file.type,
					client_site_id: socialSite.id
				});
				this.sendFile();
			};
		}
	}

	/** Show Logs */
	showLogs(clientSite) {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let dialogRef = this.matDialog.open(LogViewComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: clientSite,
			}
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}

	setForceReAuth(clientSite) {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to ask your client to Re-Authorized this Account?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let updateData: any = { 'do_reauthendicate': 1 };
				this.clientSiteService.update(clientSite.id, updateData);
			}
		});
	}

	sendFile() {
		this.clientSiteService.post(this.profileImageForm.value, 'whatsapp-profile-image-update');
		this.profileImageForm.reset();
	}

	syncWhatsappProfile(socialSite) {
		this.clientSiteService.changeItem(socialSite);
		let data = { 'client_site_id': socialSite.id };
		this.clientSiteService.getUrl('sync-whatsapp-profile', data);
	}

	/** Export Excel */
	downloadWebchatKey(socialSite: ClientSite) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Download Key?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let url: string = this.clientSiteService.downloadWebchatKeyUrl({ 'client_site_id': socialSite.id, 'client_id': socialSite.client_id });
				window.open(url, '_blank');
			}
		});
	}

	/** Authorize WebChat */
	authorizeWebchatKey(socialSite: ClientSite) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Authorize Webchat Key?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.clientSiteService.getUrl('sync-webchat/' + socialSite.id);
			}
		});
	}

	changeAccountColor(clientSite): void {
		this.clientSiteService.changeItem(clientSite);
		let dialogRef = this.matDialog.open(EditColorFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: clientSite,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const formData: UntypedFormGroup = response[1];
				formData.value["client_siteId"] = clientSite.id;
				this.clientSiteService.postClientSiteUrl(formData.value, 'client-site/updateColor');
			});
	}


	/** Add / Update ClientSite */
	reAuthorize(clientSite = null): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let dialogRef: any = null;
		switch (clientSite.details.slug) {
			case "Twitter": case "twitter":
			case "Tweet": case "tweet":
			case "Twitterdm": case "twitterdm":
			case "Tweetdm": case "tweetdm":
				dialogRef = this.matDialog.open(TwitterFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "messenger":
				dialogRef = this.matDialog.open(MessengerFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "whatsapp":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'reAuthorize',
						item: clientSite,
					},
					panelClass: ['animate__animated', 'animate__slideInRight']
				});
				dialogRef.afterClosed()
				break;
			case "instagram":
				dialogRef = this.matDialog.open(InstagramFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "instadm":
				dialogRef = this.matDialog.open(InstadmFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "webchat":
				dialogRef = this.matDialog.open(WebchatFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "facebook":
				break;
			case "youtube":
				dialogRef = this.matDialog.open(YoutubeFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			default:
				break;
		}

		// let dialogRef = this.matDialog.open(FormComponent, {
		// 	width: "600px",
		// 	data: {
		// 		action: (clientSite) ? 'edit' : 'new',
		// 		item: clientSite,
		// 	}
		// });

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response;
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.clientSiteService.store(formData.value);
						break;
					case 'update':
						this.clientSiteService.update(id, formData.value);
						break;
					case 'whatsapp':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
						}, 1000);
						break;
					case '360':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
						}, 1000);
						break;
					case 'updateProfile':
						this.clientSiteService.getAllItems();
						setTimeout(() => {
							this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
								if (data !== this.clientSitesAll) { this.subLoader = false; }
								this.clientSitesAll = data;
								this.clientSites = this.orderByPipe.transform(data, '-id');
								this.dataSource = new MatTableDataSource(this.clientSites);
								this.dataSource.sort = this.sort;
							});
							// console.log("reloaded data source");
						}, 1000);
						break;
					case 'delete':
						this.deleteSocialSite(clientSite);
						break;
				}
			});
	}
	changeActive(e, id) {
		if (e.checked) {
			var data = {
				'is_active': e.checked,
				"client_siteId": id
			}
			let tempSub = this.commonService.storeItem('client-site/status', data, true, "optionOne").subscribe((res: any) => {
				if (res.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
					this.clientSiteService.getAllItems();
				} else {
					this.alertService.webErrorShow(res);
				}
				tempSub.unsubscribe();
			}, error => {
				this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
				console.error('There was an error!', error);
				tempSub.unsubscribe();
			})
		} else {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '400',
				data: {
					type: 'alert',
					message: 'Are you sure?',
					info: 'No More interactions will be recived',
					infoDetails: "No Chatbots",
					infoDetails1: "No Tickets"
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.data) {
					var data = {
						'is_active': e.checked,
						"client_siteId": id
					}
					let tempSub = this.commonService.storeItem('client-site/status', data, true, "optionOne").subscribe((res: any) => {
						if (res.success) {
							this.alertService.webShow('Info', 'Updated Successfully !!!');
							this.clientSiteService.getAllItems();
						} else {
							this.alertService.webErrorShow(res);
						}
						tempSub.unsubscribe();
					}, error => {
						this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
						console.error('There was an error!', error);
						tempSub.unsubscribe();
					})
				} else {
					this.clientSiteService.getAllItems();
				}
			});

		}
	}

	productSettings(clientSite) {

		let dialogRef: any = null;
		var data = 'whatsapp'
		switch (data) {
			case "whatsapp":
				dialogRef = this.matDialog.open(ProductSettingsComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			default:
				break;
		}
	}

	clientSettings(clientSite) {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		var data = {
			client_site_id: clientSite.id
		}
		this.settingsService.getAllItems(data);
		this.settingsService.getProfile(data);
		this.clientSiteService.getAllModule();
		this.teamService.getAllItems();
	}

	clientItrsLanguage(clientSite) {
		this.settingsService.itrsLanguageDataSource.next([]);
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		var data = {
			client_site_id: clientSite.id
		}
		this.settingsService.getAllLanguage('client-site/itrsorderlan',data);
	}

	socialAccountActive: boolean = false;

	doWhatsappLogin(data: any = null) {
		this.clientSiteDetails = data;
		var datas = {
			"site_id": 2,
			"client_site_id": this.clientSiteDetails.id
		}
		if (data.service_provider == "option-3") {
			datas["option"] = "option-3";
		}
		if (data.service_provider == "option-7") {
			datas["option"] = "option-7";
		}
		this.clientSiteService.facebookClientSiteUrl(null, datas)
		this.socialAccountActive = true;
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		setTimeout(() => {
			if (this.loginUrl) {
				window.open(this.loginUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
			}
		}, 1000);

	}

	eventData: any;
	toastData: ToastData;

	checkEventData() {
		if (this.eventData) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'addaccount':
					if (this.eventData && this.eventData.data && this.eventData.data.result == false) {
						this.alertService.webErrorShow(this.eventData.data);
					} else {
						if (this.eventData.data.addedInfo && this.eventData.data.addedInfo.length > 0) {
							this.eventData.data.addedInfo.forEach(element => {
								this.toastData = new ToastModel({});
								this.toastData.type = element.result ? 'success' : 'error';
								this.toastData.title = element.data.account_name;
								this.toastData.message = element.message;
								this.toastData.faIcon = 'fa-exclamation-circle';
								this.toastData.category = element.result ? 'success' : 'error';
								// this.toastService.showMessage(this.toastData);

								if (this.toastData.type == 'success') {
									this.toastr.success(this.toastData.message, this.toastData.title);
								} else {
									this.toastr.info(this.toastData.message, this.toastData.title);
								}

							});
							this.clientSiteService.getAllItems();
							this.siteService.getAllItems();
							this.eventService.changeEventData(null);
						}
						this.eventService.changeEventData(null);
					}
					break;
				default:
					break;
			}
		}
	}

	webHoks() {
		var data = {}
		var clientSite = this.eventData.data;
		// if (clientSite && clientSite.numberInfo && clientSite.numberInfo.length > 1) {

		// } else {
		data["token"] = clientSite.access_key.access_token ? clientSite.access_key.access_token : '';
		data["whatsapp_number"] = clientSite.numberInfo[0].number;
		data['id'] = this.clientSiteDetails.id;
		data['is_authorized'] = true;
		// }

		let tempSub = this.commonService.storeItem('client-site/update', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.alertService.webShow('Info', res.data.message);
				this.loader = !this.loader;
			} else {
				this.alertService.webErrorShow(res);
				this.loader = !this.loader;
			}
			delete this.clientSiteDetails;
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			delete this.clientSiteDetails;
			tempSub.unsubscribe();
		})
	}
}
