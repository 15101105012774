import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// import { FieldConfig } from 'src/app/@tji/_dbShare';
import { FieldConfig } from 'src/app/@tji/_dbShare/dynamicForm/field.interface';
@Component({
  selector: 'app-date',
  template: `
    <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
      <input
        matInput
        [matDatepicker]="picker"
        [formControlName]="field.name"
        [placeholder]="field.label"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-hint></mat-hint>
      <ng-container
        *ngFor="let validation of field.validations"
        ngProjectAs="mat-error"
      >
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{
          validation.message
        }}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: ['.mat-form-field { width: 100% !important; }'],
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
